<template>
  <form @submit.prevent="searchTranslator()">
    <div class="row">
      <div class="col-md-5 col-9">
        <div class="input-group input-group-lg mb-4">
          <div class="input-group-text">
            <img src="../../assets/icon/input-language.png" alt="language">
          </div>
          <select class="form-control form-control-lg" v-model="fromLang" required>
            <option value="" disabled selected style="display: none">
              {{ $t('search.fromLanguage') }}
            </option>
            <option v-for="l in fromLanguages" :key="l.keyword" :value="l.keyword">
              {{ l.name }}
            </option>
          </select>

        </div>
      </div>
      <div class="col-3 col-md-2">
        <button class="btn btn-lg btn-pill btn-outline-secondary w-100 text-center"
                @click.prevent="changeLanguages()">
          <i class="fa fa-arrows-h" aria-hidden="true"></i>
        </button>
      </div>
      <div class="col-md-5">
        <div class="input-group input-group-lg mb-4">
          <div class="input-group-text">
            <img src="../../assets/icon/input-language.png" alt="language">
          </div>
          <select class="form-control form-control-lg" v-model="toLang" required>
            <option value="" disabled selected style="display: none">
              {{ $t('search.toLanguage') }}
            </option>
            <option v-for="l in toLanguages" :key="l.keyword" :value="l.keyword">
              {{ l.name }}
            </option>
          </select>
        </div>

      </div>


      <div class="col-md-6">

        <DatePicker v-model="fromDate" mode="date"
                    :min-date="new Date()"
                    :max-date="toDate"
                    :masks="{modelValue: 'YYYY-MM-DD'}">
          <template v-slot="{ inputValue, inputEvents }">
            <div class="input-group input-group-lg mb-4">
              <div class="input-group-text">
                <img src="../../assets/icon/input-calendar.png" alt="calendar">
              </div>
              <input type="text" class="form-control form-control-lg" :value="inputValue"
                     v-on="inputEvents"
                     :placeholder="$t('search.fromDate')" required>

            </div>
          </template>
        </DatePicker>

      </div>
      <div class="col-md-6">
        <DatePicker v-model="toDate" mode="date" :min-date="fromDate"
                    :masks="{modelValue: 'YYYY-MM-DD'}">
          <template v-slot="{ inputValue, inputEvents }">
            <div class="input-group input-group-lg mb-4">
              <div class="input-group-text">
                <img src="../../assets/icon/input-calendar.png" alt="calendar">
              </div>
              <input type="text" class="form-control form-control-lg" :value="inputValue"
                     v-on="inputEvents"
                     :placeholder="$t('search.toDate')" required>

            </div>
          </template>
        </DatePicker>
      </div>

      <div class="col-12">
        <div class="input-group input-group-lg mb-4">
          <div class="input-group-text">
            <img src="../../assets/icon/input-location.png" alt="calendar">
          </div>
          <select class="form-control form-control-lg" v-model="location">
            <option value="0">{{ $t('search.allLocations') }}</option>
            <option v-for="l in locations" :key="l.id" :value="l.id">
              {{ l.name }}
            </option>
          </select>
        </div>

      </div>
      <div class="col-md-6">
        <div class="mb-4">

          <label class="form-label">{{ $t('search.fromPrice') }}</label>
          <div class="input-group input-group-lg">
            <input type="number" step="0.01" placeholder="0" v-model="priceFrom" min="0"
                   class="form-control form-control-lg">
            <div class="input-group-text text-secondary">
              {{ $t('general.currencyShort') }}
            </div>
          </div>
        </div>

      </div>
      <div class="col-md-6">
        <div class="mb-4">

          <label class="form-label">{{ $t('search.toPrice') }}</label>
          <div class="input-group input-group-lg">
            <input type="number" step="0.01" placeholder="200" v-model="priceTo" min="0"
                   class="form-control form-control-lg">
            <div class="input-group-text text-secondary">
              {{ $t('general.currencyShort') }}
            </div>
          </div>
        </div>

      </div>

      <div class="col-12">

        <div class="form-check mb-3">
          <input type="checkbox" class="form-check-input" value="1"
                 id="is_sworn" v-model="isSworn">
          <label class="form-check-label"
                 for="is_sworn">{{ $t('search.isSworn') }}</label>
        </div>

      </div>

      <div class="col-12">

        <div class="line-accordion mb-4">

          <div class="title"
               @click="translatorCategoryListVisible = !translatorCategoryListVisible">
            <span>{{ $t('search.details') }}</span>
          </div>

          <div class="body" v-if="translatorCategoryListVisible">
            <div class="row">
              <div class="col-lg-3 col-md-4 col-sm-6" v-for="c in categories"
                   :key="c.keyword">
                <div class="form-check">
                  <input type="checkbox" class="form-check-input" :value="c.keyword"
                         :id="`cat_check_${c.keyword}`" v-model="selectedCategories">
                  <label class="form-check-label"
                         :for="`cat_check_${c.keyword}`">{{ c.name }}</label>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>

      <div class="col-12 text-end">

        <button type="submit" class="btn btn-primary btn-lg btn-pill">
          {{ $t('search.searchButton') }}
        </button>

      </div>

    </div>
  </form>
</template>

<script>

import moment from "moment/moment";

export default {
  name: "InterpreterSearchForm",
  props: ['categories', 'languages', 'locations', 'searchData'],
  data() {
    return {
      translatorCategoryListVisible: false,
      fromLang: '',
      toLang: '',
      fromDateValue: '',
      toDateValue: null,
      location: '',
      priceFrom: 0,
      priceTo: null,
      isSworn: false,
      selectedCategories: []
    }
  },
  computed: {
    fromLanguages() {
      return this.languages.filter(l => {
        return l.keyword !== this.toLang
      });
    },
    toLanguages() {
      return this.languages.filter(l => {
        return l.keyword !== this.fromLang
      });
    },

    fromDate: {
      get() {
        if (!this.fromDateValue) {
          return null;
        }
        return moment(this.fromDateValue).format('YYYY-MM-DD');
      },
      set(value) {
        this.fromDateValue = value;
      }
    },
    toDate: {
      get() {
        if (!this.toDateValue) {
          return null;
        }
        return moment(this.toDateValue).format('YYYY-MM-DD');
      },
      set(value) {
        this.toDateValue = value;
      }
    }
  },
  mounted() {
    this.setInitialData(this.searchData);
  },
  methods: {
    setInitialData(newValue) {
      if (newValue) {
        this.fromLang = newValue.fromLang;
        this.toLang = newValue.toLang;
        this.fromDate = newValue.fromDate;
        this.toDate = newValue.toDate;
        this.priceFrom = newValue.fromCost;
        this.priceTo = newValue.toCost;
        this.location = newValue.locationId;
        this.selectedCategories = newValue.selectedCategories;
        if (this.fromLang && this.toLang) {
          this.searchTranslator();
        }
      }
    },
    searchTranslator() {
      this.$emit('searchSubmitted', {
        fromLang: this.fromLang,
        toLang: this.toLang,
        fromDate: this.fromDate,
        toDate: this.toDate,
        fromCost: this.priceFrom || 0,
        toCost: this.priceTo === null ? 100000 : this.priceTo,
        locationId: this.location === '' ? 0 : this.location,
        jobType: "INTERPRETER",
        selectedCategories: this.selectedCategories,
        isSworn: this.isSworn
      });
    },
    changeLanguages() {
      let f = this.fromLang;
      this.fromLang = this.toLang;
      this.toLang = f;
    }
  }
}
</script>

<style scoped>

</style>