<template>

  <div class="bg-white">

    <top-navigation/>

  </div>
  <div class="expert-profile-container" v-if="user=== null">
    <div class="container" style="padding: 100px 0;">
      <div class="alert alert-info">{{ $t('general.loading') }}</div>
    </div>
  </div>
  <div class="expert-profile-container" v-else>
    <div class="container" style="padding: 100px 0;">


      <div class="row">
        <div class="col-md-6">
          <h3 class="text-dark fw-bold">{{ $t('authentication.changePassword') }}</h3>
        </div>
        <div class="col-md-6 text-end">
          <router-link to="/profile" class="btn btn-sm  btn-outline-primary btn-pill px-4 fw-bold ">
            {{ $t('general.cancel') }}
          </router-link>
        </div>
      </div>


      <form @submit.prevent="changePasswordSubmitted">
        <div class="my-5">
          <div class="card">

            <div class="card-body">


              <div class="row m-5">

                <div class="col-lg-6">
                  <div class="mb-3">
                    <label>{{ $t('authentication.password') }}<span class="text-primary fw-bold">*</span></label>
                    <div class="input-group input-group-lg mb-4">
                      <input class="form-control form-control-lg" :placeholder="$t('authentication.password')"
                             :type="passwordVisible?'text':'password'" v-model="password" required>
                      <div class="input-group-text" @click="passwordVisible = !passwordVisible">
                        <i class="fa" aria-hidden="true"
                           :class="{'fa-eye':!passwordVisible, 'fa-eye-slash': passwordVisible}"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="mb-3">
                    <label>{{ $t('authentication.passwordRepeat') }}<span class="text-primary fw-bold">*</span> </label>
                    <div class="input-group input-group-lg">
                      <input class="form-control form-control-lg"
                             :type="passwordRepeatVisible?'text':'password'"
                             :placeholder="$t('authentication.passwordRepeat')" v-model="passwordRepeat" required>
                      <div class="input-group-text"
                           @click="passwordRepeatVisible = !passwordRepeatVisible">
                        <i class="fa" aria-hidden="true"
                           :class="{'fa-eye':!passwordRepeatVisible, 'fa-eye-slash': passwordRepeatVisible}"></i>
                      </div>
                    </div>

                  </div>
                </div>

                <div class="col-md-6">
                  <div class="text-danger small m-2"
                       v-if="passwordRepeat !== '' && passwordRepeat !== password">
                    {{ $t('authentication.passwordsNotMatch') }}
                  </div>
                </div>
                <div class="col-md-6">
                  <button type="submit" class="btn w-100 btn-primary btn-pill btn-lg px-5 ">
                    {{ $t('general.save') }}
                  </button>
                </div>
              </div>

            </div>


          </div>

        </div>


      </form>

    </div>
  </div>


  <the-footer/>

</template>

<script>
// @ is an alias to /src

import TopNavigation from "../components/layout/TopNavigation";
import TheFooter from "../components/layout/TheFooter";
import {mapState} from "vuex";
import {changePassword} from "@/repositories/GeneralDataRepository";
import customAlert from "@/repositories/Utils";

export default {
  name: 'ProfilePassword',
  components: {TheFooter, TopNavigation},
  data() {
    return {
      password: '',
      passwordRepeat: '',
      passwordVisible: false,
      passwordRepeatVisible: false,
    };
  },
  computed: {
    ...mapState({
      token: state => state.auth.token,
      user: state => state.auth.user
    })
  },
  methods: {

    changePasswordSubmitted() {

      if (this.password === this.passwordRepeat) {

        changePassword(this.token, this.password).then(res => {

          if (res === null) {
            customAlert(this.$t('general.error'), this.$t('general.somethingWentWrong'), 'danger');
          } else {
            let {code, message} = res;
            if (code === 200) {
              customAlert(this.$t('authentication.passwordSaved'), '', 'success');
              this.$router.push('/profile');
            } else {
              customAlert(`Error ${code}`, `${message}`, 'danger');
            }
          }

        });
      }

    },

  },
}
</script>

<style lang="scss" scoped>

.card {
  border-radius: 20px;

  .card-body {
    padding: 20px;
  }
}


</style>