<template>
    <form @submit.prevent="submitNewOrderForm">
        <div class="row my-3">
            <div class="col-md-6">
                <label>{{$t('order.lessonLanguage')}}:</label>
                <select class="form-control" v-model="orderFromLang" required>
                    <option v-for="l in languages" :key="l.id" :value="l.keyword">{{l.name}}</option>
                </select>
            </div>
            <div class="col-md-6">
                <label>{{$t('order.duration')}}</label>
                <select class="form-control" v-model="duration" required>
                    <option value="45">45 {{$t('order.minute')}}</option>
                    <option value="60">60 {{$t('order.minute')}}</option>
                    <option value="90">90 {{$t('order.minute')}}</option>
                </select>
            </div>
        </div>

        <div class="row my-3">
            <div class="col-md-6">
                <label>{{$t('order.pricePerLesson')}}
                </label>
              <div class="input-group">
                <input class="form-control" v-model.number="orderPricePerPage" min="1" required>
                <div class="input-group-text">
                  {{ $t('general.currencyShort') }}
                </div>
              </div>

            </div>
            <div class="col-md-6">
                <label>
                  {{$t('order.lessonCount')}}:
                </label>
                <input class="form-control" v-model.number="orderPageCount" min="1" required>
            </div>
        </div>
        <div class="row my-3">
            <div class="col-md-6">
                <label>{{$t('order.fromDate')}}</label>
                <DatePicker v-model="orderStartTime" mode="dateTime" is24hr :minute-increment="15"
                            :max-date="orderDeadline" :min-date="expireDateTime || new Date()"
                            :masks="{modelValue: 'YYYY-MM-DD HH:mm:ss'}">
                    <template v-slot="{ inputValue, inputEvents }">
                        <input type="text" class="form-control " :value="inputValue"
                               v-on="inputEvents"
                               placeholder="" required>
                    </template>
                </DatePicker>
            </div>
            <div class="col-md-6">
                <label>{{$t('order.toDate')}}</label>
                <DatePicker v-model="orderDeadline" mode="dateTime" is24hr :minute-increment="15"
                            :min-date="orderStartTime"
                            :masks="{modelValue: 'YYYY-MM-DD HH:mm:ss'}">
                    <template v-slot="{ inputValue, inputEvents }">
                        <input type="text" class="form-control " :value="inputValue"
                               v-on="inputEvents"
                               placeholder="" required>
                    </template>
                </DatePicker>
            </div>
        </div>


        <div class="mb-3">
            <label>{{$t('order.offerAvailableUntil')}}</label>
            <DatePicker v-model="expireDateTime" mode="dateTime" is24hr :minute-increment="15"
                        :max-date="orderStartTime" :min-date="new Date()"
                        :model-config="{type: 'string', mask: 'YYYY-MM-DD HH:mm:ss'}">
                <template v-slot="{ inputValue, inputEvents }">
                    <input type="text" class="form-control " :value="inputValue"
                           v-on="inputEvents"
                           placeholder="" required>
                </template>
            </DatePicker>
        </div>

        <hr class="my-4">

        <div class="mb-3">
            <label>{{$t('order.location')}}</label>
            <GMapAutocomplete class="form-control form-control-lg"
                              @place_changed="setPlace" :value="address">
            </GMapAutocomplete>
        </div>


        <div class="row my-3">
            <div class="col-md-6 d-flex align-items-center">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="1" v-model="orderUchebniyeMaterialiStatus"
                           id="orderPostPrice">
                    <label class="form-check-label" for="orderPostPrice">
                      {{$t('order.extras.lessonMaterials')}}
                    </label>
                </div>
            </div>
            <div class="col-md-6">
                <input class="form-control" v-model.number="orderUchebniyeMaterialiPrice">
            </div>
        </div>

        <hr>
        <div class="d-flex justify-content-between align-items-center">
            <div>
              {{$t('order.totalPrice')}}
            </div>
            <div class="text-primary fw-bold">
                {{orderTotalPrice}} {{$t('general.currencyShort')}}
            </div>
        </div>

        <hr>


        <div class="my-3">
            <button type="submit" class="btn btn-primary rounded-pill w-100">
              {{$t('general.send')}}
            </button>
        </div>
    </form>
</template>

<script>
import {createOrder, getExpertLanguages} from "@/repositories/GeneralDataRepository";
    import customAlert from "../../repositories/Utils";
    import {mapState} from "vuex";
    import moment from "moment/moment";

    export default {
        name: "TeacherOrderForm",
        props: ['targetUserId'],
        data() {
            return {
                languages: [],

                orderFromLang: 0,
                orderToLang: 0,
                orderPeriod: 'HOURLY',
                orderPageCount: 1,
                orderPricePerPage: 0,
                orderStartTimeValue: '',
                orderDeadlineValue: '',
                expireDateTimeValue: '',
                orderUchebniyeMaterialiStatus: false,
                orderUchebniyeMaterialiPrice: 0,

                duration: 60,
                address: '',
                latitude: 0,
                longitude: 0,
            }
        },

        computed: {
            ...mapState(
                {
                    token: state => state.auth.token,
                    user: state => state.auth.user,
                }
            ),
            orderTotalPrice() {
                return this.orderPricePerPage * this.orderPageCount
                    + (this.orderUchebniyeMaterialiStatus && this.orderUchebniyeMaterialiPrice ? this.orderUchebniyeMaterialiPrice : 0);
            },

          orderStartTime:{
            get() {
              if(!this.orderStartTimeValue)  {
                return null;
              }
              return moment(this.orderStartTimeValue).format('YYYY-MM-DD HH:mm:ss');
            },
            set(value) {
              this.orderStartTimeValue = value;
            }
          },
          orderDeadline:{
            get() {
              if(!this.orderDeadlineValue)  {
                return null;
              }
              return moment(this.orderDeadlineValue).format('YYYY-MM-DD HH:mm:ss');
            },
            set(value) {
              this.orderDeadlineValue = value;
            }
          },
          expireDateTime:{
            get() {
              if(!this.expireDateTimeValue)  {
                return null;
              }
              return moment(this.expireDateTimeValue).format('YYYY-MM-DD HH:mm:ss');
            },
            set(value) {
              this.expireDateTimeValue = value;
            }
          },
        },
        watch: {

            orderUchebniyeMaterialiPrice(newVal) {
                if (newVal > 0) {
                    this.orderUchebniyeMaterialiStatus = true;
                } else if (!newVal) {
                    this.orderUchebniyeMaterialiStatus = false;
                }
            },

          "$i18n.locale": function () {
            this.getLanguages();
          }
        },
        methods: {
            submitNewOrderForm() {

                let data = {
                    "firstOrder": {
                        "jobFromKey": {
                            "keyword": this.orderFromLang
                        },
                        "jobToKey": {
                            "keyword": this.orderFromLang
                        },
                        "price": this.orderPricePerPage,
                        "count": this.orderPageCount,
                        "startDate": this.orderStartTime,
                        "endDate": this.orderDeadline,
                        "expireDateTime": this.expireDateTime,
                        "duration": this.duration,
                        "lat": this.latitude,
                        "lan": this.longitude,
                        "address": this.address,
                        "post": 0,
                        "notar": 0,
                        "kuryer": 0,
                        "doprava": 0,
                        "ucebniMaterialy": (this.orderUchebniyeMaterialiStatus ? this.orderUchebniyeMaterialiPrice : 0),
                        "note": '',
                        "idExpert": this.user.id,
                        "idUser": this.targetUserId,
                        "orderType": "TEACHER"
                    }
                };

                createOrder(this.token, data).then(res => {

                    if (res.code === 200) {
                        customAlert(this.$t('orderForm.orderCreated'), '', 'success');
                        let newId = res.body.firstOrder.id;

                        this.$emit('created', newId);
                    } else {
                        customAlert(this.$t('orderForm.orderCanNotBeCreated'), res.message, 'danger');
                    }

                })

            },

            setPlace(d) {
                console.log(d);
                this.address = d.formatted_address;
                this.latitude = d.geometry.location.lat();
                this.longitude = d.geometry.location.lng();
            },

          getLanguages() {
            getExpertLanguages(this.token,'TEACHER').then((data) => {
              this.languages = data.body;
            });
          }

        }, mounted() {
           this.getLanguages();
        }
    }
</script>

<style lang="scss">

    ::v-deep .pac-container {
        z-index: 10000;
    }
</style>
