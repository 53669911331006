<template>
  <form @submit.prevent="submitNewOrderForm">
    <div class="row mt-3">
      <div class="col-md-6  mb-3">
        <select class="form-control" v-model="orderFromLang" required>
          <option v-for="l in languages" :key="l.id" :value="l.keyword">{{ l.name }}</option>
        </select>
      </div>
      <div class="col-md-6  mb-3">
        <select class="form-control" v-model="orderToLang" required>
          <option v-for="l in languages" :key="l.id" :value="l.keyword">{{ l.name }}</option>
        </select>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-md-6">
        <label>{{ $t('order.pageCount') }}</label>
        <input class="form-control" v-model.number="orderPageCount" min="1" required>
      </div>
      <div class="col-md-6">
        <label>{{ $t('order.pricePerPage') }}</label>
        <div class="input-group">
        <input class="form-control" v-model.number="orderPricePerPage" min="1" required>
          <div class="input-group-text">
            {{ $t('general.currencyShort') }}
          </div>
        </div>
      </div>
    </div>

    <div class="mb-3">
      <label>{{ $t('order.deadline') }}</label>
      <DatePicker v-model="orderDeadline" mode="dateTime" is24hr :minute-increment="15"
                  :min-date="expireDateTime || new Date()"
                  :model-config="{type: 'string', mask: 'YYYY-MM-DD HH:mm:ss'}">
        <template v-slot="{ inputValue, inputEvents }">
          <input type="text" class="form-control " :value="inputValue"
                 v-on="inputEvents" required>
        </template>
      </DatePicker>


    </div>
    <div class="mb-3">
      <label>{{ $t('order.offerAvailableUntil') }}</label>
      <DatePicker v-model="expireDateTime" mode="dateTime" is24hr :minute-increment="15"
                  :max-date="orderDeadline" :min-date="new Date()"
                  :model-config="{type: 'string', mask: 'YYYY-MM-DD HH:mm:ss'}">
        <template v-slot="{ inputValue, inputEvents }">
          <input type="text" class="form-control " :value="inputValue"
                 v-on="inputEvents"
                 placeholder="" required>
        </template>
      </DatePicker>
    </div>


    <div class="row my-3">
      <div class="col-md-3 d-flex align-items-center">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" value="1" v-model="orderPostStatus"
                 id="orderPostPrice">
          <label class="form-check-label" for="orderPostPrice">
            {{ $t('order.extras.post') }}
          </label>
        </div>
      </div>
      <div class="col-md-9">
        <input class="form-control" v-model.number="orderPostPrice">
      </div>
    </div>
    <div class="row my-3">
      <div class="col-md-3 d-flex align-items-center">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" value="1" v-model="orderNoterStatus"
                 id="orderNoterPrice">
          <label class="form-check-label" for="orderNoterPrice">
            {{ $t('order.extras.notar') }}
          </label>
        </div>
      </div>
      <div class="col-md-9">
        <input class="form-control" v-model.number="orderNoterPrice">
      </div>
    </div>

    <div class="row my-3">
      <div class="col-md-3 d-flex align-items-center">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" value="1" v-model="orderCourierStatus"
                 id="orderCouirerPrice">
          <label class="form-check-label" for="orderCouirerPrice">
            {{ $t('order.extras.courier') }}
          </label>
        </div>
      </div>
      <div class="col-md-9">
        <input class="form-control" readonly v-model.number="orderCourierPrice">
      </div>
    </div>

    <hr>
    <div class="d-flex justify-content-between align-items-center">
      <div>
        {{ $t('order.totalPrice') }}:
      </div>
      <div class="text-primary fw-bold">
        {{ orderTotalPrice }} {{ $t('general.currencyShort') }}
      </div>
    </div>

    <hr>


    <div class="my-3">
      <button type="submit" class="btn btn-primary rounded-pill w-100">
        {{ $t('general.send') }}
      </button>
    </div>
  </form>
</template>

<script>
import {createOrder, getExpertLanguages} from "@/repositories/GeneralDataRepository";
import customAlert from "../../repositories/Utils";
import {mapState} from "vuex";
import moment from "moment";

export default {
  name: "TranslatorOrderForm",
  props: ['targetUserId'],
  data() {
    return {
      languages: [],

      orderFromLang: 0,
      orderToLang: 0,
      orderPageCount: '',
      orderPricePerPage: 0,
      orderDeadlineValue: '',
      expireDateTimeValue: '',
      orderPostStatus: false,
      orderPostPrice: 0,
      orderNoterStatus: false,
      orderNoterPrice: 0,
      orderCourierStatus: false,
      orderCourierPrice: 0,
    }
  },

  computed: {
    ...mapState(
        {
          token: state => state.auth.token,
          user: state => state.auth.user,
        }
    ),
    orderTotalPrice() {
      return this.orderPricePerPage * this.orderPageCount
          + (this.orderPostStatus && this.orderPostPrice ? this.orderPostPrice : 0)
          + (this.orderNoterStatus && this.orderNoterPrice ? this.orderNoterPrice : 0)
          + (this.orderCourierStatus && this.orderCourierPrice ? this.orderCourierPrice : 0);
    },
    orderDeadline: {
      get() {
        if (!this.orderDeadlineValue) {
          return null;
        }
        return moment(this.orderDeadlineValue).format('YYYY-MM-DD HH:mm:ss');
      },
      set(value) {
        this.orderDeadlineValue = value;
      }
    },
    expireDateTime: {
      get() {
        if (!this.expireDateTimeValue) {
          return null;
        }
        return moment(this.expireDateTimeValue).format('YYYY-MM-DD HH:mm:ss');
      },
      set(value) {
        this.expireDateTimeValue = value;
      }
    },
  },

  watch: {

    orderPostPrice(newVal) {
      if (newVal > 0) {
        this.orderPostStatus = true;
      } else if (!newVal) {
        this.orderPostStatus = false;
      }
    },
    orderNoterPrice(newVal) {
      if (newVal > 0) {
        this.orderNoterStatus = true;
      } else if (!newVal) {
        this.orderNoterStatus = false;
      }
    },
    orderCourierStatus(newVal) {
      if (newVal) {
        this.orderCourierPrice = 499;
      } else {
        this.orderCourierPrice = 0;
      }
    },

    "$i18n.locale": function () {
      this.getLanguages();
    }
  },
  methods: {
    submitNewOrderForm() {

      let data = {
        "firstOrder": {
          "jobFromKey": {
            "keyword": this.orderFromLang
          },
          "jobToKey": {
            "keyword": this.orderToLang
          },
          "price": this.orderPricePerPage,
          "count": this.orderPageCount,
          "startDate": this.orderDeadline,
          "endDate": this.orderDeadline,
          "expireDateTime": this.expireDateTime,
          "duration": 0,
          "lat": 0.0,
          "lan": 0.0,
          "post": (this.orderPostStatus ? this.orderPostPrice : 0),
          "notar": (this.orderNoterStatus ? this.orderNoterPrice : 0),
          "kuryer": (this.orderCourierStatus ? this.orderCourierPrice : 0),
          "doprava": 0,
          "ucebniMaterialy": 0,
          "period": "HOURLY",
          "note": '',
          "idExpert": this.user.id,
          "idUser": this.targetUserId,
          "orderType": "TRANSLATOR"
        }
      };

      console.log(data);
      createOrder(this.token, data).then(res => {

        if (res.code === 200) {
          customAlert(this.$t('orderForm.orderCreated'), '', 'success');
          let newId = res.body.firstOrder.id;

          this.$emit('created', newId);
        } else {
          customAlert(this.$t('orderForm.orderCanNotBeCreated'), res.message, 'danger');
        }

      })

    },
    getLanguages() {
      getExpertLanguages(this.token,'TRANSLATOR').then((data) => {
        this.languages = data.body;
      });
    }

  }, mounted() {
    this.getLanguages();
  }
}
</script>

<style scoped>

</style>
