<template>

    <div class="bg-white">

        <top-navigation/>

    </div>

    <div class="container py-5">


        <div class="card">

            <div class="card-body">


                <div class="row">

                    <div class="col-md-6 d-flex align-items-center mb-5">
                        <div>
                            <h1 class="display-2 fw-bold text-primary">O nás</h1>

                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum eget dapibus dui,
                                sit
                                amet pretium massa. Vivamus ac neque ligula. Sed tristique ultrices ultricies. Phasellus
                                cursus purus ut purus luctus pharetra. Nam id quam non neque iaculis interdum quis nec
                                felis. Curabitur pellentesque mollis est a cursus.
                                Pellentesque sit amet sagittis est. Morbi scelerisque dapibus sollicitudin.
                            </p>
                        </div>
                    </div>
                    <div class="col-md-6 mb-5 p-5">
                        <img src="../assets/theme/photo-3.png" class="img-fluid" alt="image">
                    </div>
                    <div class="col-md-6 mb-5 p-5">
                        <img src="../assets/theme/photo-4.png"  class="img-fluid"  alt="image">
                    </div>
                    <div class="col-md-6 d-flex align-items-center mb-5">
                        <p>
                            Pellentesque sit amet sagittis est. Morbi scelerisque dapibus sollicitudin. Praesent cursus
                            ex tortor, sit amet pellentesque mauris malesuada vitae. Sed eu orci ut magna feugiat
                            bibendum. Sed egestas turpis eget diam varius condimentum. Nulla at lectus vitae diam porta
                            maximus a sit amet justo. Ut euismod lacinia nunc quis rhoncus. Sed sit amet dolor ante.
                            Suspendisse id nibh tempor ex viverra molestie.
                            Sed egestas turpis eget diam varius condimentum. Nulla at lectus vitae diam porta maximus a
                            sit amet justo maximus.
                        </p>
                    </div>

                    <div class="col-md-6 d-flex align-items-center mb-5">
                        <p>
                            Sed eu orci ut magna feugiat bibendum. Sed egestas turpis eget diam varius condimentum.
                            Nulla at lectus vitae diam porta maximus a sit amet justo. Ut euismod lacinia nunc quis
                            rhoncus. Sed sit amet dolor ante. Suspendisse id nibh tempor ex viverra molestie.
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum eget dapibus dui, sit
                            amet pretium massa. Vivamus ac neque ligula. Sed tristique ultrices ultricies. Phasellus
                            cursus purus ut purus luctus pharetra. Nam id quam non neque iaculis interdum quis nec
                            felis. Curabitur pellentesque mollis est a amet sagittis est cursus.
                        </p>
                    </div>
                    <div class="col-md-6 mb-5 p-5">
                        <img src="../assets/theme/photo-5.png"  class="img-fluid"  alt="image">
                    </div>

                </div>


            </div>

        </div>

    </div>


    <find-clients-online/>

    <the-footer/>

</template>

<script>
    // @ is an alias to /src

    import TopNavigation from "../components/layout/TopNavigation";
    import TheFooter from "../components/layout/TheFooter";
    import FindClientsOnline from "../components/info/FindClientsOnline";

    export default {
        name: 'Home',
        components: {FindClientsOnline, TheFooter, TopNavigation},
        data() {
            return {};
        }
    }
</script>

<style lang="scss" scoped>

    @import "../assets/colors.scss";


    .card {
        border-radius: 30px;
    }

    .card-body {
        padding: 30px;
    }

    p{
        font-size: 20px;
    }

</style>