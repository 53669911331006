<template>

  <div>

    <div v-if="schedules.length<1" class="alert alert-warning">
      {{ $t('userJobs.noJobsFound') }}
    </div>

    <div v-if="!addNewBlock">

      <div class="row job-row" v-for="s in schedules" :key="s.id">
        <div class="col-md-4 d-flex align-items-center justify-content-start">
          <img :src="getLanguageIcon(s.jobFromKey.keyword)" :alt="s.jobFromKey.name" class="languageIcon">
          {{ s.jobFromKey.name }}
        </div>
        <div class="col-md-4  d-flex align-items-center justify-content-start">
          <img :src="getLanguageIcon(s.jobToKey.keyword)" :alt="s.jobToKey.name" class="languageIcon">
          {{ s.jobToKey.name }}
        </div>
        <div class="col-md-4 ">
          <div class="d-flex justify-content-between">
            <div>
                            <span class="text-success" v-if="s.sworn && s.swornStatus === 'APPROVED'">
                                <i class="fa fa-check-circle" aria-hidden="true"></i>
                                {{ $t('userJobs.isSworn') }}
                            </span>
            </div>
            <div class="text-end">
              <a href="#" class="d-inline-block me-2" @click.prevent="editJob(s.id)" v-if="!addNewBlock">
                <i class="fa fa-pencil text-primary" aria-hidden="true"></i>
              </a>

              <a href="#" @click.prevent="deleteJob(s.id)" v-if="!addNewBlock">
                <i class="fa fa-times text-danger" aria-hidden="true"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-3" v-if="!addNewBlock">
      <button class="btn btn-outline-primary rounded-pill px-3" @click="addNewBlockItem()">
        <i class="fa fa-plus" aria-hidden="true"></i> {{ $t('userJobs.addNew') }}
      </button>
    </div>

    <div class="my-3" v-if="addNewBlock">


      <div class="row">

        <div class="col-md-6 card" :class="{
                        'col-md-6': newBlock.id,
                        'col-md-12': !newBlock.id
                    }">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <label class="small text-primary">
                  <i class="fa fa-language" aria-hidden="true"></i> {{ $t('userJobs.fromLanguage') }}
                </label>
                <select class="form-control form-control-lg" v-model="newBlock.fromLang">
                  <option value="" disabled selected style="display: none">---</option>
                  <option v-for="l in fromLanguages" :key="l.keyword" :value="l.keyword">
                    {{ l.name }}
                  </option>
                </select>

              </div>

              <div class="col-md-6">
                <label class="small text-primary">
                  <i class="fa fa-language" aria-hidden="true"></i> {{ $t('userJobs.toLanguage') }}
                </label>
                <select class="form-control form-control-lg" v-model="newBlock.toLang">
                  <option value="" disabled selected style="display: none">---</option>
                  <option v-for="l in toLanguages" :key="l.keyword" :value="l.keyword">
                    {{ l.name }}
                  </option>
                </select>

              </div>
              <div class="col-md-12 pt-3">
                <label>
                  <input type="checkbox" value="1" v-model="newBlock.sworn">
                  {{ $t('userJobs.isSworn') }}
                </label>
                <span class="ms-3" v-if=" newBlock.sworn && newBlock.swornStatus">
                                    <span class="text-warning" v-if="newBlock.swornStatus ==='WAITING'">
                                      {{ $t('userJobs.status.waiting') }}
                                    </span>
                                    <span class="text-danger" v-if="newBlock.swornStatus ==='CANCELLED'">
                                      {{ $t('userJobs.status.cancelled') }}
                                    </span>
                                    <span class="text-success" v-if="newBlock.swornStatus ==='APPROVED'">
                                      {{ $t('userJobs.status.approved') }}
                                    </span>
                                </span>
              </div>
              <div class="col-md-12">

                <hr>

                <div class="mb-2 small">{{ $t('userJobs.details') }}</div>

                <div class="row">
                  <div class="col-md-4 col-sm-6" v-for="c in categories"
                       :key="c.keyword">
                    <div class="form-check small">
                      <input type="checkbox" class="form-check-input" :value="c.keyword"
                             :id="`cat_check_${c.keyword}`" v-model="newBlock.categories">
                      <label class="form-check-label"
                             :for="`cat_check_${c.keyword}`">{{ c.name }}</label>
                    </div>
                  </div>
                </div>


              </div>
            </div>
            <div class="my-3 alert alert-danger" v-if="newBlockErrors.length>0">
              <div class=" small" v-for="(e,k) in newBlockErrors" :key="k">
                - {{ e }}
              </div>
            </div>

            <hr>
            <div class="col-12 pt-3">
              <button class="btn btn-outline-secondary rounded-pill px-3 me-3" type="button"
                      @click="closeAddEditBlock">
                {{ $t('general.cancel') }}
              </button>
              <button @click="createNewJob" class="btn btn-primary rounded-pill px-3" type="button">
                {{ $t('general.save') }}
              </button>
            </div>
          </div>
        </div>
        <div class="col-md-6" v-if="newBlock.id">

          <DatePicker is-expanded :rows="2" color="purple" is-range :first-day-of-week="2"
                      :attributes='attributes' :disabled-dates="disabled" v-model.range="dateRange"
                      :locale="$i18n.locale" :initial-page="initialPage"
                      @dayclick="dayClicked"
                      expanded
          />

        </div>


      </div>


    </div>


  </div>
  <Modal v-if="setPricePopupIsOpen" size="sm" title="Adding pricing" @modalClose="closeJobTimePopup">

    <form @submit.prevent="addNewJobTime">
      <div class="mb-3">
        <label class="small">{{ $t('userJobs.price') }} :</label>
        <div class="input-group">
          <input type="number" min="1" step="0.01" class="form-control" max="100000" required
                 v-model.number="newFormConfiguration.price">
          <span class="input-group-text">
            {{ $t('general.currencyShort') }}/
            <span v-if="type==='TRANSLATOR'">{{ $tc('order.units.page', 0) }}</span>
            <span v-if="type==='INTERPRETER'">{{ $tc('order.units.hour', 0) }}</span>
            <span v-if="type==='TEACHER'">{{ $tc('order.units.lesson', 0) }}</span>
          </span>
        </div>
      </div>
      <div class="mb-3" v-if="type === 'TEACHER'">
        <label class="small">{{ $t('userJobs.lessonDuration') }}:</label>
        <div>
          <select v-model.number="newFormConfiguration.duration" class="form-control" required>
            <option value="45">45 {{ $t('userJobs.minute') }}</option>
            <option value="60">60 {{ $t('userJobs.minute') }}</option>
            <option value="90">90 {{ $t('userJobs.minute') }}</option>
          </select>
        </div>
      </div>
      <div class="mb-3">
        <label class="small">{{ $t('userJobs.color') }}:</label>
        <div>
          <select v-model="newFormConfiguration.color" class="form-control"
                  :style="{background: newFormConfiguration.color} " required>
            <option :value="c" v-for="c in colors" :key="c">{{ c }}</option>
          </select>
        </div>
      </div>
      <div class="mb-3">
        <button type="submit" class="btn btn-success">
          {{ $t('general.save') }}
        </button>
      </div>
    </form>
  </Modal>
  <Modal v-if="editTimeConfiguration.id !== null" size="sm" title="Configuring pricing"
         @modalClose="closeEditJobTimePopup">

    <form @submit.prevent="editNewJobTime">
      <div class="mb-3">
        <label class="small">{{ $t('userJobs.date') }}:</label>
        <div>
          <input class="form-control"
                 :value="`${moment(editTimeConfiguration.start).format('DD MMM YYYY')} - ${moment(editTimeConfiguration.end).format('DD MMM YYYY')}`"
                 readonly>
        </div>
      </div>
      <div class="mb-3">
        <label class="small">{{ $t('userJobs.price') }}:</label>

        <div class="input-group">
          <input type="number" min="1" step="0.01" class="form-control" max="100000"
                 v-model.number="editTimeConfiguration.price">
          <span class="input-group-text">
            {{ $t('general.currencyShort') }}/
            <span v-if="type==='TRANSLATOR'">{{ $tc('order.units.page', 0) }}</span>
            <span v-if="type==='INTERPRETER'">{{ $tc('order.units.hour', 0) }}</span>
            <span v-if="type==='TEACHER'">{{ $tc('order.units.lesson', 0) }}</span>
          </span>
        </div>
      </div>
      <div class="mb-3" v-if="type === 'TEACHER'">
        <label class="small">{{ $t('userJobs.lessonDuration') }}:</label>
        <div>
          <select v-model.number="editTimeConfiguration.duration" class="form-control" required>
            <option value="45">45 {{ $t('userJobs.minute') }}</option>
            <option value="60">60 {{ $t('userJobs.minute') }}</option>
            <option value="90">90 {{ $t('userJobs.minute') }}</option>
          </select>
        </div>
      </div>
      <div class="mb-3">
        <label class="small">{{ $t('userJobs.color') }}:</label>
        <div>
          <select v-model="editTimeConfiguration.color" class="form-control"
                  :style="{background: editTimeConfiguration.color} " required>
            <option :value="c" v-for="c in colors" :key="c">{{ c }}</option>
          </select>
        </div>
      </div>
      <div class="mb-3 d-flex justify-content-between">
        <button type="submit" class="btn btn-success">
          {{ $t('general.save') }}
        </button>

        <button type="button" class="btn btn-danger" @click.prevent="deleteTime">
          {{ $t('general.delete') }}
        </button>
      </div>
    </form>
  </Modal>
</template>

<script>
import {
  addJobTime,
  createNewJob,
  deleteJobTime,
  deleteMyJob, editJobTime,
  updateMyJob
} from "@/repositories/GeneralDataRepository";
import {mapState} from "vuex";
import Modal from "../Modal";
import customAlert from "../../repositories/Utils";
import moment from "moment";
import {mediaBase} from "@/repositories/Repository";

export default {
  name: "TranslatorJobs",
  props: ["type", "schedules", "languages", "categories"],
  components: {Modal},
  data() {
    return {
      mediaBase: mediaBase,
      addNewBlock: false,
      newBlock: {},
      newBlockErrors: [],
      weekdays: {
        "MONDAY": "Po",
        "TUESDAY": "Ut",
        "WEDNESDAY": "St",
        "THURSDAY": "Ct",
        "FRIDAY": "Pa",
        "SATURDAY": "So",
        "SUNDAY": "Ne"
      },
      colors: ["gray", "red", "orange", "yellow", "green", "teal", "blue", "indigo", "purple", "pink"],
      dateRange: {
        start: null, end: null
      },
      newFormConfiguration: {
        start: null,
        price: null,
        color: null,
        end: null,
        duration: null
      },
      editTimeConfiguration: {
        id: null,
        price: null,
        color: null,
        start: null,
        end: null,
        duration: null
      },
      clickedDateAttributeId: null,
      setPricePopupIsOpen: false,
    };
  },
  emits: ['userJobUpdated'],
  watch: {
    dateRange(newVal) {
      if (newVal && newVal.start !== null && newVal.end !== null) {
        this.newFormConfiguration = {
          start: new Date(newVal.start),
          end: new Date(newVal.end),
          price: null,
          color: null,
          duration: null
        };
        this.setPricePopupIsOpen = true;
      } else {
        this.setPricePopupIsOpen = false;
      }
    },
    schedules() {
      if (this.newBlock.id) {
        this.editJob(this.newBlock.id);
      }
    }
  },
  computed: {
    ...mapState({
      token: state => state.auth.token,
      user: state => state.auth.user
    }),
    fromLanguages() {
      return this.languages.filter(l => {
        return l.keyword !== this.newBlock.toLang
      });
    },
    toLanguages() {
      return this.languages.filter(l => {
        return l.keyword !== this.newBlock.fromLang
      });
    },
    attributes() {

      let list = [];

      if (this.newBlock.timeSheets) {
        this.newBlock.timeSheets.forEach(t => {

          let unit =  this.$t('general.currencyShortName')+"/";

          if(this.type === 'TRANSLATOR'){
            unit += this.$tc('order.units.page', 0);
          }else if(this.type === 'INTERPRETER'){
            unit += this.$tc('order.units.hour', 0);
          } else if(this.type === 'TEACHER'){
            unit += ` ${this.$tc('order.units.lesson', 0)}   (${t.duration} min)`;
          }

          list.push({
            key: t.id,
            highlight: {
              color: t.color || 'indigo',
              fillMode: 'solid'
            },
            dot: 'transparent',
            bar: 'transparent',
            popover: {
              label: ` ${moment(t.localDateFrom).format('DD MMM')} - ${moment(t.localDateTo).format('DD MMM')}:  ${t.cost} ${unit}`,
            },
            dates: {start: t.localDateFrom, end: t.localDateTo},
            excludeDates: null,
            order: 0,
            customData: t
          })
        })
      }

      return list;
    },

    initialPage(){
      return {
        day:  new Date().getDay(),
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
      };
    },
    disabled() {

      let list = [];

      list.push({
        start: new Date(1970, 1, 1),
        end: moment().add(-1, 'd').format("YYYY-MM-DD")
      })

      if (this.newBlock.timeSheets) {
        this.newBlock.timeSheets.forEach(t => {

          if (this.clickedDateAttributeId !== t.id) {
            list.push({start: t.localDateFrom, end: t.localDateTo})
          }
        })
      }

      return list;
    }
  },
  methods: {

    moment(d) {
      return moment(d);
    },
    addNewBlockItem() {
      this.addNewBlock = true;
      this.newBlockErrors = [];

      this.newBlock = {
        id: "",
        fromLang: "",
        toLang: "",
        sworn: false,
        categories: [],
        timeSheets: []
      };

      //this.addNewTimeSheet();
    },

    closeJobTimePopup() {
      this.setPricePopupIsOpen = false;
      this.dateRange = {start: null, end: null};
    },
    openEditJobTimePopup(t) {
      this.editTimeConfiguration = {
        id: t.id,
        price: t.cost,
        color: t.color,
        start: t.start,
        end: t.end,
        duration: t.duration
      };
    },
    closeEditJobTimePopup() {
      this.editTimeConfiguration = {
        id: null,
        price: null,
        color: null,
        start: null,
        end: null,
        duration: null
      };
    },
    editNewJobTime() {


      let data = {
        cost: this.editTimeConfiguration.price,
        color: this.editTimeConfiguration.color,
        duration: this.editTimeConfiguration.duration !== null ? this.editTimeConfiguration.duration : 0,
        localDateFrom: this.editTimeConfiguration.start,
        localDateTo: this.editTimeConfiguration.end,
      };


      editJobTime(this.token, this.editTimeConfiguration.id, data).then(res => {

        if (res === null) {
          customAlert(this.$t('general.error'), this.$t('general.somethingWentWrong'), "danger");
        } else {
          let {code, message} = res;

          if (code === 200) {

            customAlert(this.$t('userJobs.updated'), '', 'success');
            this.$emit('userJobUpdated', true);
            this.editTimeConfiguration = {
              id: null,
              price: null,
              color: null
            };

          } else {
            customAlert(`Error ${code}`, `${message}`, "danger");
          }
        }

      });


    },

    deleteTime() {

      if (confirm(this.$t('userJobs.deleteJobTimeQuestion'))) {


        deleteJobTime(this.token, this.editTimeConfiguration.id).then(res => {

          if (res === null) {
            customAlert(this.$t('general.error'), this.$t('general.somethingWentWrong'), "danger");
          } else {
            let {code, message} = res;

            if (code === 200) {

              customAlert(this.$t('userJobs.updated'), '', 'success');
              this.$emit('userJobUpdated', true);
              this.editTimeConfiguration = {
                id: null,
                price: null,
                color: null
              };

            } else {
              customAlert(`Error ${code}`, `${message}`, "danger");
            }
          }

        });
      }

    },
    addNewJobTime() {

      let details = {
        idUserJob: this.newBlock.id,
        "localDateFrom": moment(this.newFormConfiguration.start).format("YYYY-MM-DD"),
        "localDateTo": moment(this.newFormConfiguration.end).format("YYYY-MM-DD"),
        "cost": this.newFormConfiguration.price,
        "color": this.newFormConfiguration.color,
        duration: this.newFormConfiguration.duration !== null ? this.newFormConfiguration.duration : 0,
      };


      addJobTime(this.token, details).then(res => {

        if (res === null) {
          customAlert(this.$t('general.error'), this.$t('general.somethingWentWrong'), "danger");
        } else {
          let {code, message} = res;

          if (code === 200) {

            customAlert(this.$t('userJobs.updated'), '', 'success');
            this.$emit('userJobUpdated', true);
            this.setPricePopupIsOpen = false;
            this.dateRange = {start: null, end: null};

          } else {
            customAlert(`Error ${code}`, `${message}`, "danger");
          }
        }

      });


    },

    createNewJob() {
      this.newBlockErrors = [];

      if (!this.newBlock.fromLang) {
        this.newBlockErrors.push(this.$t('userJobs.error.chooseFromLanguage'));
      }

      if (!this.newBlock.toLang) {
        this.newBlockErrors.push(this.$t('userJobs.error.chooseToLanguage'));
      }

      if (this.newBlock.categories.length < 1) {
        this.newBlockErrors.push(this.$t('userJobs.error.chooseMinimum1Category'));
      }
      /*
                      if (!this.allDone) {
                          this.newBlockErrors.push('Please finish all time configurations');
                      }*/

      if (this.newBlockErrors.length < 1) {

        let data = {
          "jobFromKey": {
            "keyword": this.newBlock.fromLang
          },
          "jobToKey": {
            "keyword": this.newBlock.toLang
          },
          "jobType": this.type,
          "listOfJobTimes": [],
          "listOfJobKnowledgeView": [],
          "sworn": this.newBlock.sworn
        };

        this.newBlock.categories.forEach(c => {
          data.listOfJobKnowledgeView.push({
            "knowledgeKey": c
          });
        });


        data.listOfJobTimes = this.newBlock.timeSheets;


        if (this.newBlock.id !== '') {

          data.id = this.newBlock.id;
          data.userId = this.user.id;

          updateMyJob(this.token, data).then(res => {

            if (res === null) {
              customAlert(this.$t('general.error'), this.$t('general.somethingWentWrong'), "danger");
            } else {
              let {code, message} = res;

              if (code === 200) {

                customAlert(this.$t('userJobs.updated'), '', 'success');
                this.addNewBlock = false;
                this.newBlock = {};
                this.newBlockErrors = [];
                this.$emit('userJobUpdated', true);


              } else {
                customAlert(`Error ${code}`, `${message}`, "danger");
              }
            }

          });

        } else {


          createNewJob(this.token, data).then(res => {

            if (res === null) {
              customAlert(this.$t('general.error'), this.$t('general.somethingWentWrong'), "danger");
            } else {
              let {code, body, message} = res;

              if (code === 200) {

                customAlert(this.$t('userJobs.updated'), '', 'success');
                this.addNewBlock = false;
                this.$emit('userJobUpdated', true);
                this.newBlock.id = body.id;

              } else if (code === 409) {
                customAlert(this.$t('userJobs.jobAlreadyExists'), '', 'warning');
                this.addNewBlock = false;
                this.$emit('userJobUpdated', true);
                this.newBlock.id = body.id;
              } else {
                customAlert(`Error ${code}`, `${message}`, "danger");
              }
            }

          });
        }


      }

    },

    deleteJob(id) {


      if (confirm(this.$t('userJobs.areYouSureYouWantDeleteJob'))) {
        deleteMyJob(this.token, id).then(res => {

          if (res === null) {
            customAlert(this.$t('general.error'), this.$t('general.somethingWentWrong'), "danger");
          } else {
            let {code, message} = res;

            if (code === 200) {

              customAlert(this.$t('userJobs.jobRemoved'), '', 'success');
              this.$emit('userJobUpdated', true);

            } else {
              customAlert(`Error ${code}`, `${message}`, "danger");
            }
          }

        });
      }


    },
    dayClicked(day) {
      console.log(day);
      console.log(day.attributes);

      if (day.attributes.length > 0 && (!this.dateRange || this.dateRange.start === null)) {
        this.dateRange = {start: null, end: null};
        let attr = day.attributes[0];

        if (attr.key === 'disabled') {
          return;
        }
        /* if(day.id ===attr.customData.localDateFrom){

             this.clickedDateAttributeId  = attr.key;
             setTimeout(()=> {
                 this.dateRange.start = new Date(attr.customData.localDateFrom);
                 this.dateRange.end = new Date(attr.customData.localDateTo);
             },500);

         } else if(day.id ===attr.customData.localDateTo){

             this.clickedDateAttributeId  = attr.key;
             this.dateRange.start = attr.customData.localDateTo;

         } else {*/

        this.openEditJobTimePopup({
          id: attr.key,
          cost: attr.customData.cost,
          duration: attr.customData.duration,
          color: attr.customData.color,
          start: attr.customData.localDateFrom,
          end: attr.customData.localDateTo,
        });
        // }
      }

    },
    closeAddEditBlock() {
      this.addNewBlock = false;
      this.newBlock = {};
      this.newBlockErrors = [];
    },

    editJob(id) {

      let jobS = this.schedules.filter(s => s.id === id);
      if (jobS.length !== 1) {
        this.$emit('userJobUpdated', true);
        customAlert(this.$t('userJobs.jobNotFound'));
      } else {

        let job = jobS[0];

        this.addNewBlock = true;
        this.newBlockErrors = [];

        this.newBlock = {
          id: job.id,
          fromLang: job.jobFromKey.keyword,
          toLang: job.jobToKey.keyword,
          categories: [],
          timeSheets: job.listOfJobTimes,
          sworn: job.sworn,
          swornStatus: job.swornStatus
        };

        job.listOfJobKnowledgeView.forEach(c => {
          this.newBlock.categories.push(c.knowledgeKey);
        })

      }


    },

    getLanguageIcon(keyword) {
      let code = keyword.split('.')[1];
      return `${mediaBase}/lang/${code}.png`
    },
  },

  mounted() {
  }
}
</script>

<style lang="scss" scoped>


.job-row {
  padding: 10px 0;
  border-bottom: 1px solid #b9b9bb;

  .languageIcon {
    height: 20px;
    margin-right: 10px;
  }
}


.readyRow {

  padding: 5px 0;
  border-bottom: 1px solid #b5b6ba;

}

.editingRow {

  padding: 10px 0;

  &.editInMiddle {
    border-bottom: 1px solid #b5b6ba;
  }

}

.color-ball {
  width: 20px;
  height: 20px;
  display: inline-block;
  border-radius: 100%;
}

:global(.vc-highlight-content-solid.vc-disabled) {
  color: #ffffff !important;
}


</style>