<template>

    <div class="bg-white">

        <top-navigation/>

    </div>

    <div class="container" style="margin: 100px auto;">


        <div class="row">


            <div class="col-lg-4 mb-5">

                <div class="text-primary fw-bold display-3">{{ $t('general.notFound.heading') }}</div>
                <h1 class="text-dark fw-bold">{{ $t('general.notFound.title') }}</h1>

                <div class="my-5">
                  {{ $t('general.notFound.description') }}
                </div>

                <div class="my-5">

                    <router-link class="btn btn-outline-primary" to="/search">
                      {{ $t('general.notFound.searchButtonText') }}
                    </router-link>

                </div>


            </div>
            <div class="col-md-6 offset-1 mb-5 p-5">
                <img src="../assets/theme/registration.png" class="img-fluid" :alt="$t('general.notFound.title')">
            </div>

        </div>

    </div>

    <the-footer/>

</template>

<script>
    // @ is an alias to /src

    import TopNavigation from "../components/layout/TopNavigation";
    import TheFooter from "../components/layout/TheFooter";

    export default {
        name: 'NotFoundPage',
        components: {TheFooter, TopNavigation},
        data() {
            return {};
        },
        methods: {},
        watch: {},
        mounted() {


        }
    }
</script>

<style lang="scss" scoped>

    @import "../assets/colors.scss";


    .btn-social {
        background: white;
        text-align: left;
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);

        &:hover {
            background: $color-violet;
        }
    }


</style>