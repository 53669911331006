<template>

  <div class="bg-white">

    <top-navigation/>

  </div>
  <div class="expert-profile-container">
    <div class="container" style="padding: 100px 0;">

      <div class="w-75 mx-auto" v-if="step ===1">

        <h3 class="text-dark fw-bold">{{ $t('profile.registrationForExpert') }}</h3>
        <p class="text-primary fw-bold">{{ $t('profile.expertBasicInformation') }}</p>

        <div class="my-5">
          <div class="card">

            <div class="card-body p-0">

              <div class="row">

                <div class="col-md-4 expert-profile-item">

                  <p class="fw-bold">{{ $t('expertType.translator') }}</p>

                  <img src="../assets/icon/expert-translate.png" class="img-fluid expert-profile-icon"
                       alt="">


                  <div>
                    <div class="expert-profile-select"
                         @click.prevent="profileSelection('translator')"
                         :class="{active: selectedProfiles.indexOf('translator')>-1}">
                      <span>{{ $t('general.select') }}</span>
                    </div>
                  </div>

                </div>


                <div class="col-md-4 expert-profile-item">

                  <p class="fw-bold">{{ $t('expertType.interpreter') }}</p>

                  <img src="../assets/icon/expert-interpreter.png"
                       class="img-fluid expert-profile-icon"
                       alt="">


                  <div>
                    <div class="expert-profile-select"
                         @click.prevent="profileSelection('interpreter')"
                         :class="{active: selectedProfiles.indexOf('interpreter')>-1}">
                      <span>{{ $t('general.select') }}</span>
                    </div>
                  </div>

                </div>

                <div class="col-md-4 expert-profile-item">

                  <p class="fw-bold">{{ $t('expertType.teacher') }}</p>

                  <img src="../assets/icon/expert-teacher.png" class="img-fluid expert-profile-icon"
                       alt="">


                  <div>
                    <div class="expert-profile-select"
                         @click.prevent="profileSelection('teacher')"
                         :class="{active: selectedProfiles.indexOf('teacher')>-1}">
                      <span>{{ $t('general.select') }}</span>
                    </div>
                  </div>

                </div>

              </div>

            </div>

          </div>
        </div>

        <div class="text-end my-5" v-if="selectedProfiles.length>0">
          <button class="btn btn-primary btn-pill btn-lg px-5 " @click="profileSelectionSubmit">
            {{ $t('general.next') }}
          </button>
        </div>

      </div>

      <div class="w-75 mx-auto" v-if="step ===2">

        <h3 class="text-dark fw-bold">{{ $t('profile.expertRegistration.title') }}</h3>

        <div class="my-4">

          <div class="d-flex justify-content-between position-relative">
            <div style="height: 1px; background: #812C79; position: absolute; left: 0; right: 0; top: 50%; z-index: -1">

            </div>
            <button class="btn btn-primary rounded-pill px-4" @click.prevent="goToStep(2)">{{
                $t('profile.expertRegistration.profileInfo')
              }}
            </button>
            <button class="btn btn-outline-primary tab-button  rounded-pill px-4"  @click.prevent="goToStep(3)">
              {{ $t('profile.expertRegistration.invoiceDetails') }}
            </button>
            <button class="btn btn-outline-primary tab-button rounded-pill px-4"  @click.prevent="goToStep(4)">
              {{ $t('profile.expertRegistration.detailedInformation') }}
            </button>
          </div>

        </div>

        <form @submit.prevent="personalInfoFormSubmit">
          <div class="my-5">
            <div class="card">

              <div class="card-body">

                            <span class="d-inline-block fw-bold me-2"
                                  :class="{'text-primary': selectedProfiles.indexOf('translator')>-1, 'text-secondary': selectedProfiles.indexOf('translator')===-1 }">
                                <i class="fa fa-check" v-if="selectedProfiles.indexOf('translator')>-1"  aria-hidden="true"></i>
                                {{ $t('expertType.translator') }}
                            </span>

                <span class="d-inline-block fw-bold me-2"
                      :class="{'text-primary': selectedProfiles.indexOf('interpreter')>-1, 'text-secondary': selectedProfiles.indexOf('interpreter')===-1 }">
                                <i class="fa fa-check" v-if="selectedProfiles.indexOf('interpreter')>-1" aria-hidden="true"></i>
                                {{ $t('expertType.interpreter') }}
                            </span>

                <span class="d-inline-block fw-bold me-2"
                      :class="{'text-primary': selectedProfiles.indexOf('teacher')>-1, 'text-secondary': selectedProfiles.indexOf('teacher')===-1 }">
                                <i class="fa fa-check" v-if="selectedProfiles.indexOf('teacher')>-1" aria-hidden="true"></i>
                                {{ $t('expertType.teacher') }}
                            </span>

                <div class="row mt-4">

                  <div class="col-md-4">
                    <div class="my-4">
                      <label>{{ $t('profile.name') }} <span class="text-primary fw-bold">*</span></label>
                      <input class="form-control form-control-lg" type="text"
                             pattern="[A-Za-z]+" maxlength="20" title="Only letters are allowed"
                             v-model="name" required>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="my-4">
                      <label>{{ $t('profile.surname') }} <span class="text-primary fw-bold">*</span></label>
                      <input class="form-control form-control-lg" type="text"
                             pattern="[A-Za-z]+" maxlength="20" title="Only letters are allowed"
                             v-model="surname" required>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="my-4">
                      <label>{{ $t('profile.title') }} <span class="text-primary fw-bold">*</span></label>
                      <input class="form-control form-control-lg" type="text" required
                             pattern="[A-Za-z]+" maxlength="15" title="Only letters are allowed"
                             v-model="title">
                    </div>
                  </div>


                </div>

              </div>

              <hr>

              <div class="card-body">

                <div class="row">

                  <div class="col-lg-6">

                    <div class="my-4">
                      <label>{{ $t('profile.employment') }} <span class="text-primary fw-bold">*</span> </label>

                      <div class="d-flex my-3">


                        <div class="form-check me-5">
                          <input class="form-check-input" type="radio" name="legalStatus"
                                 id="legalStatusIndividual" value="individual" required>
                          <label class="form-check-label" for="legalStatusIndividual">
                            {{ $t('profile.employmentStatus.individual') }}
                          </label>
                        </div>

                        <div class="form-check">
                          <input class="form-check-input" type="radio" name="legalStatus"
                                 id="legalStatusLegal" value="company" required>
                          <label class="form-check-label" for="legalStatusLegal">
                            {{ $t('profile.employmentStatus.company') }}
                          </label>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div class="col-lg-6">
                    <div class="my-4">
                      <label>{{ $t('profile.company') }}
                        <span class="text-primary fw-bold" v-if="employmentType==='company'">*</span>
                      </label>
                      <input class="form-control form-control-lg" type="text"
                              maxlength="25" :required="employmentType==='company'"
                             v-model="company">
                    </div>
                  </div>

                </div>

              </div>

            </div>
          </div>

          <div class="text-end my-5">
            <button type="submit" class="btn btn-primary btn-pill btn-lg px-5 ">{{ $t('general.next') }}
            </button>
          </div>

        </form>

      </div>


      <div class="w-75 mx-auto" v-if="step ===3">

        <h3 class="text-dark fw-bold">{{ $t('profile.expertRegistration.title') }}</h3>

        <div class="my-4">

          <div class="d-flex justify-content-between position-relative">
            <div style="height: 1px; background: #812C79; position: absolute; left: 0; right: 0; top: 50%; z-index: -1">

            </div>
            <button class="btn btn-outline-primary tab-button rounded-pill px-4" @click="goToStep(2)">
              {{ $t('profile.expertRegistration.profileInfo') }}
            </button>
            <button class="btn btn-primary  rounded-pill px-4"  @click.prevent="goToStep(3)">
              {{ $t('profile.expertRegistration.invoiceDetails') }}
            </button>
            <button class="btn btn-outline-primary tab-button rounded-pill px-4"  @click.prevent="goToStep(4)">
              {{ $t('profile.expertRegistration.detailedInformation') }}
            </button>
          </div>

        </div>

        <form @submit.prevent="invoiceFormSubmit">
          <div class="my-5">
            <div class="card">

              <div class="card-body">

                            <span class="d-inline-block fw-bold me-2"
                                  :class="{'text-primary': selectedProfiles.indexOf('translator')>-1, 'text-secondary': selectedProfiles.indexOf('translator')===-1 }">
                                <i class="fa fa-check" v-if="selectedProfiles.indexOf('translator')>-1" aria-hidden="true"></i>
                               {{ $t('expertType.translator') }}
                            </span>

                <span class="d-inline-block fw-bold me-2"
                      :class="{'text-primary': selectedProfiles.indexOf('interpreter')>-1, 'text-secondary': selectedProfiles.indexOf('interpreter')===-1 }">
                                <i class="fa fa-check" v-if="selectedProfiles.indexOf('interpreter')>-1" aria-hidden="true"></i>
                                {{ $t('expertType.interpreter') }}
                            </span>

                <span class="d-inline-block fw-bold me-2"
                      :class="{'text-primary': selectedProfiles.indexOf('teacher')>-1, 'text-secondary': selectedProfiles.indexOf('teacher')===-1 }">
                                <i class="fa fa-check" v-if="selectedProfiles.indexOf('teacher')>-1" aria-hidden="true"></i>
                                {{ $t('expertType.teacher') }}
                            </span>

                <div class="row mt-4">

                  <div class="col-md-6">
                    <div class="my-3">
                      <label>{{ $t('profile.invoiceAddress') }}<span class="text-primary fw-bold">*</span></label>
                      <GMapAutocomplete class="form-control form-control-lg"
                                        @place_changed="setPlace">
                      </GMapAutocomplete>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="my-3">
                      <label>{{ $t('profile.country') }}: <span class="text-primary fw-bold">*</span></label>
                      <select class="form-control form-control-lg"
                              v-model="country" required>
                        <option v-for="c in countries" :key="c.keyword" :value="c.keyword">
                          {{ c.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="my-3">
                      <label>{{ $t('profile.city') }} <span class="text-primary fw-bold">*</span></label>
                      <select class="form-control form-control-lg"
                              v-model="location" required>
                        <option v-for="c in locations" :key="c.id" :value="c.id">
                          {{ c.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="my-3">
                      <label>{{ $t('profile.phone') }} <span class="text-primary fw-bold">*</span></label>
                      <input class="form-control form-control-lg" type="tel"
                             pattern="[+0-9]{9,15}" title="Only numbers are allowed"
                             v-model="phone" required>
                    </div>
                  </div>


                </div>

              </div>

              <hr>

              <div class="card-body">

                <div class="row">

                  <div class="col-lg-6">
                    <div class="my-3">
                      <label>{{ $t('profile.accountNumber') }} <span class="text-primary fw-bold">*</span></label>
                      <input class="form-control form-control-lg" type="number"  min="1"
                             v-model="account">
                    </div>
                  </div>

                  <div class="col-lg-3">
                    <div class="my-3">
                      <label>{{ $t('profile.taxId') }} </label>
                      <input class="form-control form-control-lg" type="number">
                    </div>
                  </div>

                  <div class="col-lg-3">
                    <div class="my-3">
                      <label>{{ $t('profile.vatPayer') }} <span class="text-primary fw-bold">*</span></label>
                      <select class="form-control form-control-lg" v-model="edv" required>
                        <option value="0">{{ $t('general.no') }}</option>
                        <option value="1">{{ $t('general.yes') }}</option>
                      </select>
                    </div>
                  </div>

                  <div class="col-lg-3">
                    <div class="my-3">
                      <label>{{ $t('profile.swift') }}</label>
                      <input class="form-control form-control-lg" type="text"
                             pattern="[A-Za-z0-9]+" maxlength="10" title="Only alphanumeric are allowed"
                             v-model="swift">
                    </div>
                  </div><div class="col-lg-3">
                    <div class="my-3">
                      <label>{{ $t('profile.bic') }}</label>
                      <input class="form-control form-control-lg" type="text"
                             v-model="bic">
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="my-3">
                      <label>{{ $t('profile.iban') }}</label>
                      <input class="form-control form-control-lg" type="text"

                             v-model="iban">
                    </div>
                  </div>

                </div>

              </div>

            </div>
          </div>

          <div class="text-end my-5">
            <button type="submit" class="btn btn-primary btn-pill btn-lg px-5 ">{{ $t('general.next') }}
            </button>
          </div>

        </form>

      </div>

      <div class="w-75 mx-auto" v-if="step ===4">

        <h3 class="text-dark fw-bold">{{ $t('profile.expertRegistration.title') }}</h3>

        <div class="my-4">

          <div class="d-flex justify-content-between position-relative">
            <div style="height: 1px; background: #812C79; position: absolute; left: 0; right: 0; top: 50%; z-index: -1">

            </div>
            <button class="btn btn-outline-primary tab-button rounded-pill px-4"  @click.prevent="goToStep(2)">
              {{ $t('profile.expertRegistration.profileInfo') }}
            </button>
            <button class="btn btn-outline-primary tab-button  rounded-pill px-4"  @click.prevent="goToStep(3)">
              {{ $t('profile.expertRegistration.invoiceDetails') }}
            </button>
            <button class="btn btn-primary rounded-pill px-4"  @click.prevent="goToStep(4)">
              {{ $t('profile.expertRegistration.detailedInformation') }}
            </button>
          </div>

        </div>

        <form @submit.prevent="expertRegistrationFinsih">
          <div class="my-5">
            <div class="card">

              <div class="card-body">

                            <span class="d-inline-block fw-bold me-2"
                                  :class="{'text-primary': selectedProfiles.indexOf('translator')>-1, 'text-secondary': selectedProfiles.indexOf('translator')===-1 }">
                                <i class="fa fa-check" v-if="selectedProfiles.indexOf('translator')>-1" aria-hidden="true"></i>
                                {{ $t('expertType.translator') }}
                            </span>

                <span class="d-inline-block fw-bold me-2"
                      :class="{'text-primary': selectedProfiles.indexOf('interpreter')>-1, 'text-secondary': selectedProfiles.indexOf('interpreter')===-1 }">
                                <i class="fa fa-check" v-if="selectedProfiles.indexOf('interpreter')>-1" aria-hidden="true"></i>
                                {{ $t('expertType.interpreter') }}
                            </span>

                <span class="d-inline-block fw-bold me-2"
                      :class="{'text-primary': selectedProfiles.indexOf('teacher')>-1, 'text-secondary': selectedProfiles.indexOf('teacher')===-1 }">
                                <i class="fa fa-check" v-if="selectedProfiles.indexOf('teacher')>-1" aria-hidden="true"></i>
                                {{ $t('expertType.teacher') }}
                            </span>

                <div class="row mt-4">

                  <div class="col-lg-3">
                    <div class="my-3">
                      <label>{{ $t('profile.profilePhoto') }} </label>
                      <input type="file" style="display: none" id="fileInput" ref="file"
                             @input="setPhoto">
                      <div @click="selectPhoto">
                        <img v-if="photo === null || photo===''"
                             src="../assets/theme/placeholder.png" alt="avatar"
                             class="w-100 img-thumbnail">

                        <img v-else :src="`${squareImageBase}/${photo}`" class="w-100 img-thumbnail" alt="avatar">
                      </div>
                    </div>
                  </div>
                  <div class="col-md-9">
                    <div class="my-3">
                      <label>{{ $t('profile.enterBioInfo') }}</label>

                      <textarea class="form-control form-control-lg" style="height: 150px;"
                                v-model="description"></textarea>
                    </div>
                  </div>


                </div>

              </div>

            </div>
          </div>

          <div class="text-end my-5">
            <button type="submit" class="btn btn-primary btn-pill btn-lg px-5 ">
              {{ $t('general.complete') }}
            </button>
          </div>

        </form>

      </div>
    </div>
  </div>


  <the-footer/>

</template>

<script>
// @ is an alias to /src

import TopNavigation from "../components/layout/TopNavigation";
import TheFooter from "../components/layout/TheFooter";
import {
  getLocationList,
  getMe,
  getPropertyList,
  updateProfile,
  upload
} from "@/repositories/GeneralDataRepository";
import {mapState} from "vuex";
import {squareImageBase} from "@/repositories/Repository";
import customAlert from "@/repositories/Utils";

export default {
  name: 'Registration',
  components: {TheFooter, TopNavigation},
  data() {
    return {
      squareImageBase: squareImageBase,
      step: 1,
      selectedProfiles: [],
      name: '',
      surname: '',
      title: '',
      employmentType: '',
      company: '',

      country: '',
      location: '',
      address: '',
      latitude: 0,
      longitude: 0,

      phone: '',

      jste: '',
      taxId: '',
      account: '',
      edv: 0,
      swift: 0,
      bic: 0,
      iban: 0,

      description: '',

      photo: '',

      locations: [],
      countries: [],
      submittedSteps: []
    };
  },
  computed: {
    ...mapState({
      token: state => state.auth.token,
      user: state => state.auth.user
    }),
    submitData() {

      return {
        "id": this.user.id,
        "name": this.name,
        "surname": this.surname,
        "userType": this.user.userType,
        "title": this.title,
        "employmentType": this.employmentType,
        "companyName": this.company,
        "profilePhoto": this.photo,
        "description": this.description,
        "accountAddress": this.address,
        "location": {
          "id": this.location
        },
        "latitude": this.latitude,
        "longitude": this.longitude,
        "country": {
          "keyword": this.country
        },
        "phone": this.phone,
        "account": this.account,
        "iban": this.iban,
        "swift": this.swift,
        "bic": this.bic,
        "edv": this.edv,
        "allPhotos": [],
        "translator": this.selectedProfiles.indexOf('translator') > -1,
        "interpreter": this.selectedProfiles.indexOf('interpreter') > -1,
        "teacher": this.selectedProfiles.indexOf('teacher') > -1,
        "taxId": this.taxId,
        "jste": this.jste
      }
    }
  },

  watch:{
    "$i18n.locale": function(){
      this.getCountryList();
    }
  },
  methods: {
    profileSelection(type) {
      if (this.selectedProfiles.indexOf(type) > -1) {
        this.selectedProfiles.splice(this.selectedProfiles.indexOf(type), 1);
      } else {
        this.selectedProfiles.push(type);
      }
    },

    goToStep(step) {
      this.step = step;
    },
    profileSelectionSubmit() {
      this.step = 2;
    },
    personalInfoFormSubmit() {
      this.step = 3;
      if(this.submittedSteps.indexOf(2)===-1){
        this.submittedSteps.push(2);
      }
    },
    invoiceFormSubmit() {
      this.step = 4;
      if(this.submittedSteps.indexOf(3)===-1){
        this.submittedSteps.push(3);
      }
    },
    expertRegistrationFinsih() {

      if(this.submittedSteps.length<2){
        customAlert(this.$t('general.error'), this.$t('profile.expertRegistration.pleaseFillAllFields'), 'danger');
        return;
      }

      updateProfile(this.token, this.submitData).then(res => {

        if (res === null) {
          customAlert(this.$t('general.error'), this.$t('general.somethingWentWrong'), 'danger');
        } else {
          let {code, message} = res;

          if (code === 200) {

            customAlert(this.$t('general.success'), this.$t('profile.expertProfileCreated'), 'success');
            this.$store.dispatch('auth/loadUser', this.token);

            this.$router.push('/profile');

          } else {
            alert(`${code}: ${message}`);
          }
        }

      });


    },
    setPlace(d) {
      console.log(d);
      this.address = d.formatted_address;
      this.latitude = d.geometry.location.lat();
      this.longitude = d.geometry.location.lng();
    },

    selectPhoto() {
      window.document.getElementById('fileInput').click();
    },
    setPhoto() {
      let currentFile = this.$refs.file.files[0];

      console.log(currentFile);
      if (window.File && window.FileReader && window.FileList && window.Blob) {


        upload(currentFile, event => {
          this.progress = Math.round((100 * event.loaded) / event.total);
        })
            .then(response => {

              this.$refs.file.value = null;

              if (response.data.code === 200) {
                this.photo = response.data.body;
              } else {
                this.uploadMessage = response.data.message;
              }
            })
            .catch((e) => {
              console.log(e);
              this.progress = 0;
              this.uploadMessage = this.$t('general.canNotUploadFile') + e;
            });

      } else {
        customAlert(this.$t('general.photoUploadNotSupportedOnYourBrowser'));
      }
    },


    getCountryList(){
      getPropertyList('countries', this.$i18n.locale).then((data) => {
        this.countries = data;
      });
    }
  },
  mounted() {

    getMe(this.token).then(data => {

      if (data.code === 200) {

        if ((data.body.translator || data.body.interpreter || data.body.teacher || data.body.userType === 'USER')) {
          this.$router.push('/profile/edit');
        } else {

          this.name = data.body.name;
          this.surname = data.body.surname;
          this.title = data.body.title;
          this.phone = data.body.phone;
          this.legalStatus = data.body.account;
          this.company = data.body.companyName;
          this.edv = data.body.edv;
          this.swift = data.body.swift;
          this.bic = data.body.bic;
          this.iban = data.body.iban;
          this.account = data.body.account;
          this.taxId = data.body.taxId;
          this.jste = data.body.jste;

          this.employmentType = data.body.employmentType;
          this.country = data.body.country.keyword;
          this.location = data.body.location.id;
          this.latitude = data.body.latitude;
          this.longitude = data.body.longitude;
          this.address = data.body.accountAddress;

          this.photo = data.body.profilePhoto;

          this.description = data.body.description;

          if (data.body.translator) {
            this.selectedProfiles.push('translator');
          }
          if (data.body.interpreter) {
            this.selectedProfiles.push('interpreter');
          }
          if (data.body.teacher) {
            this.selectedProfiles.push('teacher');
          }

        }

      } else if (data.code === 401) {
        window.location.reload();
      }

    });

    getLocationList().then((data) => {
      this.locations = data;
    });

    this.getCountryList();

  }
}
</script>

<style lang="scss" scoped>

@import "../assets/colors.scss";

.expert-profile-container {
  background: url('../assets/theme/expert-footer.png') no-repeat center bottom;
  background-size: 100% auto;
}

.tab-button{
  background: #e6e5e6;
}

.expert-profile-item {

  text-align: center;
  padding: 50px 20px;
  border-right: 1px solid $color-dark;

  &:last-child {
    border-right: none;
  }

  .expert-profile-icon {
    margin: 50px auto;
  }


  .expert-profile-select {
    display: inline-block;
    position: relative;

    color: $color-violet;
    font-weight: bold;
    line-height: 40px;

    padding: 0 20px 0 40px;
    border: 1px solid $color-violet;
    border-radius: 25px;

    &:before {
      content: '';
      display: inline-block;
      width: 20px;
      height: 20px;
      border: 1px solid $color-violet;

      position: absolute;
      left: 10px;
      top: 10px;
      border-radius: 10px;
    }

    &.active {
      background: $color-violet;
      color: white;

      &:before {
        border-color: white;
        background: url("../assets/icon/done.png") no-repeat center $color-violet;
        background-size: 50% 50%;
      }
    }

  }

}

.card {
  border-radius: 20px;

  .card-body {
    padding: 20px;
  }
}


</style>