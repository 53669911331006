<template>

    <div class="bg-white">

        <top-navigation/>

    </div>
    <div class="expert-profile-container" v-if="user=== null">
        <div class="container" style="padding: 100px 0;">
            <div class="alert alert-info">{{ $t('general.loading') }}</div>
        </div>
    </div>
    <div class="expert-profile-container" v-else>
        <div class="container" style="padding: 100px 0;">


            <div class="row">
                <div class="col-md-6">
                    <div class="card">
                        <div class="card-body">
                            <OrderDetails :active-order="activeOrder" v-if="activeOrder"></OrderDetails>
                        </div>
                        <hr>
                        <div class="card-body">
                            <button @click="startChat" class="btn btn-primary">{{ $t('order.backToConversation') }}</button>
                        </div>
                    </div>
                </div>

                <div class="col-md-6  mb-5 p-5">
                    <img src="../assets/theme/registration.png" class="img-fluid" alt="image">
                </div>
            </div>


        </div>
    </div>


    <the-footer/>

</template>

<script>
    // @ is an alias to /src

    import TopNavigation from "../components/layout/TopNavigation";
    import TheFooter from "../components/layout/TheFooter";
    import {mapState} from "vuex";
    import OrderDetails from "../components/order/OrderDetails";
    import {getOrder, startChat} from "@/repositories/GeneralDataRepository";
    import customAlert from "../repositories/Utils";

    export default {
        name: 'ProfileEdit',
        components: {TheFooter, TopNavigation, OrderDetails},
        data() {
            return {
                activeOrder: null
            };
        },
        computed: {
            ...mapState({
                token: state => state.auth.token,
                user: state => state.auth.user
            }),
            lastOfferDetails() {
                if (this.activeOrder == null) {
                    return null;
                }
                if (this.activeOrder.secondOrder) {
                    let secondOrder = this.activeOrder.secondOrder;
                    secondOrder.expert = this.activeOrder.firstOrder.expert;
                    secondOrder.user = this.activeOrder.firstOrder.user;
                    secondOrder.orderNo = this.activeOrder.firstOrder.orderNo;
                    return secondOrder;
                } else {
                    return this.activeOrder.firstOrder;
                }
            }
        },
        methods: {
            getOrderDetails(id) {


                getOrder(this.token, id).then(res => {

                    if (res === null) {
                        customAlert(this.$t('general.error'), this.$t('order.error.canNotGetError'), 'danger');
                    } else if (res.code === 200) {

                        this.activeOrder = res.body;

                        // this.ordersViewIsOpen = false;

                    } else {
                        customAlert(this.$t('general.error'), res.message, 'danger');
                    }

                })

            },


            startChat() {

                let userId = null;
                if(this.lastOfferDetails.idExpert===this.user.id){
                    userId = this.lastOfferDetails.idUser;
                } else {
                    userId = this.lastOfferDetails.idExpert;
                }

                startChat(this.token, userId).then(data => {

                    if (data.code === 200 && data.body) {

                        this.$router.push({name: 'chat', params: {id: data.body.current.id}})

                    } else {
                        customAlert(this.$t('conversation.error.canNotStartConversation'));
                    }

                });
            }

        },
        mounted() {

            this.getOrderDetails(this.$route.params['id']);

        }
    }
</script>

<style lang="scss" scoped>

    .card {
        border-radius: 20px;

        .card-body {
            padding: 20px;
        }
    }


</style>