<template>
    <div class="card-body">

        <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex justify-content-start align-items-center">

                <div class="avatar" :style="{
                                'background-image': `url('${expertAvatar}')`
                            }"></div>

                <div class="ps-2">

                    <strong >
                        {{o.firstOrder.expert.name}} {{o.firstOrder.expert.surname}}
                    </strong>
                    <div class="d-flex justify-content-start align-items-center">
                        <img :src="getLanguageIcon(o.firstOrder.jobFromKey.keyword)"
                             style="height: 18px;" class="me-1" :alt="o.firstOrder.jobFromKey.name">
                        {{o.firstOrder.jobFromKey.name}}
                    </div>
                    <div class="d-flex justify-content-start align-items-center" v-if="o.firstOrder.orderType !== 'TEACHER'">
                        <img :src="getLanguageIcon(o.firstOrder.jobToKey.keyword)"
                             style="height: 18px;" class="me-2"  :alt="o.firstOrder.jobToKey.name">
                        <span>{{o.firstOrder.jobToKey.name}}</span>
                    </div>
                </div>
            </div>

            <div class="text-end">

                <strong>#{{o.firstOrder.orderNo}}</strong> <br>
                {{o.firstOrder.totalAmount}} Kc <br>
                <span class="small">
                    {{o.firstOrder.count}}
                    <span v-if="o.firstOrder.orderType==='TRANSLATOR'">
                      {{$tc('order.units.page', o.firstOrder.count)}}
                    </span>
                    <span v-if="o.firstOrder.orderType==='TEACHER'">
                      {{$tc('order.units.lesson', o.firstOrder.count)}}
                    </span>
                    <span v-if="o.firstOrder.orderType==='INTERPRETER'">
                        <span v-if="o.firstOrder.period==='HOURLY'">
                          {{$tc('order.units.hour', o.firstOrder.count)}}
                        </span>
                        <span v-else>
                          {{$tc('order.units.day', o.firstOrder.count)}}
                        </span>
                    </span>
                </span>
            </div>
        </div>


    </div>
</template>

<script>
import {mediaBase, squareImageBase} from "@/repositories/Repository";
    import {mapState} from "vuex";

    export default {
        name: "OrderListItem",
        props: ['o'],
        computed: {
            ...mapState(
                {
                    token: state => state.auth.token,
                    user: state => state.auth.user
                }
            ),
            expertAvatar() {
                return `${this.o.firstOrder.expert.profilePhoto === null || this.o.firstOrder.expert.profilePhoto === '' ?
                    '/img/avatar_placeholder.png' :
                    `${squareImageBase}/${this.o.firstOrder.expert.profilePhoto}`}`
            }
        },
        methods: {

            getLanguageIcon(keyword) {
                let code = keyword.split('.')[1];
                return `${mediaBase}/lang/${code}.png`
            }
        }
    }
</script>

<style scoped>


    .avatar {
        display: inline-block;
        width: 50px;
        height: 50px;
        min-width: 50px;
        max-width: 50px;
        background-size: cover;
        border-radius: 100%;
        border: 1px solid #9EA2B2;

    }
</style>