<template>
  <section v-if="!user">

    <div class="container my-5">
      <div class="row">

        <div class="col-md-6 d-flex align-items-center">

          <div>
            <h1 class="fw-bold text-primary ">
              {{ $t('body.findClientsOnline.title') }}
            </h1>

            <p class="lead fw-normal">
              {{ $t('body.findClientsOnline.body') }}
            </p>


            <div class="my-4">
              <router-link to="/register" class="btn btn-dark btn-lg btn-pill px-4">
                {{ $t('body.findClientsOnline.join') }}
              </router-link>
            </div>

          </div>


        </div>


        <div class="col-md-6">
          <img src="../../assets/theme/photo-2.png" class="w-100 p-5" :alt="$t('body.findClientsOnline.title')">
        </div>

      </div>

    </div>

  </section>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "FindClientsOnline",
  computed: {
    ...mapState(
        {
          token: state => state.auth.token,
          user: state => state.auth.user
        }
    ),
  }
}
</script>

<style scoped>

</style>