import {createToast} from "mosha-vue-toastify";

import 'mosha-vue-toastify/dist/style.css'

export default function customAlert(text, description,type){
    createToast({
            title: text,
            description: description
        },
        {
            showIcon: true,
            transition: 'bounce',
            type: type,
            hideProgressBar: true,
            position: 'top-right',
        })
}