<template>

  <div class="bg-white">

    <top-navigation/>

  </div>

  <div class="container py-5">


    <div class="card" v-if="isLoading">
      <div class="card-body">
        <div class="alert alert-info">{{ $t('general.loading') }}</div>
      </div>
    </div>
    <div class="card" v-else-if="page===null">
      <div class="card-body">
        <div class="row">

          <div class="col-md-6 d-flex align-items-center">

            <div>
              <h1 class="fw-bold text-primary ">{{ $t('general.notFound.title') }}</h1>

              <p class="lead fw-normal">{{ $t('general.notFound.description') }}</p>


              <div class="my-4">
                <router-link to="/search" class="btn btn-dark btn-lg btn-pill px-4">
                  {{ $t('general.notFound.searchButtonText') }}
                </router-link>
              </div>

            </div>


          </div>


          <div class="col-md-6">
            <img src="../assets/theme/photo-2.png" class="w-100 p-5" alt="not found">
          </div>

        </div>
      </div>
    </div>
    <div class="card" v-else>

      <div class="card-body">

        <h2>{{ page.title }}</h2>

        <div v-html="page.body"></div>
      </div>

    </div>

  </div>


  <find-clients-online/>

  <the-footer/>

</template>

<script>
// @ is an alias to /src

import TopNavigation from "../components/layout/TopNavigation";
import TheFooter from "../components/layout/TheFooter";
import FindClientsOnline from "../components/info/FindClientsOnline";
import {getPageData} from "@/repositories/GeneralDataRepository";

export default {
  name: 'Home',
  components: {FindClientsOnline, TheFooter, TopNavigation},
  data() {
    return {
      page: null,
      isLoading: true
    };
  },
  watch: {
    "$route"() {
      this.loadPage();
    },
    "$i18n.locale": function () {
      this.loadPage();
    }
  },
  methods: {

    loadPage() {
      let language = this.$i18n.locale;
      if(!language){
        language = 'en';
      }
      this.isLoading = true;
      getPageData(this.$route.params.id, language).then(data => {
        this.page = data;
        this.isLoading = false;
        window.scrollTo({top:0})
      });
    }

  },
  mounted() {

    this.loadPage();

  }
}
</script>

<style lang="scss" scoped>

@import "../assets/colors.scss";


.card {
  border-radius: 30px;
}

.card-body {
  padding: 30px;
}

p {
  font-size: 20px;
}

</style>
