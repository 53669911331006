<template>

  <div class="row" v-if="lastOfferDetails.orderType==='TRANSLATOR'">

    <div class="col-md-12 mb-3">
      <div class="d-flex justify-content-start align-items-center">
        <div class="avatar" :style="{'background-image': `url('${expertAvatar}')`}"></div>
        <div class="ps-2">
          <strong>{{ $t('order.expert') }}</strong><br>
          {{ lastOfferDetails.expert.name }} {{ lastOfferDetails.expert.surname }}
        </div>
      </div>
    </div>

    <div class="col-md-12 mb-3">
      <span>{{ $t('orderType.translation') }}</span>
      <br>
      <div class="row">
        <div class="col-md-6 mb-3">
          <img :src="getLanguageIcon(lastOfferDetails.jobFromKey.keyword)" style="height: 18px;" class="me-1"
               :alt="lastOfferDetails.jobFromKey.name">
          {{ lastOfferDetails.jobFromKey.name }}
        </div>
        <div class="col-md-6 mb-3">
          <img :src="getLanguageIcon(lastOfferDetails.jobToKey.keyword)" style="height: 18px;" class="me-1"
               :alt="lastOfferDetails.jobToKey.name">
          {{ lastOfferDetails.jobToKey.name }}
        </div>
      </div>
    </div>


    <div class="col-md-6 mb-3">
      <span>{{ $t('order.pageCount') }}</span> <br>
      <del class="text-danger me-2"
           v-if="activeOrder.secondOrder && activeOrder.firstOrder.count !== lastOfferDetails.count">
        {{ activeOrder.firstOrder.count }}
      </del>
      <strong>{{ lastOfferDetails.count }}</strong>
    </div>

    <div class="col-md-6 mb-3">
      <span>{{ $t('order.pricePerPage') }}</span> <br>
      <del class="text-danger me-2"
           v-if="activeOrder.secondOrder && activeOrder.firstOrder.price !== lastOfferDetails.price">
        {{ activeOrder.firstOrder.price }} {{ $t('general.currencyShort') }}
      </del>
      <strong>{{ lastOfferDetails.price }} {{ $t('general.currencyShort') }}</strong>
    </div>


    <div class="col-md-12 mb-3">
      <span>{{ $t('order.deadline') }}</span> <br>
      <del class="text-danger me-2"
           v-if="activeOrder.secondOrder && activeOrder.firstOrder.endDate !== lastOfferDetails.endDate">
        {{ formatDateTime(activeOrder.firstOrder.endDate) }}
      </del>
      <strong>{{ formatDateTime(lastOfferDetails.endDate) }}</strong>
    </div>
    <div class="col-md-12 mb-3" v-if="lastOfferDetails.orderStatus === 'WAITING'">
      <span>{{ $t('order.offerAvailableUntil') }}</span> <br>
      <strong>{{ formatDateTime(lastOfferDetails.expireDateTime) }}</strong>
    </div>


    <div class="col-md-6 mb-3">
      <div v-if="lastOfferDetails.notar"><strong>{{ $t('order.extras.notar') }}: </strong> {{ lastOfferDetails.notar }}
        Kc
      </div>
      <div v-if="lastOfferDetails.post"><strong>{{ $t('order.extras.post') }}: </strong> {{ lastOfferDetails.post }} Kc
      </div>
      <div v-if="lastOfferDetails.kuryer"><strong>{{ $t('order.extras.courier') }}: </strong>
        {{ lastOfferDetails.kuryer }} Kc
      </div>
    </div>

    <div class="col-md-12 mb-3">
      <span>{{ $t('order.totalPrice') }}</span> <br>
      <del class="text-danger me-2"
           v-if="activeOrder.secondOrder && activeOrder.firstOrder.totalAmount !== lastOfferDetails.totalAmount">
        {{ activeOrder.firstOrder.totalAmount }} {{ $t('general.currencyShort') }}
      </del>
      <strong>{{ lastOfferDetails.totalAmount }} {{ $t('general.currencyShort') }}</strong>
    </div>

  </div>
  <div class="row" v-if="lastOfferDetails.orderType==='INTERPRETER'">

    <div class="col-md-12 mb-3">
      <div class="d-flex justify-content-start align-items-center">

        <div class="avatar" :style="{
                                'background-image': `url('${expertAvatar}')`
                            }"></div>

        <div class="ps-2">


          <strong>
            {{ $t('order.expert') }}
          </strong><br>
          {{ lastOfferDetails.expert.name }} {{ lastOfferDetails.expert.surname }}
        </div>
      </div>
    </div>

    <div class="col-md-12 mb-3">
      <span>{{ $t('orderType.interpreter') }}</span>
      <br>
      <div class="row">
        <div class="col-md-6 mb-3">
          <img :src="getLanguageIcon(lastOfferDetails.jobFromKey.keyword)" style="height: 18px;" class="me-1"
               :alt="lastOfferDetails.jobFromKey.name">
          {{ lastOfferDetails.jobFromKey.name }}
        </div>
        <div class="col-md-6 mb-3">
          <img :src="getLanguageIcon(lastOfferDetails.jobToKey.keyword)" style="height: 18px;" class="me-1"
               :alt="lastOfferDetails.jobToKey.name">
          {{ lastOfferDetails.jobToKey.name }}
        </div>
      </div>
    </div>


    <div class="col-md-6 mb-3">
      <span>{{ $t('order.workVolume') }}</span> <br>
      <strong>
        {{ lastOfferDetails.count }}
        <span v-if="lastOfferDetails.period==='HOURLY'">
                  {{ $tc('order.hour', lastOfferDetails.count) }}
                </span>
        <span v-else>{{ $tc('order.day', lastOfferDetails.count) }}</span>
      </strong>
    </div>

    <div class="col-md-6 mb-3">
      <span>{{ $t('order.price') }}</span> <br>
      <strong>{{ lastOfferDetails.price }} {{ $t('general.currencyShort') }}</strong>
    </div>

    <div class="col-md-12 mb-3">
      <span>{{ $t('order.deadline') }}</span> <br>
      <strong>{{ formatDateTime(lastOfferDetails.endDate) }}</strong>
    </div>
    <div class="col-md-12 mb-3" v-if="lastOfferDetails.orderStatus === 'WAITING'">
      <span>{{ $t('order.offerAvailableUntil') }}</span> <br>
      <strong>{{ formatDateTime(lastOfferDetails.expireDateTime) }}</strong>
    </div>
    <div class="col-md-12 mb-3">
      <span>{{ $t('order.location') }}</span> <br>
      <strong>{{ lastOfferDetails.address }}</strong>
    </div>

    <div class="col-md-6 mb-3">
      <div v-if="lastOfferDetails.doprava">
        <strong>{{ $t('order.extras.transport') }}: </strong>
        {{ lastOfferDetails.doprava }} {{ $t('general.currencyShort') }}
      </div>
    </div>


    <div class="col-md-12 mb-3">
      <span>{{ $t('order.totalPrice') }}</span> <br>
      <strong>{{ lastOfferDetails.totalAmount }} {{ $t('general.currencyShort') }}</strong>
    </div>

  </div>
  <div class="row" v-if="lastOfferDetails.orderType==='TEACHER'">

    <div class="col-md-12 mb-3">
      <div class="d-flex justify-content-start align-items-center">

        <div class="avatar" :style="{
                                'background-image': `url('${expertAvatar}')`
                            }"></div>

        <div class="ps-2">


          <strong>
            {{ $t('order.expert') }}
          </strong><br>
          {{ lastOfferDetails.expert.name }} {{ lastOfferDetails.expert.surname }}
        </div>
      </div>
    </div>

    <div class="col-md-12 mb-3">
      <span>{{ $t('orderType.teacher') }}</span>
      <br>
      <div class="row">
        <div class="col-md-6 mb-3">
          <img :src="getLanguageIcon(lastOfferDetails.jobFromKey.keyword)" style="height: 18px;" class="me-1"
               :alt="lastOfferDetails.jobFromKey.name">
          {{ lastOfferDetails.jobFromKey.name }}
        </div>
      </div>
    </div>


    <div class="col-md-6 mb-3">
      <span>{{ $t('order.lessonCount') }}</span> <br>
      <strong>
        {{ lastOfferDetails.count }}
      </strong>
    </div>

    <div class="col-md-6 mb-3">
      <span>{{ $t('order.duration') }}</span> <br>
      <strong>
        {{ lastOfferDetails.duration }} {{ $t('order.minute') }}
      </strong>
    </div>

    <div class="col-md-12 mb-3">
      <span>{{ $t('order.price') }}</span> <br>
      <strong>{{ lastOfferDetails.price }} {{ $t('general.currencyShort') }}</strong>
    </div>

    <div class="col-md-6 mb-3">
      <span>{{ $t('order.fromDate') }}</span> <br>
      <strong>{{ lastOfferDetails.startDate }}</strong>
    </div>
    <div class="col-md-6 mb-3">
      <span>{{ $t('order.toDate') }}</span> <br>
      <strong>{{ lastOfferDetails.endDate }}</strong>
    </div>
    <div class="col-md-12 mb-3">
      <span>{{ $t('order.location') }}</span> <br>
      <strong>{{ lastOfferDetails.address }}</strong>
    </div>


    <div class="col-md-12 mb-3">
      <span>{{ $t('order.deadline') }}</span> <br>
      <strong>{{ formatDateTime(lastOfferDetails.endDate) }}</strong>
    </div>
    <div class="col-md-12 mb-3" v-if="lastOfferDetails.orderStatus === 'WAITING'">
      <span>{{ $t('order.offerAvailableUntil') }}</span> <br>
      <strong>{{ formatDateTime(lastOfferDetails.expireDateTime) }}</strong>
    </div>

    <div class="col-md-6 mb-3">
      <div v-if="lastOfferDetails.ucebniMaterialy">
        <strong>{{ $t('order.extras.lessonMaterials') }}</strong>
        {{ lastOfferDetails.ucebniMaterialy }} {{ $t('general.currencyShort') }}
      </div>
    </div>

    <div class="col-md-12 mb-3">
      <span>{{ $t('order.totalPrice') }}</span> <br>
      <strong>{{ lastOfferDetails.totalAmount }} {{ $t('general.currencyShort') }}</strong>
    </div>

  </div>

</template>

<script>
import {mediaBase, squareImageBase} from "@/repositories/Repository";
import moment from 'moment';

export default {
  name: "OrderDetails",

  props: ['activeOrder'],

  computed: {

    expertAvatar() {
      return `${this.lastOfferDetails.expert.profilePhoto === null || this.lastOfferDetails.expert.profilePhoto === '' ?
          '/img/avatar_placeholder.png' :
          `${squareImageBase}/${this.lastOfferDetails.expert.profilePhoto}`}`
    },
    lastOfferDetails() {
      if (this.activeOrder == null) {
        return null;
      }
      if (this.activeOrder.secondOrder) {
        let secondOrder = this.activeOrder.secondOrder;
        secondOrder.expert = this.activeOrder.firstOrder.expert;
        secondOrder.user = this.activeOrder.firstOrder.user;
        secondOrder.orderNo = this.activeOrder.firstOrder.orderNo;
        return secondOrder;
      } else {
        return this.activeOrder.firstOrder;
      }
    },

  },
  methods: {
    getLanguageIcon(keyword) {
      let code = keyword.split('.')[1];
      return `${mediaBase}/lang/${code}.png`
    },
    formatDateTime(dateTime) {
      return moment(dateTime).format("DD MMM YYYY HH:mm")
    }
  }
}
</script>

<style scoped>


.avatar {
  display: inline-block;
  width: 50px;
  height: 50px;
  min-width: 50px;
  max-width: 50px;
  background-size: cover;
  border-radius: 100%;
  border: 1px solid #9EA2B2;

}
</style>