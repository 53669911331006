<template>
    <form @submit.prevent="submitNewOrderForm">
        <div class="row mt-3">
            <div class="col-md-6 mb-3">
                <select class="form-control" v-model="orderFromLang" required>
                    <option v-for="l in languages" :key="l.keyword" :value="l.keyword">{{l.name}}</option>
                </select>
            </div>
            <div class="col-md-6 mb-3">
                <select class="form-control" v-model="orderToLang" required>
                    <option v-for="l in languages" :key="l.keyword" :value="l.keyword">{{l.name}}</option>
                </select>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-sm-6">
                <label><input type="radio" name="orderPeriod" value="HOURLY" v-model="orderPeriod">
                {{$t('order.hourly')}}
                </label>
            </div>
            <div class="col-sm-6">
                <label><input type="radio" name="orderPeriod" value="DAILY" v-model="orderPeriod">
                  {{$t('order.daily')}}
                </label>
            </div>
        </div>

        <div class="row mb-3">
            <div class="col-md-6">
                <label>
                    <span v-if="orderPeriod==='HOURLY'">{{$t('orderForm.howManyHours')}}</span>
                    <span v-else>{{$t('orderForm.howManyDays')}}</span>
                </label>
                <input class="form-control" v-model.number="orderPageCount" min="1" required>
            </div>
            <div class="col-md-6">
                <label>
                    <span v-if="orderPeriod==='HOURLY'">{{$t('order.pricePerHour')}}</span>
                    <span v-else>{{$t('order.pricePerDay')}}</span>
                </label>
              <div class="input-group">
                <input class="form-control" v-model.number="orderPricePerPage" min="1" required>
                <div class="input-group-text">
                  {{ $t('general.currencyShort') }}
                </div>
              </div>

            </div>
        </div>

        <div class="mb-3">
            <label>{{$t('order.deadline')}}</label>
            <DatePicker v-model="orderDeadline" mode="dateTime" is24hr :minute-increment="15"
                        :min-date="expireDateTime || new Date() " :is-required="true"
                        :masks="{modelValue: 'YYYY-MM-DD HH:mm:ss'}">
                <template v-slot="{ inputValue, inputEvents }">
                    <input type="text" class="form-control " :value="inputValue"
                           v-on="inputEvents" required>
                </template>
            </DatePicker>


        </div>
        <div class="mb-3">
            <label>{{$t('order.deadlineForAcceptance')}}</label>
            <DatePicker v-model="expireDateTime" mode="dateTime" is24hr :minute-increment="15"
                        :max-date="orderDeadline" :min-date="new Date()" :is-required="true"
                        :masks="{modelValue: 'YYYY-MM-DD HH:mm:ss'}">
                <template v-slot="{ inputValue, inputEvents }">
                    <input type="text" class="form-control " :value="inputValue"
                           v-on="inputEvents"
                           placeholder="" required>
                </template>
            </DatePicker>
        </div>


        <hr class="my-4">

        <div class="mb-3">
            <label>{{$t('order.location')}}</label>
            <GMapAutocomplete class="form-control form-control-lg"
                              @place_changed="setPlace" :value="address">
            </GMapAutocomplete>
        </div>


        <div class="row my-3">
            <div class="col-md-3 d-flex align-items-center">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="1" v-model="orderDopravaStatus"
                           id="orderPostPrice">
                    <label class="form-check-label" for="orderPostPrice">
                      {{$t('order.extras.transport')}}
                    </label>
                </div>
            </div>
            <div class="col-md-9">
                <input class="form-control" v-model.number="orderDopravaPrice">
            </div>
        </div>

        <hr>
        <div class="d-flex justify-content-between align-items-center">
            <div>
              {{$t('order.totalPrice')}}:
            </div>
            <div class="text-primary fw-bold">
                {{orderTotalPrice}} {{$t('general.currencyShort')}}
            </div>
        </div>

        <hr>


        <div class="my-3">
            <button type="submit" class="btn btn-primary rounded-pill w-100">
              {{$t('general.send')}}
            </button>
        </div>
    </form>
</template>

<script>
import {createOrder, getExpertLanguages} from "@/repositories/GeneralDataRepository";
    import customAlert from "../../repositories/Utils";
    import {mapState} from "vuex";
    import moment from "moment/moment";

    export default {
        name: "InterpreterOrderForm",
        props: ['targetUserId'],
        data() {
            return {
                languages: [],

                orderFromLang: 0,
                orderToLang: 0,
                orderPeriod: 'HOURLY',
                orderPageCount: 1,
                orderPricePerPage: 0,
                orderDeadlineValue: '',
                expireDateTimeValue: '',
                orderDopravaStatus: false,
                orderDopravaPrice: 0,

                address: '',
                latitude: 0,
                longitude: 0,
            }
        },

        computed: {
            ...mapState(
                {
                    token: state => state.auth.token,
                    user: state => state.auth.user,
                }
            ),
            orderTotalPrice() {
                return this.orderPricePerPage * this.orderPageCount
                    + (this.orderDopravaStatus && this.orderDopravaPrice ? this.orderDopravaPrice : 0);
            },

          orderDeadline:{
            get() {
              if(!this.orderDeadlineValue)  {
                return null;
              }
              return moment(this.orderDeadlineValue).format('YYYY-MM-DD HH:mm:ss');
            },
            set(value) {
              this.orderDeadlineValue = value;
            }
          },
          expireDateTime:{
            get() {
              if(!this.expireDateTimeValue)  {
                return null;
              }
              return moment(this.expireDateTimeValue).format('YYYY-MM-DD HH:mm:ss');
            },
            set(value) {
              this.expireDateTimeValue = value;
            }
          },
        },
        watch: {

            orderDopravaPrice(newVal) {
                if (newVal > 0) {
                    this.orderDopravaStatus = true;
                } else if (!newVal) {
                    this.orderDopravaStatus = false;
                }
            },

          "$i18n.locale": function () {
            this.getLanguages();
          }

        },
        methods: {
            submitNewOrderForm() {

                let data = {
                    "firstOrder": {
                        "jobFromKey": {
                            "keyword": this.orderFromLang
                        },
                        "jobToKey": {
                            "keyword": this.orderToLang
                        },
                        "price": this.orderPricePerPage,
                        "count": this.orderPageCount,
                        "startDate": this.orderDeadline,
                        "endDate": this.orderDeadline,
                        "expireDateTime": this.expireDateTime,
                        "duration": 0,
                        "lat": this.latitude,
                        "lan": this.longitude,
                        "address": this.address,
                        "period": this.orderPeriod,
                        "post": 0,
                        "notar": 0,
                        "kuryer": 0,
                        "doprava": (this.orderDopravaStatus ? this.orderDopravaPrice : 0),
                        "ucebniMaterialy": 0,
                        "note": '',
                        "idExpert": this.user.id,
                        "idUser": this.targetUserId,
                        "orderType": "INTERPRETER"
                    }
                };

                createOrder(this.token, data).then(({code, body, message}) => {

                    if (code === 200) {
                        customAlert(this.$t('orderForm.orderCreated'), '', 'success');
                        let newId =body.firstOrder.id;

                        this.$emit('created', newId);
                    } else {
                        customAlert(this.$t('orderForm.orderCanNotBeCreated'), message, 'danger');
                    }

                })

            },

            setPlace(d) {
                console.log(d);
                this.address = d.formatted_address;
                this.latitude = d.geometry.location.lat();
                this.longitude = d.geometry.location.lng();
            },

          getLanguages() {
            getExpertLanguages(this.token,'INTERPRETER').then((data) => {
              this.languages = data.body;
            });
          }

        }, mounted() {

        this.getLanguages();
        }
    }
</script>

<style lang="scss">

    ::v-deep .pac-container {
        z-index: 10000;
    }
</style>
