<template>


  <div class="mt-5">

    <div class="row">
      <div class="col-sm-9 ">
        <p class="fw-bold lead mb-3">{{ $t('userOrders.title') }}</p>
      </div>
      <div class="col-sm-3 ">

        <select class="form-control" v-model="perPage">
          <option v-for="p in pageOptions" :key="p" :value="p">
            {{ p }}
          </option>
        </select>
      </div>
    </div>


    <div class="my-4">

      <table class="table table-bordered  bg-white">

        <caption style="display:none;">{{ $t('userOrders.title') }}</caption>

        <thead>
        <tr>
          <th>{{ $t('order.id') }}</th>
          <th>{{ $t('order.type') }}</th>
          <th>{{ $t('order.role') }}</th>
          <th>{{ $t('order.user') }}</th>
          <th>{{ $t('order.from') }}</th>
          <th>{{ $t('order.to') }}</th>
          <th>{{ $t('order.price') }}</th>
          <th>{{ $t('order.status') }}</th>
        </tr>
        </thead>

        <tbody>
        <tr>
          <td colspan="5" v-if="items.length<1">
            {{ $t('userOrders.notFound') }}
          </td>
        </tr>
        <tr v-for="i in items" :key="i.id">
          <td>{{ i.firstOrder.orderNo }}</td>
          <td>{{ i.firstOrder.orderType }}</td>
          <td>{{ i.firstOrder.idUser === this.user.id ? $t('userType.user') : $t('userType.expert') }}</td>
          <td>
            <router-link :to="`/user/${i.firstOrder.expert.id}`" v-if="i.firstOrder.idUser === this.user.id">
              {{ i.firstOrder.expert.name }} {{ i.firstOrder.expert.surname }}
            </router-link>
            <span v-else>
                            {{ i.firstOrder.user.name }} {{ i.firstOrder.user.surname }}
                        </span>
          </td>
          <td>{{ i.firstOrder.jobFromKey.name }}</td>
          <td>{{ i.firstOrder.jobToKey.name }}</td>
          <td>{{ i.firstOrder.totalAmount }} {{ $t('general.currencyShortName') }}</td>
          <td>
            {{ $t(`orderStatus.${i.firstOrder.orderStatus}`) }}
             <i @mouseenter="showPopover($event,i.firstOrder.orderStatus)" @mouseout="hidePopover" class="fa fa-info-circle" :title="$t(`orderStatus.${i.firstOrder.orderStatus}`)"></i>
          </td>
          <td>
            <a v-if="i.firstOrder.orderStatus === 'FINISHED'" href="#" @click.prevent="exportPdf(i.firstOrder.id)">
              <i class="fa fa-file-text" aria-hidden="true"></i>
            </a>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <nav aria-label="Navigation">
      <ul class="pagination pagination-sm">
        <li class="page-item">
          <a class="page-link" @click="currentPage--" v-if="currentPage>1">&lt;</a>
        </li>
        <li class="page-item active"><a class="page-link" href="#" v-if="currentPage>1 || items.length===perPage">{{ currentPage }}</a></li>
        <li class="page-item">
          <a class="page-link" @click="currentPage++" v-if="items.length===perPage">&gt;</a>
        </li>
      </ul>
    </nav>

  </div>


</template>
<script>
import {mapState} from "vuex";
import {getOrderInvoice, getOrderList} from "@/repositories/GeneralDataRepository";
import {mediaBase} from "@/repositories/Repository";
import customAlert from "@/repositories/Utils";
import { Popover } from "bootstrap";

export default {
  data() {
    return {
      searchKeyword: '',
      items: [],
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 25, 50, 100],
      loading: [],

      bsPopover: null
    }
  },
  computed: {
    ...mapState({
      token: state => state.auth.token,
      user: state => state.auth.user,
    }),
    mediaBase: mediaBase
  },
  watch: {
    perPage() {
      this.currentPage = 1;
      this.getList();
    },
    currentPage() {
      this.getList();
    }
  },
  methods: {
    getList() {
      getOrderList(this.token, this.currentPage, this.perPage).then(data => {
        this.items = data;
      })
    },
    exportPdf(id) {
      getOrderInvoice(this.token, id).then(url => {
        if (url === null) {
          customAlert(this.$t('general.error'), this.$t('userOrders.invoiceCanNotBeGenerated'),'danger');
        } else {
          window.open(`${mediaBase}/${url}`);
        }
      })
    },
    showPopover(event, status) {

      let content = this.$t(`orderStatusDescription.${status}`);

      this.bsPopover = new Popover(event.target, {placement: 'top',content:content});
      this.bsPopover.show()
    },
    hidePopover() {
      this.bsPopover.hide()
    }
  },
  mounted() {
    this.getList();

  }

}
</script>
<style>
div.review canvas {
  position: relative !important;
}

div.review input {
  z-index: 1;
  display: inline-block;
}
</style>
