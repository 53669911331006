<template>
  <div class="top-navigation">
    <div class="container">
      <div class="row">

        <div class="col-4">
          <router-link to="/" class="logo">
            <img src="../../assets/logo.png" alt="logo">
          </router-link>
        </div>

        <div class="col-8 d-flex align-items-center">

          <div class="row w-100" v-if="user===null">

            <div
                class="col-12 col-xl-6 col-lg-8 d-flex align-items-center justify-content-end justify-content-lg-center">


              <ul class="nav">
                <li class="nav-item dropdown">
                  <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button"
                     aria-expanded="false">
                    <img :src="`${mediaBase}/lang/${$i18n.locale}.png`" :alt="$i18n.locale">
                  </a>
                  <ul class="dropdown-menu" style="z-index: 1030">
                    <li v-for="l in $i18n.availableLocales" :key="`locale-${l}`" @click.prevent="setLanguage(l)">
                      <a class="dropdown-item" href="#">
                        <img :src="`${mediaBase}/lang/${l}.png`" :alt="l" style="height: 15px;">
                        {{ languages[l] }}
                      </a>
                    </li>
                  </ul>
                </li>
                <li class="nav-item ">
                  <router-link class="nav-link" aria-current="page" to="/search">
                    <img src="../../assets/icon/search.png" alt="search">
                    <span class="d-none d-md-inline-block">{{ $t("header.search") }}</span>
                  </router-link>
                </li>
                <li class="nav-item divider  d-none d-md-inline-block"></li>

                <li class="nav-item">
                  <router-link to="/login" class="nav-link violet fw-bold">
                    <img src="../../assets/icon/user.png" alt="search">
                    <span class="d-none d-md-inline-block">{{ $t("header.login") }}</span>
                  </router-link>
                </li>
              </ul>

            </div>

            <div class="col-12 col-xl-6 col-lg-4 d-none d-lg-flex align-items-center justify-content-end">

              <div class="registration-box d-none d-md-flex">

                <div class="pre-text">
                  <router-link to="/register">
                    {{ $t("header.registration") }}
                  </router-link>
                </div>
                <div class="choices">
                  <router-link to="/register?type=USER" class="choice">
                    {{$t('userType.user')}}
                  </router-link>
                  <router-link to="/register?type=EXPERT" class="choice">
                    {{$t('userType.expert')}}
                  </router-link>
                </div>

              </div>


            </div>

          </div>

          <div class="row w-100" v-else>

            <div class="col-7 d-flex align-items-center">


              <ul class="nav">
                <li class="nav-item dropdown">
                  <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button"
                     aria-expanded="false">
                    <img :src="`${mediaBase}/lang/${$i18n.locale}.png`" :alt="$i18n.locale">
                  </a>
                  <ul class="dropdown-menu"  style="z-index: 1030">
                    <li v-for="l in $i18n.availableLocales" :key="`locale-${l}`" @click.prevent="setLanguage(l)">
                      <a class="dropdown-item" href="#">
                        <img :src="`${mediaBase}/lang/${l}.png`" :alt="l" style="height: 15px;">
                        {{ languages[l] }}
                      </a>
                    </li>
                  </ul>
                </li>
                <li class="nav-item d-none d-md-inline-block">
                  <router-link class="nav-link" aria-current="page" to="/search">
                    <img src="../../assets/icon/search.png" alt="search">
                    <span>{{ $t("header.search") }}</span>
                  </router-link>
                </li>

              </ul>

            </div>

            <div class="col-5 d-flex align-items-center justify-content-end">


              <div class="d-flex align-items-center">


                <router-link :to="{name: 'ChatPage', params:{id: 'list'}}"
                             class="fw-bold text-dark" style="text-decoration: none; white-space: nowrap">
                  <span class="d-none d-md-inline-block me-1">{{ $t("header.messaging") }}</span>
                  <span class="badge bg-danger" v-if="unreadMessageCount>0">{{unreadMessageCount}}</span>
                  <i class="fa fa-bell-o" aria-hidden="true"></i>
                </router-link>


                <div class=" ms-3" style="cursor:pointer;">
                  <div class="avatar" data-bs-toggle="dropdown" aria-expanded="false" :style="{
                                'background-image': `url('${user.profilePhoto===null || user.profilePhoto === ''?'/img/avatar_placeholder.png': `${squareImageBase}/${user.profilePhoto}` }')`
                            }">

                  </div>

                  <ul class="dropdown-menu  dropdown-menu-lg-end" style="max-width: 200px">
                    <li>
                      <h6 class="dropdown-header text-truncate">
                        {{ user.name }} {{ user.surname }}{{ user.name }} {{ user.surname }}{{ user.name }} {{ user.surname }}{{ user.name }} {{ user.surname }}{{ user.name }} {{ user.surname }}
                      </h6>
                    </li>
                    <li><a class="dropdown-item" href="/profile">{{ $t("header.profile") }}</a></li>
                    <li v-if="user.status==='OFFLINE'">
                      <a class="dropdown-item text-warning" href="#"
                         @click.prevent="setStatus('online')">
                        <span class="status-dot bg-warning"></span>
                        {{ $t("header.userStatus.away") }}
                      </a>
                    </li>
                    <li v-else>
                      <a class="dropdown-item text-success" href="#"
                         @click.prevent="setStatus('offline')">
                        <span class="status-dot bg-success"></span>
                        {{ $t("header.userStatus.online") }}
                      </a>
                    </li>
                    <li><a class="dropdown-item text-danger" href="#" @click.prevent="logOut()">
                      {{ $t("header.logout") }}
                    </a>
                    </li>
                  </ul>

                </div>

              </div>

            </div>

          </div>


        </div>


      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {mediaBase, squareImageBase} from "@/repositories/Repository";
import {setLanguage, setStatus} from "@/repositories/GeneralDataRepository";
import customAlert from "../../repositories/Utils";

export default {
  name: "TopNavigation",
  data() {
    return {
      mediaBase: mediaBase,
      squareImageBase: squareImageBase,
      languages:{
        en: "English",
        cz: 'Česky'
      }
    }
  },
  computed: {
    ...mapState(
        {
          token: state => state.auth.token,
          user: state => state.auth.user,
          unreadMessageCount: state => state.auth.unreadMessageCount,
        }
    ),
  },
  methods: {
    logOut() {
      if (confirm(this.$t('header.logoutQuestion'))) {
        localStorage.removeItem('token');
        this.$store.dispatch('auth/setToken', null);
        location.href = '/';
      }
    },
    setStatus(status) {
      setStatus(this.token, status).then(() => {
        this.$store.dispatch('auth/loadUser', this.token);
        customAlert(
            this.$t('header.yourStatusUpdated'),
            status === 'online' ? this.$t('header.youAreOnline') : this.$t('header.youAreOffline'),
            status === 'online' ? 'success' : 'warning'
        );
      });
    },
    setLanguage(language) {
      this.$i18n.locale = language;
      localStorage.setItem('lang', language);
      if(this.user) {
        setLanguage(this.token, language.toUpperCase()).then(() => {
          this.$store.dispatch('auth/loadUser', this.token);
        });
      }
    }
  }
}
</script>

<style lang="scss" scoped>

@import "../../assets/colors";

.top-navigation {
  padding-bottom: 10px;

  .logo {
    display: inline-block;
    padding: 10px;
    border-radius: 0 0 10px 10px;
    background: white;

    img {
      height: 57px;

      @media screen and (max-width: 640px) {
        height: 30px;
      }
    }

  }

  .nav-item {
    &.divider {
      width: 10px;

      &:after {
        content: '';
        display: inline-block;
        height: 12px;
        overflow: hidden;
        border-left: 1px solid #000000;
        opacity: 0.2;
        position: relative;
        top: 10px;
      }
    }

    .nav-link {
      text-decoration: none;
      color: black;
      font-size: 16px;
      line-height: 20px;

      &.violet {
        color: $color-violet;
      }

      img {
        height: 20px;
      }
    }
  }

  .registration-box {
    border: 1px solid $color-violet;
    border-radius: 20px;
    display: flex;
    overflow: hidden;

    .pre-text {
      font-size: 16px;
      line-height: 20px;
      padding: 10px 20px;
      color: $color-violet;

      a {
        text-decoration: none;
      }
    }

    .choices {
      display: block;
      background: $color-violet;
      border-radius: 20px 0 0 20px;
      @media screen and (max-width: 1200px) {
        display: none;
      }

      .choice {
        display: inline-block;
        font-size: 16px;
        line-height: 20px;
        font-weight: bold;
        color: white;
        text-decoration: none;
        padding: 10px 20px;

        &:not(:first-child) {
          border-left: 1px solid #FFFFFF;
        }

      }
    }

  }

  .avatar {
    display: inline-block;
    width: 50px;
    height: 50px;
    background-size: cover;
    border-radius: 100%;
    border: 1px solid #9EA2B2;
  }

}
</style>