<template>

  <div class="bg-white">

    <top-navigation/>

  </div>

  <div class="container-fluid">

    <div class="row">

      <div class="col-lg-6 d-none d-lg-block">

        <GMapMap
            api-key="AIzaSyDXi-Dc5YhnAe-netj3JT0X6UAnDzqbtJg"
            style="width: 100%; height: 90vh"
            :center="mapCenter"
            :zoom="8"
            class="sticky-top"
            ref="searchMap"
        >
          <GMapMarker v-for="(r,i) in searchResult" :key="r.userView.id"
                      :icon="{url: `${squareImageBase}/${r.userView.profilePhoto}`, scaledSize: {width: 40, height: 40},}"
                      :position="{lat: r.userView.latitude, lng: r.userView.longitude}"
                      @click="openMarker(i)">
            <GMapInfoWindow
                :closeclick="true"
                @closeclick="openMarker(null)"
                :opened="openedMarkerID === i">
              <expert-item :border-less="true" :expert="r"/>
            </GMapInfoWindow>
          </GMapMarker>
        </GMapMap>


      </div>
      <div class="col-lg-6 py-5 ">


        <div class="tabs-view search-box">
          <div class="tabs-header">
            <a class="tab-pill" :class="{active: searchBoxActivePill==='TRANSLATOR'}"
               @click="searchBoxActivePill = 'TRANSLATOR'">
              {{ $t('expertType.translator') }}
            </a>
            <a class="tab-pill" :class="{active: searchBoxActivePill==='INTERPRETER'}"
               @click="searchBoxActivePill = 'INTERPRETER'">
              {{ $t('expertType.interpreter') }}
            </a>
            <a class="tab-pill" :class="{active: searchBoxActivePill==='TEACHER'}"
               @click="searchBoxActivePill = 'TEACHER'">
              {{ $t('expertType.teacher') }}
            </a>
          </div>

          <div class="tab-body" :class="{firstIsActive: searchBoxActivePill==='TRANSLATOR'}">

            <div v-if="searchBoxActivePill==='TRANSLATOR'">
              <translator-search-form :categories="categories" :languages="languages"
                                      :locations="locations" :searchData="searchQuery['TRANSLATOR']"
                                      @searchSubmitted="startSearching" :key="tabKeys['TRANSLATOR']"/>
            </div>

            <div v-if="searchBoxActivePill==='INTERPRETER'">
              <interpreter-search-form :categories="categories" :languages="languages"
                                       :locations="locations" :searchData="searchQuery['INTERPRETER']"
                                       @searchSubmitted="startSearching" :key="tabKeys['INTERPRETER']"/>
            </div>

            <div v-if="searchBoxActivePill==='TEACHER'">
              <lector-search-form :categories="categories" :languages="languages"
                                  :locations="locations" :searchData="searchQuery['TEACHER']"
                                  @searchSubmitted="startSearching" :key="tabKeys['TEACHER']"/>
            </div>


          </div>


        </div>

        <div class="search-result mt-5">

          <div class="line-header">
            <h3 class=" text-secondary fw-bold">{{ $t('search.searchResults') }}</h3>
          </div>


          <div v-if="searchInProgress">
            <div class="alert alert-info my-5 text-center">{{ $t('search.searching') }}</div>
          </div>
          <div v-else>
            <div v-if="searchResult === null">
              <div class="alert alert-info my-5">{{ $t('search.plsSelectParameters') }}</div>
            </div>
            <div v-else-if="searchResult.length<1">
              <div class="alert alert-primary my-5">{{ $t('search.noResultFound') }}</div>
            </div>
            <div v-else class="row mt-5">
              <div class="col-md-6  mb-4" v-for="e in searchResult" :key="e.userView.id">
                <expert-item :border-less="false" :expert="e" :search-type="searchBoxActivePill"/>
              </div>
            </div>

          </div>


        </div>


      </div>

    </div>

  </div>

  <find-clients-online/>

  <the-footer/>

</template>

<script>
// @ is an alias to /src

import TopNavigation from "../components/layout/TopNavigation";
import TheFooter from "../components/layout/TheFooter";
import FindClientsOnline from "../components/info/FindClientsOnline";
import {
  getExpertList, getLocationList,
  getPropertyList
} from "@/repositories/GeneralDataRepository";
import TranslatorSearchForm from "../components/search/TranslatorSearchForm";
import InterpreterSearchForm from "../components/search/InterpreterSearchForm";
import LectorSearchForm from "../components/search/LectorSearchForm";
import ExpertItem from "../components/ExpertItem";
import {ref} from "vue";
import {mapState} from "vuex";
import {squareImageBase} from "@/repositories/Repository";


export default {
  name: 'Search',
  components: {
    ExpertItem,
    LectorSearchForm,
    InterpreterSearchForm, TranslatorSearchForm, FindClientsOnline, TheFooter, TopNavigation
  },
  setup() {
    const searchMap = ref(null)

    function centerMap(minLat, minLng, maxLat, maxLng) {

      if (searchMap.value) {
        if (minLat === maxLat) {
          minLat -= 0.002;
          maxLat += 0.002;
        }
        if (minLng === minLng) {
          minLng -= 0.002;
          minLng += 0.002;
        }
        try {
          let bounds = new window.google.maps.LatLngBounds();
          bounds.extend(new window.google.maps.LatLng({lat: minLat, lng: minLng}));
          bounds.extend(new window.google.maps.LatLng({lat: maxLat, lng: maxLng}));
          this.$refs.searchMap.$mapObject.fitBounds(bounds);
        } catch (e) {
          console.log(e);
        }
      } else {
        console.log(searchMap);
      }
    }

    return {
      searchMap, centerMap
    }
  },
  data() {
    return {
      squareImageBase: squareImageBase,
      searchInProgress: false,
      searchBoxActivePill: 'TRANSLATOR',
      languages: [],
      categories: [],
      locations: [],
      selectedJobKnowledge: [],
      searchResult: null,
      openedMarkerID: null,
      mapCenter: {lat: 50.05671, lng: 14.42596},
      searchQuery:{
      },
      tabKeys: {
        TRANSLATOR: 100,
        INTERPRETER: 200,
        TEACHER: 300
      }
    };
  },
  computed: {
    ...mapState({
      token: state => state.auth.token
    })
  },
  methods: {
    startSearching(searchData) {

      console.log(searchData);

      this.searchInProgress = true;

      let {
        fromLang,
        toLang,
        fromDate,
        toDate,
        fromCost,
        toCost,
        locationId,
        jobType,
        selectedCategories,
        isSworn
      } = searchData;

      this.$router.push(`/search?fromLang=${fromLang}&toLang=${toLang}&fromDate=${fromDate}&toDate=${toDate}&fromCost=${fromCost}&toCost=${toCost}&locationId=${locationId}&jobType=${jobType}&sworn=${isSworn ? 1 : 0}&jobKnowledge=${selectedCategories.join(',')}`);

      this.searchQuery[jobType] =  searchData;
      //this.tabKeys[jobType] += 1;

      getExpertList(this.token, fromLang, toLang, fromDate, toDate, fromCost, toCost, locationId, jobType, isSworn, selectedCategories).then((data) => {
        this.searchInProgress = false;

        if (data === null) {
          this.searchResult = [];
        } else {
          this.searchResult = data;
        }
      });

    },
    setPlace(data) {
      console.log(data);
    },
    openMarker(id) {
      this.openedMarkerID = id
    },
    getProperties(){
      getLocationList().then((data) => {
        this.locations = data;
      })
      getPropertyList('languages', this.$i18n.locale).then((data) => {
        this.languages = data;
      })
      getPropertyList('job_knowledge', this.$i18n.locale).then((data) => {
        this.categories = data;
      })
    }
  },
  created() {
   this.getProperties();
  },
  mounted() {
    console.log(this.$route.query);

    let selectedCategory = this.$route.query.jobKnowledge;

    if (!selectedCategory || selectedCategory === '') {
      selectedCategory = [];
    } else {
      selectedCategory = selectedCategory.split(',');
    }



    this.searchBoxActivePill = this.$route.query.jobType || 'TRANSLATOR';

    this.searchQuery[this.searchBoxActivePill] = {
      fromLang: this.$route.query.fromLang || '',
      toLang: this.$route.query.toLang || '',
      fromDate: this.$route.query.fromDate || '',
      toDate: this.$route.query.toDate || '',
      fromCost: this.$route.query.fromCost || '',
      toCost: this.$route.query.toCost || null,
      locationId: this.$route.query.locationId || '',
      sworn: this.$route.query.sworn || '0',
      selectedCategories: selectedCategory
    };
    this.tabKeys[this.searchBoxActivePill] += 1;
  },
  watch: {

    searchBoxActivePill(){
      this.searchResult = null;
    },
    searchResult(newData) {

      if (newData != null) {
        let lats = [];
        let lngs = [];


        this.searchResult.forEach(r => {
          if (r.userView.latitude && r.userView.longitude) {
            lats.push(r.userView.latitude);
            lngs.push(r.userView.longitude);
          }
        })

        console.log(lats);

        if (lats.length > 0 && lngs.length > 0) {
          this.centerMap(Math.min(...lats), Math.min(...lngs), Math.max(...lats), Math.max(...lngs));
        }
      }
    },
    "$i18n.locale": function () {
      this.getProperties();
    }
  }
}
</script>

<style lang="scss" scoped>

@import "../assets/colors.scss";


.form-card {
  border-radius: 20px;
  box-shadow: 0 6px 52px -20px rgba(0, 0, 0, 0.2);

  .card-body {
    padding: 40px;
  }
}

.tab-pill{
  cursor: pointer;
}

.info-card {
  border-radius: 6px;

  .card-body {
    padding: 30px;
    color: $color-violet;

    a {
      text-decoration: none;
      color: $color-violet;
    }
  }
}


</style>