<template>

  <div class="bg-white">

    <top-navigation/>

  </div>

  <div class="container py-5">

    <div class="row">

      <div class="col-lg-6">

        <h1 class="display-2 text-primary fw-bold">
          {{ $t('contacts.title') }}
        </h1>

        <p class="lead">
          {{ $t('contacts.subtitle') }}
        </p>

        <div class="row mt-4" v-if="contactInfo">

          <div class="col-md-6 mb-3">
            <div class="card info-card">
              <div class="card-body">
                <span class="text-primary">
                  {{ $t('contacts.phone') }}
                </span> <br>
                <span class="text-secondary">
                  {{ contactInfo['contactinfo.phone']}}
                </span>
              </div>
            </div>
          </div>
          <div class="col-md-6 mb-3">
            <div class="card  info-card">
              <div class="card-body">
                <span class="text-primary">
                  {{ $t('contacts.email') }}
                </span><br>
                <span class="text-secondary">
                  {{ contactInfo['contactinfo.email']}}
                </span>
              </div>
            </div>
          </div>
          <div class="col-md-6 mb-3">
            <div class="card  info-card">
              <div class="card-body">
                <span class="text-primary">
                  {{ $t('contacts.address') }}
                </span><br>
                <span class="text-secondary">
                  {{ contactInfo['contactinfo.address']}}
                </span>
              </div>
            </div>
          </div>

          <div class="col-md-6 mb-3">
            <div class="card  info-card">
              <div class="card-body">
                <div class="text-primary mb-2">
                 Social networks
                </div>
                <a class="text-primary me-3" :href="contactInfo['contactinfo.facebook']" v-if="contactInfo['contactinfo.facebook']">
                  <i class="fa fa-facebook-square" aria-hidden="true" style="font-size: 20px;"></i>
                </a>
                <a class="text-primary me-3" :href="contactInfo['contactinfo.linkedin']" v-if="contactInfo['contactinfo.linkedin']">
                  <i class="fa fa-linkedin-square" aria-hidden="true" style="font-size: 20px;"></i>
                </a>
                <a class="text-primary me-3" :href="contactInfo['contactinfo.twitter']" v-if="contactInfo['contactinfo.twitter']">
                  <i class="fa fa-twitter-square" aria-hidden="true" style="font-size: 20px;"></i>
                </a>
                <a class="text-primary me-3" :href="contactInfo['contactinfo.instagram']" v-if="contactInfo['contactinfo.instagram']">
                  <i class="fa fa-instagram" aria-hidden="true" style="font-size: 20px;"></i>
                </a>
              </div>
            </div>
          </div>

        </div>

        <div class="mt-5">
          <img src="../assets/theme/photo-6.png" class="img-fluid" alt="contact us">
        </div>


      </div>
      <div class="col-lg-5 offset-1">

        <div class="card form-card">
          <div class="card-body">

            <h3 class="fw-bold mb-4">{{ $t('contacts.contactUs') }}</h3>

            <form @submit.prevent="submitContactForm">

              <div class="row">
                <div class="col-md-6">
                  <div class="mb-3">
                    <label for="name">{{ $t('contacts.name') }}</label>
                    <input class="form-control form-control-pill" id="name" v-model.trim="contactForm.name" required>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="mb-3">
                    <label for="surname">Surname</label>
                    <input class="form-control form-control-pill" id="surname" v-model.trim="contactForm.surname" required>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <div class="mb-3">
                    <label for="email">{{ $t('contacts.email') }}</label>
                    <input type="email" class="form-control form-control-pill" id="email" v-model.trim="contactForm.email" required>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="mb-3">
                    <label for="phone">Phone</label>
                    <input type="tel" class="form-control form-control-pill" id="phone" v-model.trim="contactForm.phone" required>
                  </div>
                </div>
              </div>


              <div class="mb-3">
                <label for="subject">Subject</label>
                <select class="form-control form-control-pill" id="subject" v-model="contactForm.subject.keyword" required>
                  <option :value="k" v-for="(s,k) in subjectList" :key="k">{{s}}</option>
                </select>
              </div>

              <div class="mb-3">
                <label for="message">{{ $t('contacts.message') }}</label>
                <textarea class="form-control form-control-pill" id="message" rows="5" required
                          v-model.trim="contactForm.message"></textarea>
              </div>

              <div class="mb-3">
                                <span class="text-secondary">
                                    {{ $t('contacts.acceptingPersonalDataProtectionRules') }}
                                </span>
              </div>

              <div class="alert alert-info mb-3" v-if="formSubmitError">
                {{formSubmitError}}
              </div>

              <div class="alert alert-info" v-if="isFormSubmitting">
                Submitting form...
              </div>
              <div class="mb-3 text-end" v-else>
                <button type="submit" class="btn btn-lg btn-primary btn-pill">
                  {{ $t('general.send') }}
                </button>
              </div>



            </form>

          </div>
        </div>

      </div>

    </div>

  </div>


  <section>

    <GoogleMap
        api-key="AIzaSyD7-un0s7EHnFMh-yNDKC5xMi6pZE2cYEw"
        style="width: 100%; height: 600px"
        :center=" { lat: 50.070810, lng: 14.441479 }"
        :zoom="17"
    >
      <Marker :options="{ position:  { lat: 50.070810, lng: 14.441479} }"/>
    </GoogleMap>

  </section>

  <the-footer/>

</template>

<script>
// @ is an alias to /src

import TopNavigation from "../components/layout/TopNavigation";
import TheFooter from "../components/layout/TheFooter";
import {GoogleMap, Marker} from "vue3-google-map";
import {contactForm, getPropertyList} from "@/repositories/GeneralDataRepository";
import customAlert from "@/repositories/Utils";


export default {
  name: 'Home',
  components: {TheFooter, TopNavigation, GoogleMap, Marker},
  data() {
    return {
      isFormSubmitting: false,
      formSubmitError: null,
      contactInfo: null,
      subjectList:{},
      contactForm: {
        name: '',
        surname: '',
        email: '',
        phone: '',
        subject: {
          keyword: '',
        },
        message: '',
      }
    };
  },
  watch: {
    '$i18n.locale'(){
      this.getProperties();
    }
  },
methods: {

  submitContactForm()
  {
    if(this.isFormSubmitting) return;
    this.isFormSubmitting = true;
    this.formSubmitError = null;
    contactForm(this.contactForm).then(res => {
      this.isFormSubmitting = false;
      if (res === null) {
        this.formSubmitError = this.$t('general.somethingWentWrong');
      } else {
        let {code, message} = res;

        if (code === 200) {

          customAlert("Contact request successfully submitted", '', 'success');

          this.contactForm = {
            name: '',
            surname: '',
            email: '',
            phone: '',
            subject: {
              keyword: '',
            },
            message: '',
          };

        } else {
          this.formSubmitError = `${code}: ${message}`;
        }
      }

    });
  },

  getProperties(){
    getPropertyList('contactInfo', this.$i18n.locale).then((response) => {
      this.contactInfo = {};

      response.forEach((item) => {
        return this.contactInfo[item.keyword] = item.name;
      })
    });
    getPropertyList('contact', this.$i18n.locale).then((response) => {
      this.subjectList = {};
      response.forEach((item) => {
        return this.subjectList[item.keyword] = item.name;
      })
    });
  }
},
  mounted() {
    this.getProperties();
  }
}
</script>

<style lang="scss" scoped>

@import "../assets/colors.scss";


.form-card {
  border-radius: 20px;
  box-shadow: 0 6px 52px -20px rgba(0, 0, 0, 0.2);

  .card-body {
    padding: 40px;
  }
}

.info-card {
  border-radius: 6px;

  .card-body {
    padding: 30px;
    color: $color-violet;

    a {
      text-decoration: none;
      color: $color-violet;
    }
  }
}


</style>