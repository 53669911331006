import axios from 'axios';

const baseDomain = 'https://api.linguamat.com';
// const baseDomain = 'http://lingua.even-team.de';


export const customHeaders = {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
};

export const baseUrl = `${baseDomain}`;
export const mediaBase = `${baseDomain}/images`;
export const squareImageBase = `${baseDomain}/photo-ms/image/height/200/width/200?path=`;
export const socketBase = `${baseDomain}/messaging-ms`;
// export const socketBase = `${baseDomain}/messaging-ms`;

export default axios.create({
    baseUrl,
    headers: customHeaders
});

export const serializeQuery = query => {
    return Object.keys(query)
        .map(
            key =>
                `${encodeURIComponent(key)}=${encodeURIComponent(query[key])}`
        )
        .join('&');
};
