import {createApp} from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import * as bootstrap from 'bootstrap';

import VueGoogleMaps from '@fawmi/vue-google-maps';
import VueNextSelect from 'vue-next-select';
import { setupCalendar, Calendar, DatePicker } from 'v-calendar';
import 'v-calendar/style.css';

import gAuth from 'vue3-google-auth';
import {initFacebookSdk} from "./repositories/FacebookSDK";

import { createI18n } from 'vue-i18n';

const $gAuth = gAuth.createGAuth({
    clientId: "725938650307-7jevs01ad2a9mf3cds9tnv8nul0jgpge.apps.googleusercontent.com",
});


let messagesEn = require("@/locale/en.json");
let messagesCz = require("@/locale/cz.json");

let messages = {
    en: messagesEn,
    cz: messagesCz
};

const i18n = createI18n({
    locale: 'en',
    messages
});


initFacebookSdk().then(()=>{

    createApp(App)
        .use(store)
        .use(router)
        .use(bootstrap)
        .use($gAuth)
        .use(i18n)
        .use(VueGoogleMaps, {
            load: {
                key: 'AIzaSyDXi-Dc5YhnAe-netj3JT0X6UAnDzqbtJg',
                libraries: "places"
            },
        })
        .use(setupCalendar, {})
        .component('Calendar', Calendar)
        .component('DatePicker', DatePicker)
        .component('vue-select', VueNextSelect)
        .mount('#app')
});

