import {createRouter, createWebHistory} from 'vue-router'
import Home from '../views/Home.vue'
import AboutUs from "../views/AboutUs";
import ContactUs from "../views/ContactUs";
import Search from "../views/Search";
import UserPage from "../views/UserPage";
import Registration from "../views/Registration";
import LoginPage from "../views/LoginPage";
import ExpertRegistration from "../views/ExpertRegistration";
import ForgetPage from "../views/ForgetPage";
import NotFoundPage from "../views/NotFoundPage";
import store from "../store";
import ProfileEdit from "../views/ProfileEdit";
import ProfilePassword from "../views/ProfilePassword";
import ProfilePage from "../views/ProfilePage";
import ChatPage from "../views/ChatPage";
import PaymentResultPage from "../views/PaymentResultPage";
import InfoPage from "../views/InfoPage";

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/about',
        name: 'AboutUs',
        component: AboutUs
    },
    {
        path: '/contact',
        name: 'ContactUs',
        component: ContactUs
    },
    {
        path: '/search',
        name: 'Search',
        component: Search
    },

    {
        'path': '/page/:id',
        name: "page",
        component: InfoPage,
    },
    {
        'path': '/user/:id',
        name: "UserPage",
        component: UserPage,
        beforeEnter: (to, from, next) => {
            if (store.state.auth.token === null) {
                next('/login?back='+to.path);
            } else {
                next();
            }
        }
    },
    {
        'path': '/chat/:id',
        name: "chat",
        component: ChatPage,
        beforeEnter: (to, from, next) => {
            if (store.state.auth.token === null) {
                next('/login?back='+to.path);
            } else {
                next();
            }
        }
    },
    {
        'path': '/register',
        name: "Registration",
        component: Registration,
        beforeEnter: (to, from, next) => {
            if (store.state.auth.token) {
                next('/profile');
            } else {
                next();
            }
        }
    },
    {
        'path': '/forget',
        name: "Forget",
        component: ForgetPage,
        beforeEnter: (to, from, next) => {
            if (store.state.auth.token) {
                next('/profile');
            } else {
                next();
            }
        }
    },
    {
        'path': '/login',
        name: "LoginPage",
        component: LoginPage,
        beforeEnter: (to, from, next) => {
            if (store.state.auth.token) {
                next('/profile');
            } else {
                next();
            }
        }
    },
    {
        'path': '/registration/expert',
        name: "ExpertRegistration",
        component: ExpertRegistration,
        beforeEnter: (to, from, next) => {
            if (store.state.auth.token === null) {
                next('/login');
            } else {
                next();
            }
        }
    },
    {
        'path': '/profile',
        name: "Profile",
        component: ProfilePage,
        beforeEnter: (to, from, next) => {
            if (store.state.auth.token === null) {
                next('/login');
            } else {
                next();
            }
        }
    },
    {
        'path': '/profile/edit',
        name: "ProfileEdit",
        component: ProfileEdit,
        beforeEnter: (to, from, next) => {
            if (store.state.auth.token === null) {
                next('/login');
            } else {
                next();
            }
        }
    },
    {
        'path': '/profile/password',
        name: "ProfilePassword",
        component: ProfilePassword,
        beforeEnter: (to, from, next) => {
            if (store.state.auth.token === null) {
                next('/login');
            } else {
                next();
            }
        }
    },
    {
        'path': '/chat',
        name: "ChatPage",
        component: ChatPage,
        beforeEnter: (to, from, next) => {
            if (store.state.auth.token === null) {
                next('/login?back='+to.path);
            } else {
                next();
            }
        }
    },

    {
        'path': '/payment/:id',
        name: "paymentResult",
        component: PaymentResultPage,
        beforeEnter: (to, from, next) => {
            if (store.state.auth.token === null) {
                next('/login?back='+to.path);
            } else {
                next();
            }
        }
    },

    {
        path: "/:catchAll(.*)", component: NotFoundPage
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    scrollBehavior() {
        return {
            x: 0, y: 0,
            behavior: 'smooth'
        }
    },
    routes
})

router.beforeEach(() => {
    window.scrollTo(0, 0);
})

export default router
