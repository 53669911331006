<template>
  <div class="card expert-card" @click="goToExpertPage" :class="{'border-0': borderLess}">
    <div class="card-body">
            <span class="text-secondary fw-bold">
              <span v-if="searchType==='TRANSLATOR'">{{ $t('expertType.translator') }}</span>
              <span v-else-if="searchType==='INTERPRETER'">{{ $t('expertType.interpreter') }}</span>
              <span v-else-if="searchType==='TEACHER'">{{ $t('expertType.teacher') }}</span>
            </span>
      <div class="languages d-flex align-items-center mt-2">
        <img :src="getLanguageIcon(expert.jobFromKey.keyword)" alt="1" class="language-icon">
        <span class="language-name">{{ expert.jobFromKey.name }}</span>
        <img src="../assets/icon/arrow.png" alt="1" class="arrow">
        <img :src="getLanguageIcon(expert.jobToKey.keyword)" alt="1" class="language-icon">
        <span class="language-name">{{ expert.jobToKey.name }}</span>
      </div>
    </div>
    <div class="card-body profile">
      <div class="d-flex justify-content-between align-items-center">
        <div class="d-flex align-items-center">
          <div class="avatar" :style="{
                                'background-image': `url('${
                                    expert.userView.profilePhoto===null || expert.userView.profilePhoto === ''
                                    ?'/img/avatar_placeholder.png'
                                    : `${squareImageBase}/${expert.userView.profilePhoto}` }')`
                            }">

          </div>

          <span class="name">{{ expert.userView.name }} {{ expert.userView.surname }}
                    <span class="status-dot" :class="{
                                    'bg-success': expert.userView.status ==='ONLINE',
                                    'bg-secondary': expert.userView.status ==='OFFLINE',
                                     }"> </span>
                    </span>
        </div>
        <div class="d-flex align-items-center rating">
          <img src="../assets/icon/star.png" class="rating-star" alt="star">
          <span>{{ rating }}/5</span>
        </div>
      </div>
    </div>
    <div class="card-body">
      <a class="category" v-for="c in expert.jobKnowledge" :key="c.knowledgeKey">{{ c.name }}</a>
    </div>
    <div class="card-body bg-success text-white fw-bold py-1" v-if="expert.sworn">
      <i class="fa fa-check-circle text-white" aria-hidden="true"></i> {{ $t('expert.isSworn') }}
    </div>
    <hr class="my-0" v-else>
    <div class="card-body ">
      <span class="h4 fw-bold">{{ expert.cost }} {{ $t('general.currencyShortName') }}</span>
      <span class="h4">/</span>
      <span v-if="expert.jobType==='TRANSLATOR'">{{ $t('order.units.page') }}</span>
      <span v-if="expert.jobType==='INTERPRETER'">{{ $t('order.units.hour') }}</span>
      <span v-if="expert.jobType==='TEACHER'">{{ $t('order.units.lesson') }}</span>
    </div>
  </div>
</template>

<script>
import {mediaBase, squareImageBase} from "@/repositories/Repository";

export default {
  name: "ExpertItem",
  props: ['expert', 'borderLess'],
  data() {
    return {
      mediaBase: mediaBase,
      squareImageBase: squareImageBase
    }
  },
  computed: {
    rating() {
      if (this.expert.ratedUsers > 0) {
        return (this.expert.rating / this.expert.ratedUsers).toFixed(1);
      }
      return 0;
    }
  },
  methods: {
    getLanguageIcon(keyword) {
      let code = keyword.split('.')[1];
      return `${mediaBase}/lang/${code}.png`
    },
    goToExpertPage() {
      this.$router.push(`/user/${this.expert.userView.id}`);
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../assets/colors";

.expert-card {
  cursor: pointer;

  .languages {

    .language-icon {
      width: 18px;
      margin-right: 5px;
    }

    .language-name {
      color: black;
    }

    .arrow {
      margin: 0 10px;
    }

  }

  .profile {
    background: #E7E7E7;
    padding: 5px 15px;

    .avatar {
      display: inline-block;
      width: 50px;
      height: 50px;
      min-width: 50px;
      max-width: 50px;
      background-size: cover;
      border-radius: 100%;
      border: 1px solid #9EA2B2;
      margin-right: 10px;
    }

    .rating {
      color: $color-salmon;
      font-weight: bold;

      .rating-star {
        margin-right: 3px;
        height: 16px;
      }
    }
  }

  .category {
    display: inline-block;
    text-decoration: none;
    margin: 4px;
    padding: 5px 15px;
    color: $color-grey;
    background: rgba($color-grey, 0.2);
    border-radius: 15px;
    font-size: 12px;
    font-weight: bold;
  }


}


  .gm-style-iw-c {
    padding: 0;
  }

  .gm-style-iw-d {
    padding: 0;
  }
</style>