import { createStore } from 'vuex'
import authStorage from "./auth";
import appStorage from "./app";
import chatStorage from "./chat";

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth: authStorage,
    app: appStorage,
    chat: chatStorage
  }
})
