<template>

  <div class="bg-white">

    <top-navigation/>

  </div>


  <div class="container py-5" v-if="userProfile == null">
    <div class="alert alert-info">
      {{ $t('general.loading') }}
    </div>
  </div>

  <div class="container py-5" v-else-if="userProfile.userType === 'USER'">


    <div class="row">

      <div class="col-md-6 d-flex align-items-center">

        <div>
          <h1 class="fw-bold text-primary "> {{ $t('general.notFound.title') }}</h1>

          <p class="lead fw-normal">
            {{ $t('general.notFound.description') }}
          </p>


          <div class="my-4">
            <router-link to="/search" class="btn btn-dark btn-lg btn-pill px-4">
              {{ $t('general.notFound.searchButtonText') }}
            </router-link>
          </div>

        </div>


      </div>


      <div class="col-md-6">
        <img src="../assets/theme/photo-2.png" class="w-100 p-5" alt="not found">
      </div>

    </div>


  </div>
  <div class="container py-5" v-else>

    <div class="row">


      <div class="col-md-8">
        <div class="profile d-flex align-items-start  ">

          <div class="avatar" :style="{
                                'background-image': `url('${userProfile.profilePhoto===null || userProfile.profilePhoto === ''?'/img/avatar_placeholder.png': `${squareImageBase}/${userProfile.profilePhoto}` }')`
                            }">

          </div>

          <div class="profile-data ps-3">
                        <span class="name fw-bold lead me-3">
                            {{ userProfile.title }} {{ userProfile.name }} {{ userProfile.surname }}
                        </span>

            <div class="d-inline-block rating">
                            <span class="d-inline-block" v-for="n in 5" :key="n">
                                <i class="fa fa-star" aria-hidden="true" v-if="userRating >= n" ></i>
                                <i class="fa fa-star-o" aria-hidden="true" v-else></i>
                            </span>
              <span class="d-inline-block ms-2">{{ userRating }}</span>
            </div>
            <span class="text-secondary d-inline-block ms-2 fw-normal ">
                            ({{ userProfile.rate !== null ? userProfile.rate.ratedUsersCount : 0 }})
                        </span>
            <br>
            <span>{{ userProfile.country.name }}</span>
          </div>


        </div>
      </div>
      <div class="col-md-4 d-flex align-items-center justify-content-end">
        <div v-if="userProfile.id !== user.id">
          <button @click="startChat" class="btn btn-primary">
            <i class="fa fa-comment" aria-hidden="true"></i> {{ $t('conversation.startConversation') }}
          </button>
        </div>


      </div>
      <div class="col-12 d-flex justify-content-end py-2">

        <div class="profileMenu small">

          <a href="#profileGeneralDetails">
            <i class="fa fa-list" aria-hidden="true"></i> {{ $t('profile.generalDetails') }}
          </a>
          <span class="divider">|</span>
          <a href="#profileLanguage">
            <i class="fa fa-language" aria-hidden="true"></i> {{ $t('profile.languageKnowledge') }}
          </a>
          <span class="divider">|</span>
          <a href="#profileReviews">
            <i class="fa fa-comment-o" aria-hidden="true"></i> {{ $t('profile.reviews.title') }}
          </a>
        </div>


      </div>


    </div>

    <hr class="mb-4">

    <div class="row" id="profileGeneralDetails">
      <div class="col-md-6 mb-3">

        <h4 class="fw-bold mb-4">{{ $t('profile.generalDetails') }}</h4>

        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6 mb-2">{{ $t('profile.title') }}:</div>
              <div class="col-md-6 mb-2  fw-bold">{{ userProfile.title }}</div>
              <div class="col-md-6 mb-2">{{ $t('profile.invoiceAddress') }}:</div>
              <div class="col-md-6 mb-2  fw-bold">{{ userProfile.accountAddress }}</div>
            </div>
          </div>
        </div>

      </div>
      <div class="col-md-6">

        <h4 class="fw-bold mb-3">{{ $t('profile.aboutExpert') }}</h4>

        <div v-html="userProfile.description">

        </div>
      </div>


      <div class="col-md-12 mt-5" id="profileLanguage">

        <h4 class="fw-bold mb-5">{{ $t('profile.languageKnowledge') }}</h4>


        <div class="row mt-5">

          <div class="col-lg-4 col-md-6 mb-4" v-for="(expert,i) in knowledgeList" :key="i">
            <div class="card knowledge-card">
              <div class="card-body">
                <span class="text-secondary fw-bold">
                    <span v-if="expert.jobType==='TRANSLATOR'">
                      {{ $t('expertType.translator') }}
                    </span>
                    <span v-if="expert.jobType==='INTERPRETER'">
                      {{ $t('expertType.interpreter') }}
                    </span>
                    <span v-if="expert.jobType==='TEACHER'">
                      {{ $t('expertType.teacher') }}
                    </span>
                </span>
                <div class="languages d-flex align-items-center mt-2">
                  <img :src="getLanguageIcon(expert.languageFrom.keyword)" alt="1"
                       class="language-icon">
                  <span class="language-name">{{ expert.languageFrom.name }}</span>
                  <img src="../assets/icon/arrow.png" alt="1" class="arrow">
                  <img :src="getLanguageIcon(expert.languageTo.keyword)" alt="1" class="language-icon">
                  <span class="language-name">{{ expert.languageTo.name }}</span>
                </div>
              </div>
              <hr class="my-0 py-0">
              <div class="card-body">
                <a class="category" v-for="c in expert.jobKnowledges"
                   :key="c.knowledgeKey">{{ c.name }}</a>
              </div>
              <div class="card-body bg-success text-white fw-bold py-1" v-if="expert.sworn">
                <i class="fa fa-check-circle text-white" aria-hidden="true"></i> {{ $t('userJobs.isSworn') }}
              </div>
            </div>
          </div>

        </div>


      </div>


      <div class="col-md-12 mt-5" id="profileReviews">

        <h4 class="fw-bold mb-5">{{ $t('profile.reviews.title') }}</h4>


        <div v-if="reviews.length<1">
          <div class="alert alert-info">{{ $t('profile.reviews.noReviews') }}</div>
        </div>


        <div class="row mt-5">

          <div class="col-lg-6 mb-4" v-for="r in reviews" :key="r.rating.id">
            <div class="d-flex justify-content-start align-items-start">
              <div>
                <div class="avatar" :style="{
                                'background-image': `url('${r.userView.profilePhoto===null || r.userView.profilePhoto === ''?'/img/avatar_placeholder.png': `${squareImageBase}/${r.userView.profilePhoto}` }')`
                            }">

                </div>

              </div>
              <div class="ps-3 w-100">
                <p class="mb-1">
                  <strong>{{ r.userView.name }} {{ r.userView.surname }}</strong>
                  <span class="text-warning ms-3">
                                        <i class="fa fa-star" aria-hidden="true"></i> {{ r.rating.rate.toFixed(2) }}
                                    </span>

                </p>
                <p class="small">{{ r.rating.comment }}</p>
                <div v-if="r.rating.answer" class="small p-2 bg-secondary text-white rounded ">
                  <strong>{{ userProfile.title }} {{ userProfile.name }} {{ userProfile.surname }}</strong> <br>
                  {{ r.rating.answer }}
                </div>
              </div>
            </div>
          </div>

        </div>


      </div>
    </div>


  </div>


  <the-footer/>

</template>

<script>
// @ is an alias to /src

import TopNavigation from "../components/layout/TopNavigation";
import TheFooter from "../components/layout/TheFooter";
import {
  getExpertDetails,
  getExpertKnowledge,
  getExpertReviews,
  startChat
} from "@/repositories/GeneralDataRepository";
import {mapState} from "vuex";
import {mediaBase, squareImageBase} from "@/repositories/Repository";
import customAlert from "@/repositories/Utils";

export default {
  name: 'UserPage',
  components: {TheFooter, TopNavigation},
  data() {
    return {
      mediaBase: mediaBase,
      squareImageBase: squareImageBase,
      userProfile: null,
      knowledgeList: [],
      reviews: []

    };
  },
  computed: {
    ...mapState(
        {
          token: state => state.auth.token,
          user: state => state.auth.user
        }
    ),
    userRating() {

      if (this.userProfile === null) {
        return 0;
      }

      if (this.userProfile.rate === null) {
        return 0;
      }
      if (this.userProfile.rate.ratedUsersCount === 0) {
        return 0;
      }

      return (this.userProfile.rate.rateSum / this.userProfile.rate.ratedUsersCount).toFixed(1);

    }
  }, methods: {
    getLanguageIcon(keyword) {
      let code = keyword.split('.')[1];
      return `${mediaBase}/lang/${code}.png`
    },
    startChat() {

      startChat(this.token, this.userProfile.id).then(data => {

        if (data.code === 200 && data.body !== null) {

          this.$router.push({name: 'chat', params: {id: data.body.current.id}})

        } else {
          customAlert(this.$t('general.error'), this.$t('conversation.error.canNotStartConversation'), 'danger');
        }

      });
    }
  }
  ,
  mounted() {
    getExpertDetails(this.token, this.$route.params.id).then(data => {
      this.userProfile = data;
    });

    getExpertReviews(this.token, this.$route.params.id).then(list => {
      this.reviews = list;
    });

    getExpertKnowledge(this.token, this.$route.params.id).then(list => {
      this.knowledgeList = list;
    })
  }
}
</script>

<style lang="scss" scoped>

@import "../assets/colors.scss";


.rating {
  color: $color-salmon;
  font-weight: bold;
}

.profileMenu {
  .divider {
    margin: 0 10px;
    color: #ccc;
  }

  a {
    text-decoration: none;
    color: black;
    font-size: 14px;
    line-height: 20px;

    &.active {
      color: $color-violet;
    }

  }
}

.language-item {
  display: inline-block;
  margin: 5px;
  background: $color-light;
  padding: 5px 10px;
  border-radius: 15px;
  text-decoration: none;
  font-weight: bold;
  color: black;

  img {
    height: 20px;
    margin-right: 5px;
  }
}

.avatar {
  display: inline-block;
  width: 50px;
  height: 50px;
  min-width: 50px;
  max-width: 50px;
  background-size: cover;
  border-radius: 100%;
  border: 1px solid #9EA2B2;
}

.knowledge-card {

  .languages {

    .language-icon {
      width: 18px;
      margin-right: 5px;
    }

    .language-name {
      color: black;
    }

    .arrow {
      margin: 0 10px;
    }

  }


  .category {
    display: inline-block;
    text-decoration: none;
    margin: 4px;
    padding: 5px 15px;
    color: $color-grey;
    background: rgba($color-grey, 0.2);
    border-radius: 15px;
    font-size: 12px;
    font-weight: bold;
  }


}
</style>