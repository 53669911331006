<template>
  <form @submit.prevent="searchTranslator()">

    <div class="row">


      <div class="col-lg-4 col-md-6 col-12  mb-3">
        <div class="input-group input-group-lg">
          <div class="input-group-text">
            <img src="../../assets/icon/input-location.png" alt="location">
          </div>
          <select class="form-control form-control-lg" v-model="location">
            <option value="" disabled selected style="display: none">
              {{ $t('search.location') }}
            </option>
            <option value="0">{{ $t('search.allLocations') }}</option>
            <option v-for="l in locations" :key="l.id" :value="l.id">
              {{ l.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-lg-4  col-md-6 col-12 mb-3">

        <div class="input-group input-group-lg">
          <div class="input-group-text">
            <img src="../../assets/icon/input-language.png" alt="language">
          </div>
          <select class="form-control form-control-lg" v-model="fromLang" required>
            <option value="" disabled selected style="display: none">{{ $t('search.fromLanguage') }}</option>
            <option v-for="l in fromLanguages" :key="l.keyword" :value="l.keyword">
              {{ l.name }}
            </option>
          </select>

        </div>
      </div>

      <div class="col-lg-4  col-md-6 col-12 mb-3">

        <div class="input-group input-group-lg ">
          <div class="input-group-text">
            <img src="../../assets/icon/input-language.png" alt="language">
          </div>
          <select class="form-control form-control-lg" v-model="toLang" required>
            <option value="" disabled selected style="display: none">
              {{$t('search.toLanguage')}}
            </option>
            <option v-for="l in toLanguages" :key="l.keyword" :value="l.keyword">
              {{ l.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-lg-4  col-md-6 col-12 mb-3">
        <DatePicker v-model="fromDate" mode="date" :min-date="new Date()" :max-date="toDate"
                    :model-config="{type: 'string', mask: 'YYYY-MM-DD HH:mm:ss'}">
          <template v-slot="{ inputValue, inputEvents }">
            <div class="input-group input-group-lg ">
              <div class="input-group-text">
                <img src="../../assets/icon/input-calendar.png" alt="calendar">
              </div>
              <input type="text" class="form-control form-control-lg" :value="inputValue"
                     v-on="inputEvents"
                     :placeholder="$t('search.fromDate')">

            </div>
          </template>
        </DatePicker>

      </div>
      <div class="col-lg-4  col-md-6 col-12 mb-3">

        <DatePicker v-model="toDate" mode="date" :min-date="fromDate"
                    :model-config="{type: 'string', mask: 'YYYY-MM-DD'}">
          <template v-slot="{ inputValue, inputEvents }">
            <div class="input-group input-group-lg ">
              <div class="input-group-text">
                <img src="../../assets/icon/input-calendar.png" alt="calendar">
              </div>
              <input type="text" class="form-control form-control-lg" :value="inputValue"
                     v-on="inputEvents"
                     :placeholder="$t('search.toDate')">

            </div>
          </template>
        </DatePicker>

      </div>

      <div class="col-lg-3  col-md-6 col-12 mb-3">
        <label class="p-2" v-if="type==='TRANSLATOR'">
          <input type="checkbox" class="form-check-inline" value="1" v-model="isSworn">
          {{ $t('search.isSworn')}}
        </label>
      </div>
      <div class="col-lg-1  col-md-6 col-12">
        <button class="search-button px-3 py-2">
          <img src="../../assets/icon/seach-large.png" alt="search">
        </button>
      </div>

    </div>


  </form>
</template>

<script>

import moment from "moment";

export default {
  name: "HomeSearchForm",
  props: ['categories', 'languages', 'locations', "type"],
  data() {
    return {
      translatorCategoryListVisible: false,
      fromLang: '',
      toLang: '',
      locationName: '',
      location: '',
      priceFrom: 0,
      priceTo: null,
      fromDateValue: null,
      toDateValue: null,
      selectedCategories: [],
      isSworn: false
    }
  },
  computed: {
    fromLanguages() {
      return this.languages;
    },
    toLanguages() {
      return this.languages.filter(l => {
        return l.keyword !== this.fromLang
      });
    },

    fromDate:{
      get() {
        if(!this.fromDateValue)  {
          return null;
        }
        return moment(this.fromDateValue).format('YYYY-MM-DD');
      },
      set(value) {
        this.fromDateValue = value;
      }
    },
    toDate:{
      get() {
        if(!this.toDateValue)  {
          return null;
        }
        return moment(this.toDateValue).format('YYYY-MM-DD');
      },
      set(value) {
        this.toDateValue = value;
      }
    }
  },
  methods: {
    setPlace(d) {
      console.log(d);
      this.locationName = d.formatted_address;
      this.location = d;
    },
    searchTranslator() {
      this.$emit('searchSubmitted', {
        fromLang: this.fromLang,
        toLang: this.toLang,
        fromDate: this.fromDate,
        toDate: this.toDate,
        fromCost: this.priceFrom,
        toCost: this.priceTo === null ? 100000 : this.priceTo,
        locationId: this.location,
        jobType: this.type,
        isSworn: this.isSworn
      });
    }
  }
}
</script>

<style scoped>



.search-button {
  background: white;
  border-radius: 10px;
  display: block;
  border: none;
  width: 100%;
}

</style>