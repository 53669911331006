<template>

  <div class="bg-white">

    <top-navigation/>

  </div>

  <div class="container py-5" v-if="user == null">
    <div class="alert alert-info">
      {{ $t('general.loading') }}
    </div>
  </div>
  <div class="container py-5" v-else>


    <div class="row">


      <div class="col-md-8">
        <div class="profile d-flex align-items-start ">

          <div class="avatar" :style="{
                                'background-image': `url('${user.profilePhoto===null || user.profilePhoto === ''?'/img/avatar_placeholder.png': `${squareImageBase}/${user.profilePhoto}` }')`
                            }">

          </div>

          <div class="profile-data ps-3">
            <span class="name fw-bold lead me-3"> {{ user.title }} {{ user.name }} {{ user.surname }} </span>

            <div class="d-inline-block rating">
              <span class="d-inline-block" v-for="n in 5" :key="n">
                  <i class="fa fa-star" v-if="userRating >= n"  aria-hidden="true"></i>
                  <i class="fa fa-star-o" v-else aria-hidden="true"></i>
              </span>
              <span class="d-inline-block ms-2">{{ userRating }}</span>
            </div>
            <span class="text-secondary d-inline-block ms-2 fw-normal ">
                            ({{ user.rate !== null ? user.rate.ratedUsersCount : 0 }})
                        </span>

            <br>
            <span>{{ user.country.name }}</span>
          </div>


        </div>
      </div>
      <div class="col-md-4 d-flex justify-content-md-end justify-content-start align-items-center ">
        <div>
          <div class="text-md-end  mb-2">
            <router-link to="/profile/edit"
                         class="btn btn-outline-primary rounded-pill btn-sm px-4 me-2 fw-bold">
              {{ $t('profile.editProfileButton') }}
            </router-link>
          </div>
        </div>
      </div>


      <div class="col-12 d-flex justify-content-end py-2">

        <div class="profileMenu small">

          <a href="#profileOfferConfig" v-if="user.userType === 'EXPERT'">
            <i class="fa fa-list" aria-hidden="true"></i> {{ $t('profile.offerPricingMenu') }}
          </a>
          <span class="divider" v-if="user.userType === 'EXPERT'">|</span>
          <a href="#profileOrders">
            <i class="fa fa-cube" aria-hidden="true"></i> {{ $t('userOrders.title') }}
          </a>
          <span class="divider">|</span>
          <a href="#profileReviews">
            <i class="fa fa-comment-o" aria-hidden="true"></i> {{ $t('profile.reviews.title') }}
          </a>
        </div>


      </div>


    </div>

    <hr>


    <div class="row" v-if="user.userType === 'EXPERT'" id="profileOfferConfig">


      <div class="col-md-12 mt-5">

        <p class="fw-bold lead mb-3">{{ $t('profile.manageYourOffers') }}</p>

        <div class="tabs-view">
          <div class="tabs-header">
            <a class="tab-pill" v-if="user.translator"
               :class="{active: calendarTabsActivePill==='TRANSLATOR'}"
               @click="calendarTabsActivePill = 'TRANSLATOR'">
              {{ $t('expertType.translator') }}
            </a>
            <a class="tab-pill" v-if="user.interpreter"
               :class="{active: calendarTabsActivePill==='INTERPRETER'}"
               @click="calendarTabsActivePill = 'INTERPRETER'">
              {{ $t('expertType.interpreter') }}
            </a>
            <a class="tab-pill" v-if="user.teacher"
               :class="{active: calendarTabsActivePill==='TEACHER'}"
               @click="calendarTabsActivePill = 'TEACHER'">
              {{ $t('expertType.teacher') }}
            </a>
          </div>

          <div class="tab-body" :class="{firstIsActive: calendarTabsActivePill==='TRANSLATOR'}">

            <div v-if="calendarTabsActivePill==='TRANSLATOR'">
              <translator-jobs type="TRANSLATOR" :schedules="activeTypeSchedules" :languages="languages"
                               :categories="categories" @userJobUpdated="loadUserJobs"></translator-jobs>
            </div>
            <div v-if="calendarTabsActivePill==='INTERPRETER'">
              <translator-jobs type="INTERPRETER" :schedules="activeTypeSchedules" :languages="languages"
                               :categories="categories" @userJobUpdated="loadUserJobs"></translator-jobs>
            </div>
            <div v-if="calendarTabsActivePill==='TEACHER'">
              <translator-jobs type="TEACHER" :schedules="activeTypeSchedules" :languages="languages"
                               :categories="categories" @userJobUpdated="loadUserJobs"></translator-jobs>
            </div>
          </div>


        </div>

      </div>


    </div>

    <UserOrders id="profileOrders"></UserOrders>


    <div class="col-md-12 mt-5" id="profileReviews">

      <h4 class="fw-bold mb-5">{{ $t('profile.reviews.title') }}</h4>


      <div v-if="reviews.length<1">
        <div class="alert alert-info">{{ $t('profile.reviews.noReviews') }}</div>
      </div>

      <div class="row mt-5">

        <div class="col-lg-6 mb-4" v-for="r in reviews" :key="r.rating.id">
          <div class="d-flex justify-content-start align-items-start">
            <div>
              <div class="avatar" :style="{
                                'background-image': `url('${r.userView.profilePhoto===null || r.userView.profilePhoto === ''?'/img/avatar_placeholder.png': `${squareImageBase}/${r.userView.profilePhoto}` }')`
                            }">

              </div>

            </div>
            <div class="ps-3 w-100">
              <p class="mb-1">
                <strong>{{ r.userView.name }} {{ r.userView.surname }}</strong>
                <span class="text-warning ms-3">
                  <i class="fa fa-star" aria-hidden="true"></i> {{ r.rating.rate.toFixed(2) }}
                </span>
              </p>
              <p class="small">{{ r.rating.comment }}</p>

              <div v-if="r.rating.answer" class="small p-2 bg-secondary text-white rounded ">
                <strong>{{ user.title }} {{ user.name }} {{ user.surname }}</strong> <br>
                {{ r.rating.answer }}
              </div>
              <div v-else>

                <div v-if="replyFormId === r.rating.id">

                  <form @submit.prevent="submitReviewReply(r.rating.id)">

                    <div class="mb-3">

                      <textarea class="form-control form-control-sm" v-model="replyText"
                                :placeholder="$t('profile.reviews.enterYourResponse')"></textarea>
                    </div>

                    <div>
                      <button type="submit" class="btn btn-sm btn-primary me-3">{{ $t('general.send') }}</button>
                      <button type="button" class="btn btn-sm btn-outline-primary" @click.prevent="replyFormId = ''">
                        {{ $t('general.cancel') }}
                      </button>
                    </div>

                  </form>

                </div>

                <div v-else>
                <a href="#" v-if="canReply(r)" class="text-primary"
                   @click.prevent="replyFormId = r.rating.id">{{ $t('profile.reviews.reply') }}</a>
                </div>

              </div>
            </div>
          </div>
        </div>

      </div>


    </div>
  </div>


  <the-footer/>

</template>

<script>
// @ is an alias to /src

import TopNavigation from "../components/layout/TopNavigation";
import TheFooter from "../components/layout/TheFooter";
import {mapState} from "vuex";
import {mediaBase, squareImageBase} from "@/repositories/Repository";
import {getExpertReviews, getMe, getMyJobs, getPropertyList, replyReview} from "@/repositories/GeneralDataRepository";
import TranslatorJobs from "../components/jobs/TranslatorJobs";
import UserOrders from "../components/UserOrders";
import customAlert from "@/repositories/Utils";
import moment from "moment/moment";

export default {
  name: 'ProfilePage',
  components: {UserOrders, TranslatorJobs, TheFooter, TopNavigation},
  data() {
    return {
      mediaBase: mediaBase,
      squareImageBase: squareImageBase,
      languages: [],
      categories: [],
      calendarTabsActivePill: '',
      activeView: 'about',
      schedules: [],
      activeTypeSchedules: [],
      activeCalendarTab: 'all',
      calendarSchedules: [],
      user: null,
      reviews: [],
      replyFormId: '',
      replyText: ''
    };
  },
  computed: {

    ...mapState(
        {
          token: state => state.auth.token
        }
    ),
    userRating() {

      if (this.user === null) {
        return 0;
      }

      if (this.user.rate === null) {
        return 0;
      }
      if (this.user.rate.ratedUsersCount === 0) {
        return 0;
      }

      return (this.user.rate.rateSum / this.user.rate.ratedUsersCount).toFixed(1);

    }
  },
  watch: {
    user(newData, oldData) {
      if (oldData === null && newData !== null) {
        if (this.calendarTabsActivePill === '') {
          this.setActiveCalendarTab(newData);
        }
        this.loadUserJobs();
      }
    },
    calendarTabsActivePill(newVal) {
      this.activeTypeSchedules = this.getProfileTypeSchedules(newVal);
      this.calendarSchedules = this.getCalendarSchedules(this.activeCalendarTab);
    },
    activeCalendarTab() {
      this.calendarSchedules = this.getCalendarSchedules(this.activeCalendarTab);
    },
    schedules() {
      this.activeTypeSchedules = this.getProfileTypeSchedules(this.calendarTabsActivePill);
      this.calendarSchedules = this.getCalendarSchedules(this.activeCalendarTab);
    },
    replyFormId() {
      this.replyText = '';
    },
    "$i18n.locale"(){
      this.loadPropertyList();
    }
  },

  methods: {
    getLanguageIcon(keyword) {
      let code = keyword.split('.')[1];
      return `${mediaBase}/lang/${code}.png`
    },
    setActiveCalendarTab(newData) {
      if (newData.translator) {
        this.calendarTabsActivePill = 'TRANSLATOR';
      } else if (newData.interpreter) {
        this.calendarTabsActivePill = 'INTERPRETER';
      } else if (newData.teacher) {
        this.calendarTabsActivePill = 'TEACHER';
      }

    },

    getProfileTypeSchedules(type) {
      return this.schedules.filter(s => s.jobType === type);
    },
    getCalendarSchedules(type) {
      if (type === 'all') {
        return this.activeTypeSchedules;
      } else {
        return this.activeTypeSchedules.filter(s => s.id === type);
      }
    },
    loadUserJobs() {
      getMyJobs(this.token, this.user.id).then(data => {

        if (data.code === 200) {
          this.schedules = data.body;
        }

      });
    },

    loadMeData() {
      getMe(this.token).then(data => {

        if (data.code === 200) {
          if (!data.body.translator && !data.body.interpreter && !data.body.teacher && data.body.userType === 'EXPERT') {
            this.$router.push('/registration/expert');
          } else {
            this.user = data.body;

            getExpertReviews(this.token, data.body.id).then(list => {
              this.reviews = list;
            });

          }
        }
      });
    },
    canReply(rating){
      if (rating == null) {
        return false;
      }
      if (rating.localDateTime) {
        return moment(rating.localDateTime).add(3, 'days').isAfter(moment());
      }
      return false;
    },
    submitReviewReply(ratingId) {

      replyReview(this.token, ratingId, this.replyText).then(res => {

        if (res === null) {
          customAlert(this.$t('general.error'), this.$t('general.somethingWentWrong'), "danger");
        } else {
          let {code, message} = res;

          if (code === 200) {

            customAlert(this.$t('profile.reviews.replyAccepted'), '', 'success');
            this.replyText = '';
            this.replyFormId = '';

            this.loadMeData();

          } else {
            customAlert(`Error ${code}`, `${message}`, "danger");
          }
        }

      });
    },

    loadPropertyList(){
      getPropertyList('languages', this.$i18n.locale).then((data) => {
        this.languages = data;
      })
      getPropertyList('job_knowledge', this.$i18n.locale).then((data) => {
        this.categories = data;
      })
    }
  },
  mounted() {
    this.loadMeData();

    this.loadPropertyList();

  }
}
</script>

<style lang="scss" scoped>

@import "../assets/colors.scss";


.rating {
  color: $color-salmon;
  font-weight: bold;
}

.profileMenu {
  .divider {
    margin: 0 10px;
    color: #ccc;
  }

  a {
    text-decoration: none;
    color: black;
    font-size: 14px;
    line-height: 20px;

    &.active {
      color: $color-violet;
    }

  }
}

.language-item {
  display: inline-block;
  margin: 5px;
  background: $color-light;
  padding: 5px 10px;
  border-radius: 15px;
  text-decoration: none;
  font-weight: bold;
  color: black;

  img {
    height: 20px;
    margin-right: 5px;
  }
}


.avatar {
  display: inline-block;
  width: 50px;
  height: 50px;
  min-width: 50px;
  max-width: 50px;
  background-size: cover;
  border-radius: 100%;
  border: 1px solid #9EA2B2;
}


</style>