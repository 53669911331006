<template>

  <div class="bg-white">

    <top-navigation/>

  </div>

  <div class="container login-container">


    <div class="row justify-content-center">


      <div class="col-md-7 col-lg-5 col-11">

        <div class="card login-card">
          <div class="card-body" v-if="googleRegisterCase">
            <form @submit.prevent="gogoleRegistrationSubmit">
              <div class="mb-4">
                <div class="btn-group btn-group-lg w-100 " style="overflow: hidden">
                  <button class="btn btn-lg rounded-pill rounded-end" :class="{
                                    'btn-outline-primary': userType!== 'USER',
                                    'btn-primary': userType=== 'USER',
                                }" @click.prevent="userType='USER'">{{ $t('userType.user') }}
                  </button>
                  <button class="btn btn-lg  rounded-pill rounded-start" :class="{
                                    'btn-outline-primary': userType!== 'EXPERT',
                                    'btn-primary': userType=== 'EXPERT',
                                }" @click.prevent="userType='EXPERT'"> {{ $t('userType.expert') }}
                  </button>
                </div>
              </div>


              <div class="mb-4 form-check">
                <input class="form-check-input" type="checkbox" id="terms" required>
                <label class="form-check-label" for="terms">
                  <a target="_blank" href="/page/terms">
                    {{ $t('general.acceptTermsAndConditions') }}
                  </a>
                </label>
              </div>


              <div class="mb-4 ">
                <button class="btn btn-primary btn-lg btn-pill px-4">
                  {{ $t('authentication.complete') }}
                </button>
              </div>
            </form>

          </div>
          <div class="card-body" v-else-if="facebookRegisterCase">
            <form @submit.prevent="facebookRegistrationSubmit">
              <div class="mb-4">
                <div class="btn-group btn-group-lg w-100 " style="overflow: hidden">
                  <button class="btn btn-lg rounded-pill rounded-end" :class="{
                                    'btn-outline-primary': userType!== 'USER',
                                    'btn-primary': userType=== 'USER',
                                }" @click.prevent="userType='USER'"> {{ $t('userType.user') }}
                  </button>
                  <button class="btn btn-lg  rounded-pill rounded-start" :class="{
                                    'btn-outline-primary': userType!== 'EXPERT',
                                    'btn-primary': userType=== 'EXPERT',
                                }" @click.prevent="userType='EXPERT'"> {{ $t('userType.expert') }}
                  </button>
                </div>
              </div>


              <div class="mb-4 form-check">
                <input class="form-check-input" type="checkbox" id="terms_fb" required>
                <label class="form-check-label" for="terms_fb">
                  <a target="_blank" href="/page/terms">
                    {{ $t('general.acceptTermsAndConditions') }}
                  </a>
                </label>
              </div>


              <div class="mb-4 ">
                <button class="btn btn-primary btn-lg btn-pill px-4">
                  {{ $t('authentication.complete') }}
                </button>
              </div>
            </form>

          </div>
          <div class="card-body" v-else>

            <h2 class="text-dark fw-bold">
              {{ $t('authentication.loginTitle') }}
            </h2>

            <div class="my-4">

              <div class="d-grid gap-4 mb-4">
                <button class="btn btn-lg btn-block btn-pill btn-outline-primary btn-social"
                        type="button" @click.prevent="googleAuth">
                  <i class="fa fa-google" aria-hidden="true"></i>
                  {{ $t('authentication.loginWithGoogle') }}
                </button>
                <button class="btn btn-lg btn-block btn-pill btn-outline-primary btn-social"
                        type="button" @click.prevent="fbAuth">
                  <i class="fa fa-facebook-square" aria-hidden="true"></i>
                  {{ $t('authentication.loginWithFacebook') }}
                </button>
              </div>


              <form @submit.prevent="loginSubmit">
                <div class="my-4">
                  <input class="form-control form-control-lg" type="email"
                         :placeholder="$t('authentication.email')" v-model="login">
                </div>
                <div class="input-group input-group-lg mb-4">
                  <input class="form-control form-control-lg"
                         :type="passwordVisible?'text':'password'"
                         :placeholder="$t('authentication.password')" v-model="password">
                  <div class="input-group-text" @click="passwordVisible = !passwordVisible">
                    <i class="fa" aria-hidden="true"
                       :class="{'fa-eye':!passwordVisible, 'fa-eye-slash': passwordVisible}"></i>
                  </div>
                </div>

                <div class="my-3 alert alert-danger" v-if="loginError!==''">
                  {{ loginError }}
                </div>

                <div class="mb-4 " v-if="isLoginLoading">
                  {{ $t('authentication.loginInProgress') }}
                </div>
                <div class="mb-4 " v-else>
                  <p>
                    <button class="btn btn-primary btn-lg btn-pill px-5 me-5">
                      {{ $t('authentication.loginButton') }}
                    </button>

                    <router-link to="/register" class="text-secondary">
                      {{ $t('authentication.doesNotHaveAccount') }}
                    </router-link>
                  </p>

                  <router-link to="/forget" class="text-secondary small">
                    {{ $t('authentication.forgetPassword') }}
                  </router-link>
                </div>
              </form>
            </div>


          </div>
        </div>
      </div>

    </div>

  </div>

  <the-footer/>

</template>

<script>
// @ is an alias to /src

import TopNavigation from "../components/layout/TopNavigation";
import TheFooter from "../components/layout/TheFooter";
import {login, withFacebook, withGoogle} from "@/repositories/GeneralDataRepository";
import customAlert from "../repositories/Utils";

export default {
  name: 'LoginPage',
  components: {TheFooter, TopNavigation},
  data() {
    return {
      login: '',
      password: '',
      passwordVisible: false,
      loginError: '',
      isLoginLoading: false,
      googleRegisterCase: false,
      googleIdToken: '',
      facebookAccessToken: '',
      facebookRegisterCase: false,
      userType: 'USER',

    };
  },
  methods: {
    loginSubmit() {
      this.loginError = '';
      this.isLoginLoading = true;
      login(this.login, this.password).then(res => {
        this.isLoginLoading = false;
        if (res === null) {
          this.loginError = this.$t('general.somethingWentWrong');
        } else {
          let {code, message, body} = res;

          if (code === 200) {

            localStorage.setItem('token', body.token);
            localStorage.setItem('userId', body.userId);


            this.$store.dispatch('auth/setToken', body.token);
            this.$store.dispatch('auth/loadUser', body.token);

            this.loginSuccess();

          } else if (code === 401) {
            this.loginError = this.$t('authentication.wrongPassword');
          } else {
            this.loginError = `${code}: ${message}`;
          }
        }

      });


    },
    loginWithGoogle(userType) {
      withGoogle(this.googleIdToken, userType).then((res) => {
        let {code, message, body} = res;
        if (code === 200) {
          localStorage.setItem('token', body.token);
          localStorage.setItem('userId', body.userId);

          this.$store.dispatch('auth/setToken', body.token);
          this.$store.dispatch('auth/loadUser', body.token);

          this.loginSuccess();

        } else if (code === 404) {

          this.googleRegisterCase = true;

        } else {
          customAlert(message, '', 'danger');
        }


      })
    },
    googleAuth() {
      this.$gAuth.signIn((user) => {
        this.googleIdToken = user.getAuthResponse().id_token;
        this.loginWithGoogle('');

      }, (error) => {
        console.log(error);
        if (error === false) {
          customAlert(this.$t('authentication.googleNotInitialised'), '', 'danger');
        } else {
          customAlert(error.error, '', 'danger');
        }
      })
    },
    loginWithFacebook(userType) {
      withFacebook(this.facebookAccessToken, userType).then((res) => {
        let {code, message, body} = res;
        if (code === 200) {
          localStorage.setItem('token', body.token);
          localStorage.setItem('userId', body.userId);

          this.$store.dispatch('auth/setToken', body.token);
          this.$store.dispatch('auth/loadUser', body.token);

          this.loginSuccess();

        } else if (code === 404) {

          this.facebookRegisterCase = true;

        } else if (code === 403) {

          customAlert(this.$t('authentication.facebookLoginError'), this.$t('authentication.facebookEmailPermissionIsRequired'), 'danger');

        } else {
          customAlert(message, '', 'danger');
        }


      })
    },

    async fbAuth() {

      window.FB.login(({authResponse}) => {
        if (!authResponse) return;

        this.facebookAccessToken = authResponse.accessToken;
        this.loginWithFacebook('');
      }, {
        scope: 'public_profile,email',
        return_scopes: true,
        authType: 'reauthenticate',
        auth_nonce: (new Date()).getTime()
      });

    },


    gogoleRegistrationSubmit() {
      this.loginWithGoogle(this.userType);
    },

    facebookRegistrationSubmit() {
      this.loginWithFacebook(this.userType);
    },

    loginSuccess() {

      let back = this.$route.query.back;

      if (back) {
        this.$router.push(back);
      } else {
        this.$router.push('/profile');
      }

    }
  }
}
</script>

<style lang="scss" scoped>

@import "../assets/colors.scss";

.login-container {
  background: url("../assets/theme/login.png") center bottom;
  background-size: auto 100%;
  padding: 200px 0;
}

.login-card {
  box-shadow: 0 6px 52px -20px rgba(0, 0, 0, 0.2);
  border-radius: 20px;

  .card-body {
    padding: 50px;
  }
}

.btn-social {
  background: white;
  text-align: left;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);

  &:hover {
    background: $color-violet;
  }
}


</style>