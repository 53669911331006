import {getMe, getUnreadMessageCount} from "../repositories/GeneralDataRepository";

const authStorage = {
    namespaced: true,
    state() {
        return {
            token: null,
            hash: null,
            user: null,
            unreadMessageCount: null,
            loginError: null,
            registerError: null
        }
            ;
    },
    mutations: {
        setToken(state, payload) {
            state.token = payload;
        },

        setHash(state, payload) {
            state.hash = payload;
        },

        setUser(state, payload) {
            state.user = payload;
        },
        setUnreadMessageCount(state, payload) {
            state.unreadMessageCount = payload;
        },
        setLoginError(state, payload) {
            state.loginError = payload;
        },
        setRegisterError(state, payload) {
            state.registerError = payload;
        },
    },
    actions: {
        setToken(context, payload) {
            context.commit('setToken', payload)
        },
        setHash(context, payload) {
            context.commit('setHash', payload)
        },
        setUser(context, payload) {
            context.commit('setUser', payload)
        },
        setLoginError(context, payload) {
            context.commit('setLoginError', payload)
        },
        setRegisterError(context, payload) {
            context.commit('setRegisterError', payload)
        },
        async loadUser(context, token) {
            await getMe(token).then(data => {
                if (data == null) {
                    localStorage.removeItem('token');
                }else if (data.code === 200) {
                    context.commit('setUser', data.body);
                } else if (data.code === 401) {
                    context.commit('setUser', null);
                    context.commit('setToken', null);
                    localStorage.setItem('token', null);
                }

            })
        },

        async loadUnreadMessageCount(context, token) {
            await getUnreadMessageCount(token).then(data => {

                if (data.code === 200) {
                    context.commit('setUnreadMessageCount', data.body);
                } else if (data.code === 401) {
                    context.commit('setUser', null);
                    localStorage.set('token', null);
                }

            })
        },

    },
    getters: {}
}

export default authStorage;