<template>

  <div class="bg-white">

    <top-navigation/>

  </div>

  <div class="container" style="margin: 100px auto;">


    <div class="row">


      <div class="col-lg-4 mb-5">

        <h1 class="text-dark fw-bold">
          {{ $t('authentication.forgetPasswordTitle') }}
        </h1>

        <div class="my-5" v-if="registrationStep ===1">


          <form @submit.prevent="registrationSubmit">
            <div class="mb-4">
              <input class="form-control form-control-lg" type="email" placeholder="Email"
                     v-model="email" autocomplete="email" required>
            </div>
            <div class="my-3 alert alert-danger" v-if="registrationError!==''">
              {{ registrationError }}
            </div>

            <div class="mb-4 ">
              <button class="btn btn-primary btn-lg btn-pill px-4">
                {{ $t('authentication.complete') }}
              </button>
            </div>
          </form>
        </div>

        <div class="my-5" v-if="registrationStep ===2">


          <h2 class="mb-4">{{ $t('authentication.enterCode') }}</h2>


          <form @submit.prevent="registrationOtpSubmit">
            <div class="mb-4">
              <input class="form-control form-control-lg" type="email" :placeholder="$t('authentication.email')"
                     v-model="email" autocomplete="email" readonly>
            </div>

            <div class="mb-4">
              <input class="form-control form-control-lg" type="number" :placeholder="$t('authentication.otp')"
                     v-model="otp" autocomplete="off" required>
            </div>

            <div class="my-3 alert alert-danger" v-if="otpError!==''">
              {{ otpError }}
            </div>


            <div class="mb-4 ">
              <button class="btn btn-primary btn-lg btn-pill px-4">
                {{ $t('authentication.complete') }}
              </button>
            </div>
          </form>
        </div>

        <div class="my-5" v-if="registrationStep ===3">


          <h2 class="mb-4">
            {{$t('authentication.enterNewPassword')}}
          </h2>


          <form @submit.prevent="setPassword">
            <div class="input-group input-group-lg mb-4">
              <input class="form-control form-control-lg" :type="passwordVisible?'text':'password'"
                     :placeholder="$t('authentication.password')" v-model="password" required>
              <div class="input-group-text" @click="passwordVisible = !passwordVisible">
                <i class="fa" :class="{'fa-eye':!passwordVisible, 'fa-eye-slash': passwordVisible}" aria-hidden="true"></i>
              </div>
            </div>

            <div class="my-3 alert alert-danger" v-if="passwordError!==''">
              {{ passwordError }}
            </div>


            <div class="mb-4 ">
              <button class="btn btn-primary btn-lg btn-pill px-4">
                {{ $t('authentication.complete') }}
              </button>
            </div>
          </form>
        </div>

      </div>
      <div class="col-md-6 offset-1 mb-5 p-5">
        <img src="../assets/theme/registration.png" class="img-fluid" alt="image">
      </div>

    </div>

  </div>

  <the-footer/>

</template>

<script>
// @ is an alias to /src

import TopNavigation from "../components/layout/TopNavigation";
import TheFooter from "../components/layout/TheFooter";
import {changePassword, forget, registerOtp} from "@/repositories/GeneralDataRepository";
import {mapState} from "vuex";
import customAlert from "@/repositories/Utils";

export default {
  name: 'ForgetPage',
  components: {TheFooter, TopNavigation},
  data() {
    return {


      email: '',
      registrationError: '',
      registrationKey: '',
      registrationStep: 1,
      otp: '',
      otpError: '',
      password: '',
      passwordError: '',
      passwordVisible: false

    };
  },
  computed: {
    ...mapState({
      token: state => state.auth.token,
      user: state => state.auth.user,
    })
  },
  methods: {
    registrationSubmit() {


      forget(this.email).then(res => {

        if (res === null) {
          this.registrationError = this.$t('general.somethingWentWrong');
        } else {
          let {code, message, body} = res;

          if (code === 200) {

            this.registrationKey = body;
            this.registrationStep = 2;


          } else {
            this.registrationError = `${code}: ${message}`;
          }
        }

      })

    },
    registrationOtpSubmit() {
      registerOtp(this.registrationKey, this.otp).then(res => {

        if (res === null) {
          this.otpError = this.$t('general.somethingWentWrong');
        } else {
          let {code, message, body} = res;

          if (code === 200) {

            localStorage.setItem('token', body.token);
            localStorage.setItem('userId', body.userId);


            this.$store.dispatch('auth/setToken', body.token);
            this.$store.dispatch('auth/loadUser', body.token);
            this.registrationStep = 3;

          } else if (code === 401) {
            this.otpError = this.$t('authentication.wrongOtp');
          } else {
            this.otpError = `${code}: ${message}`;
          }
        }

      });
    },
    setPassword() {
      changePassword(this.token, this.password).then(res => {

        if (res === null) {
          this.passwordError = this.$t('general.somethingWentWrong');
        } else {
          let {code, message} = res;

          if (code === 200) {

            customAlert(this.$t('authentication.passwordUpdated'));

            this.$router.push('/profile');

          } else if (code === 401) {
            this.otpError = this.$t('authentication.wrongPassword');
          } else {
            this.otpError = `${code}: ${message}`;
          }
        }

      });
    }
  },
  watch: {
    "this.$route"() {
      if (this.$route.query['type']) {
        this.userType = this.$route.query['type'];
      }
    },

    user(newValue) {
      if (newValue !== null) {
        this.$router.push('/profile/password')
      }
    }
  },
  mounted() {

    console.log(this.$route);

    if (this.$route.query['type']) {
      this.userType = this.$route.query['type'];
    }

  }
}
</script>

<style lang="scss" scoped>

@import "../assets/colors.scss";


.btn-social {
  background: white;
  text-align: left;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);

  &:hover {
    background: $color-violet;
  }
}


</style>