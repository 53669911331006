<template>
  <section>

    <div class="footer-line">

    </div>

    <div class="footer">

      <div class="container">

        <div class="row">

          <div class="col-lg-3 col-md-6">

            <img src="../../assets/logo-white.png" alt="logo">

            <p class="mt-4">
              {{ $t('footer.info') }}
            </p>

          </div>
          <div class="col-lg-3 col-md-6">
            <p class="lead fw-bold">
              {{ $t('footer.menu.corporate.title') }}
            </p>

            <ul class="footer-menu">
              <li>
                <router-link to="/about">{{ $t('footer.menu.corporate.aboutUs') }}</router-link>
              </li>
              <li>
                <router-link to="/page/privacy">{{ $t('footer.menu.corporate.privacy') }}</router-link>
              </li>
              <li>
                <router-link to="/contact">{{ $t('footer.menu.corporate.contact') }}</router-link>
              </li>
            </ul>
          </div>
          <div class="col-lg-3 col-md-6">
            <p class="lead fw-bold">
              {{ $t('footer.menu.support.title') }}
            </p>

            <ul class="footer-menu">
              <li>
                <router-link to="/profile">{{ $t('footer.menu.support.account') }}</router-link>
              </li>
              <li>
                <router-link to="/page/buscond">{{ $t('footer.menu.support.business') }}</router-link>
              </li>
              <li>
                <router-link to="/page/complaints">{{ $t('footer.menu.support.complains') }}</router-link>
              </li>
              <li>
                <router-link to="/page/gdpr">{{ $t('footer.menu.support.gdpr') }}</router-link>
              </li>
            </ul>
          </div>

          <div class="col-lg-3 col-md-6">
            <p class="lead fw-bold">
              {{ $t('footer.menu.contact.title') }}
            </p>

            <ul class="footer-menu">
              <li><a href="#">{{ $t('footer.menu.contact.phone') }}</a></li>
              <li><a href="#">{{ $t('footer.menu.contact.email') }}</a></li>
            </ul>
          </div>

        </div>

        <hr class="my-4">


        <div class="row">

          <div class="col-md-6 text-center text-lg-start">
            {{ $t('footer.copyright') }}
          </div>
          <div class="col-md-6 text-center  text-lg-end">
            {{ $t('footer.madeBy') }}
          </div>

        </div>

      </div>

    </div>


  </section>
</template>

<script>
export default {
  name: "TheFooter"
}
</script>

<style lang="scss" scoped>

@import "../../assets/colors";

.footer-line {
  height: 25px;
  background: url("../../assets/theme/footer-lent.png") #000000;
  background-size: auto 100%;
}

.footer {
  background: black;
  padding: 50px 0;
  color: white;

  a {
    color: white;
  }

  .footer-menu {

    list-style: none;
    padding-left: 0;

    li {

      &::before {
        content: ""; /* Add content: \2022 is the CSS Code/unicode for a bullet */
        background: $color-violet; /* Change the color */
        display: inline-block; /* Needed to add space between the bullet and the text */
        width: 8px;
        height: 8px;
        margin-right: 10px;
      }


      a {
        text-decoration: none;
        display: inline-block;
        line-height: 2;
      }
    }
  }
}

</style>
