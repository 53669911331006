<template>

  <div class="bg-white">

    <top-navigation/>

  </div>

  <div class="container py-5" v-if="user == null">
    <div class="alert alert-info">
      {{ $t('general.loading') }}
    </div>
  </div>


  <div class="container py-5" v-else-if="chatList.length<1">
    <div class="card">
      <div class="card-body text-center text-secondary d-flex align-items-center bg-white" style="min-height: 70vh">
        <div class="w-100">
          <i class="fa fa-info-circle fa-3x mb-3"></i> <br>
          There is no active conversation. You can search experts and start a conversation.
        </div>
      </div>

    </div>
  </div>
  <div class="container py-5" v-else>

    <div class="row g-0">
      <div class="col-md-4 col-lg-3 bg-white" style="height: 570px; overflow-x: hidden; overflow-y: auto; "
           v-if="(targetProfile===null && isMobile) || !isMobile ">


        <div class="list-group list-group-flush">
          <div class="list-group-item list-group-item-action" v-for="h in chatList" :key="h.idHistory"
               style="cursor: pointer" :class="{active: h.idHistory === historyId}"
               @click="goToChat(h.idHistory)">

            <div class="d-flex justify-content-between align-items-start">
              <div class="d-flex justify-content-start align-items-center">

                <img :src="`${squareImageBase}/${h.user.profilePhoto}`" style="width: 50px" alt="avatar"
                     class="img-thumbnail rounded-circle" v-if="h.user.profilePhoto">
                <img src="/img/avatar_placeholder.png" style="width: 50px" alt="avatar"
                     class="img-thumbnail rounded-circle" v-else>

                <div class="px-3" style="overflow: hidden">
                  <div class="text-truncate">
                                    <span class="status-dot" :class="{
                                    'bg-success': h.status ==='ONLINE',
                                    'bg-secondary': h.status ==='OFFLINE',
                                     }"> </span>
                    {{ h.user.name }} {{ h.user.surname }}

                  </div>
                  <div class="text-truncate small text-secondary">
                    {{ getMessageContent(h.lastMessage) }}
                  </div>


                </div>

              </div>
              <div v-if="h.unReadMessagesCount>0" class="unread-count">
                {{ h.unReadMessagesCount }}
              </div>
            </div>


          </div>
        </div>

      </div>
      <div class="col-md-8 col-lg-9">

        <div class="card" v-if="targetProfile===null && !isMobile && chatList.length>0">
          <div class="card-body">
            <div style="height: 540px;" class="d-flex justify-content-center align-items-center">
              <div class="text-secondary">
                <h2 class="text-center">{{ $t('conversation.conversations') }}</h2>
                <p class="text-center">{{ $t('conversation.selectConversationToStart') }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="card" v-else-if="targetProfile!==null">
          <div class="card-header">
            <div class="profile d-flex align-items-center justify-content-between  ">

              <div class=" d-flex align-items-center justify-content-start">
                <div @click.prevent="targetProfile=null" v-if="isMobile" class="p-2">
                  <i class="fa fa-arrow-left" aria-hidden="true"></i>
                </div>
                <div class="avatar" v-if="!isMobile" :style="{
                                'background-image': `url('${targetProfile.profilePhoto===null || targetProfile.profilePhoto === ''?'/img/avatar_placeholder.png': `${squareImageBase}/${targetProfile.profilePhoto}` }')`
                            }">

                </div>

                <div class="profile-data ps-3 d-flex align-items-center">

                  <router-link class="conversationTitle" v-if="targetProfile.userType==='EXPERT'"
                               :to="{name: 'UserPage', params: {id: targetProfile.id}}">
                    <span class=" fw-bold lead me-3"> {{ targetProfile.name }} {{ targetProfile.surname }} </span>
                  </router-link>
                  <a class="conversationTitle" v-else>
                    <span class=" fw-bold lead me-3"> {{ targetProfile.name }} {{ targetProfile.surname }} </span>
                  </a>
                  <span class="status-dot" :class="{
                                    'bg-success': targetProfile.status ==='ONLINE',
                                    'bg-secondary': targetProfile.status ==='OFFLINE',
                                     }"> </span>

                </div>
              </div>

              <div class="small" v-if="orderList.length && !isMobile" style="cursor: pointer"
                   @click.prevent="openOrdersView()">
                {{ $tc('conversation.youHaveActiveOrders', orderList.length) }}

              </div>


              <div v-if="user.userType==='EXPERT'">
                <button class="btn btn-success rounded-pill" @click.prevent="createNewOrder"
                        v-if="!isMobile">{{ $t('order.createNewOrder') }}
                </button>
              </div>


              <div>
                <div class="mx-3 my-2" style="cursor: pointer" data-bs-toggle="dropdown" aria-expanded="false">
                  <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                </div>

                <ul class="dropdown-menu  dropdown-menu-lg-end">
                  <li v-if="isMobile">
                    <a class="dropdown-item" @click.prevent="createNewOrder">
                      {{ $t('order.createNewOrder') }}
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item"
                       @click.prevent="exportChatPopupIsOpen = true">{{ $t('conversation.exportHistory') }}</a>
                  </li>
                </ul>
              </div>

            </div>

            <div class="small text-center border border-secondary rounded"
                 v-if="orderList.length && isMobile" style="cursor: pointer"
                 @click.prevent="openOrdersView()">
              {{ $tc('conversation.youHaveActiveOrders', orderList.length) }}
            </div>
          </div>
          <div class="card-body position-relative">

            <div style="overflow: auto; height: 400px;" id="messageList" @scroll="handleScroll">

              <div v-for="(m,i) in messages" :key="i">


                <div class=" text-center"
                     v-if=" i ===0 || getFormattedDate(m.messageInfo.dateTime) !== getFormattedDate(messages[i-1].messageInfo.dateTime)">
                  <span class="badge bg-secondary">{{ getFormattedDate(m.messageInfo.dateTime) }}</span>
                </div>

                <div v-if="m.messageInfo.body.type === 'complaintCreated'" class="w-75 mx-auto">

                  <div class="rounded p-2 text-center bg-warning text-dark">

                    <a href="#" class="text-dark small text-decoration-none"
                       @click.prevent="getOrderDetails(m.messageInfo.body.orderId)">
                      <template v-if="m.userView.id === user.id">
                        {{ $t('conversation.orderActions.thereIsAComplaintOnOrder') }}
                      </template>
                      <template v-else>
                        {{ $t('conversation.orderActions.yourComplaintRegistered') }}
                      </template>
                    </a>
                  </div>
                  <em class="small">
                    <i class="fa fa-clock-o" aria-hidden="true"></i>
                    {{ getFormattedTime(m.messageInfo.dateTime) }}
                  </em>
                </div>
                <div v-else-if="m.messageInfo.body.type === 'complaintRejected'" class="w-75 mx-auto">

                  <div class="rounded p-2 text-center bg-warning  text-dark ">

                    <a href="#" class="text-dark small text-decoration-none"
                       @click.prevent="getOrderDetails(m.messageInfo.body.orderId)">
                      {{ $t('conversation.orderActions.complaintRejected') }}
                    </a>
                  </div>
                  <em class="small">
                    <i class="fa fa-clock-o" aria-hidden="true"></i>
                    {{ getFormattedTime(m.messageInfo.dateTime) }}
                  </em>
                </div>
                <div v-else-if="m.messageInfo.body.type === 'complaintAccepted'" class="w-75 mx-auto">

                  <div class="rounded p-2 bg-warning  text-dark">

                    <a href="#" class="text-dark small text-decoration-none"
                       @click.prevent="getOrderDetails(m.messageInfo.body.orderId)">
                      {{ $t('conversation.orderActions.complaintAccepted') }}
                    </a>
                  </div>
                  <em class="small">
                    <i class="fa fa-clock-o" aria-hidden="true"></i>
                    {{ getFormattedTime(m.messageInfo.dateTime) }}
                  </em>
                </div>
                <div v-else-if="m.messageInfo.body.type === 'cashPaymentRejected'" class="w-75 mx-auto">

                  <div class="rounded p-2 bg-warning text-dark">

                    <a href="#" class="text-dark small text-decoration-none"
                       @click.prevent="getOrderDetails(m.messageInfo.body.orderId)">
                      {{ $t('conversation.orderActions.cashPaymentRejected') }}
                    </a>

                  </div>
                  <em class="small">
                    <i class="fa fa-clock-o" aria-hidden="true"></i>
                    {{ getFormattedTime(m.messageInfo.dateTime) }}
                  </em>

                </div>
                <div v-else-if="m.messageInfo.body.type === 'adminOfferAssigned'" class="w-75 mx-auto">

                  <div class="rounded p-2 bg-warning text-dark">

                    <a href="#" class="text-dark small text-decoration-none"
                       @click.prevent="getOrderDetails(m.messageInfo.body.orderId)">

                      <span v-if="m.userView.id === user.id">
                                {{ $t('conversation.orderActions.adminAssignedYourOrderToThisExpert') }}
                              </span>
                      <span v-else>
                                {{ $t('conversation.orderActions.adminAssignedAnOfferToYou') }}
                              </span>

                    </a>

                  </div>
                  <em class="small">
                    <i class="fa fa-clock-o" aria-hidden="true"></i>
                    {{ getFormattedTime(m.messageInfo.dateTime) }}
                  </em>

                </div>

                <div v-else-if="m.messageInfo.body.type === 'courierNotification'" class="w-75 mx-auto">

                  <div class="rounded p-2 bg-warning text-dark">

                    <a href="#" class="text-dark small text-decoration-none"
                       @click.prevent="getOrderDetails(m.messageInfo.body.orderId)">

                      <span v-if="m.userView.id === user.id">
                        {{ $t('conversation.orderActions.youWillBeContactedSoonByCourierService') }}
                      </span>
                      <span v-else>
                        {{ $t('conversation.orderActions.courierWillContactToTheUserYouWillGetANotification') }}
                      </span>

                    </a>

                  </div>
                  <em class="small">
                    <i class="fa fa-clock-o" aria-hidden="true"></i>
                    {{ getFormattedTime(m.messageInfo.dateTime) }}
                  </em>

                </div>

                <div v-else-if="m.messageInfo.body.type === 'adminOfferExpertChanged'" class="w-75 mx-auto">

                  <div class="rounded p-2 bg-warning text-dark">

                    <a href="#" class="text-dark small text-decoration-none"
                       @click.prevent="getOrderDetails(m.messageInfo.body.orderId)">
                      <span v-if="m.userView.id === user.id">
                        {{ $t('conversation.orderActions.yourOrderHasBeenAssignedToAnotherExpert') }}
                      </span>
                      <span v-else>
                         {{ $t('conversation.orderActions.thisOrderHasBeenAssignedToAnotherExpert') }}
                      </span>
                    </a>

                  </div>
                  <em class="small">
                    <i class="fa fa-clock-o" aria-hidden="true"></i>
                    {{ getFormattedTime(m.messageInfo.dateTime) }}
                  </em>

                </div>
                <div v-else-if="m.messageInfo.body.type === 'orderApproved'" class="w-75 mx-auto">

                  <div class="rounded p-2 bg-warning text-dark">

                    <a href="#" class="text-dark small text-decoration-none"
                       @click.prevent="getOrderDetails(m.messageInfo.body.orderId)">
                      <span v-if="m.userView.id === user.id">
                        {{ $t('conversation.orderActions.yourOrderHasBeenApproved') }}
                      </span>
                      <span v-else>
                         {{ $t('conversation.orderActions.thisOrderHasBeenApprovedStartWorking') }}
                      </span>
                    </a>

                  </div>
                  <em class="small">
                    <i class="fa fa-clock-o" aria-hidden="true"></i>
                    {{ getFormattedTime(m.messageInfo.dateTime) }}
                  </em>

                </div>

                <div v-else class="message d-flex my-2" :class="{
                                        'justify-content-end': m.userView.id === user.id,
                                        'justify-content-start': m.userView.id !== user.id,
                                    }" :id="`message${m.messageInfo.id}`">
                  <div>
                    <div class="pe-2" v-if=" i===0 || messages[i-1].userView.id !== m.userView.id ">

                      <div class="d-flex align-items-center" v-if="m.userView.id === user.id">
                        <div class="avatar message-avatar me-2"
                             :style="{'background-image': `url('${user.profilePhoto===null || user.profilePhoto === ''?'/img/avatar_placeholder.png': `${squareImageBase}/${user.profilePhoto}` }')`}">
                        </div>
                        <strong>
                          {{ user.name }} {{ user.surname }}
                        </strong>
                      </div>
                      <div class="d-flex align-items-center" v-else>
                        <div class="avatar message-avatar me-2"
                             :style="{'background-image': `url('${targetProfile.profilePhoto===null || targetProfile.profilePhoto === ''?'/img/avatar_placeholder.png': `${squareImageBase}/${targetProfile.profilePhoto}` }')`}">
                        </div>
                        <strong>
                          {{ targetProfile.name }} {{ targetProfile.surname }}
                        </strong>
                      </div>
                    </div>
                    <div class="d-flex " :class="{
                                        'justify-content-end': m.userView.id === user.id,
                                        'justify-content-start': m.userView.id !== user.id,
                                    }">

                      <div>
                        <div class="p-2 mt-2 message-area" :class="{
                                        'my-message': m.userView.id === user.id,
                                        'opposite-message': m.userView.id !== user.id,
                                            }">
                          <div v-if="m.messageInfo.body.type === 'text'" class=" px-2">
                            {{ m.messageInfo.body.message }}
                          </div>

                          <div v-if="m.messageInfo.body.type === 'orderCreated'"
                               class=" px-2">
                            <a :class="{'text-white': m.userView.id === user.id, 'text-dark': m.userView.id !== user.id, } "
                               href="#"
                               @click.prevent="getOrderDetails(m.messageInfo.body.orderId)">{{
                                $t('conversation.orderActions.newOrderCreated')
                              }}</a>
                          </div>

                          <div v-if="m.messageInfo.body.type === 'orderUpdated'"
                               class=" px-2">
                            <a :class="{'text-white': m.userView.id === user.id, 'text-dark': m.userView.id !== user.id, }"
                               href="#"
                               @click.prevent="getOrderDetails(m.messageInfo.body.orderId)">{{
                                $t('conversation.orderActions.offerChanged')
                              }}</a>
                          </div>


                          <div v-if="m.messageInfo.body.type === 'orderAccepted'"
                               class=" px-2">
                            <a :class="{'text-white': m.userView.id === user.id, 'text-dark': m.userView.id !== user.id, }"
                               href="#"
                               @click.prevent="getOrderDetails(m.messageInfo.body.orderId)">{{
                                $t('conversation.orderActions.offerApproved')
                              }}</a>
                          </div>

                          <div v-if="m.messageInfo.body.type === 'orderFinished'"
                               class=" px-2">
                            <a :class="{'text-white': m.userView.id === user.id, 'text-dark': m.userView.id !== user.id, }"
                               href="#"
                               @click.prevent="getOrderDetails(m.messageInfo.body.orderId)">{{
                                $t('conversation.orderActions.orderFinished')
                              }}</a>
                          </div>

                          <div v-if="m.messageInfo.body.type === 'orderRated'"
                               class=" px-2">
                            <a :class="{'text-white': m.userView.id === user.id, 'text-dark': m.userView.id !== user.id, }"
                               href="#"
                               @click.prevent="getOrderDetails(m.messageInfo.body.orderId)">{{
                                $t('conversation.orderActions.orderRated')
                              }}</a>
                          </div>

                          <div v-else-if="['photo','file'].indexOf(m.messageInfo.body.type)> -1">
                            <a :href="`${mediaBase}/${m.messageInfo.body.file}`"
                               v-if="isPhoto(m.messageInfo.body.file)" target="_blank">
                              <img :src="`${squareImageBase}/${m.messageInfo.body.file}`" alt="avatar"
                                   style="max-height: 200px; max-width: 300px;">
                            </a>

                            <div v-else>{{ $t('conversation.sentAnAttachement') }}<br>
                              <a :href="`${mediaBase}/${m.messageInfo.body.file}`"
                                 :class="{
                                                        'text-white': m.userView.id === user.id,
                                                        'text-dark': m.userView.id !== user.id
                                                        }" target="_blank">
                                <strong>{{ $t('conversation.downloadFile') }}
                                  *.{{ getFileExtension(m.messageInfo.body.file) }} file</strong>
                              </a>
                            </div>
                          </div>

                        </div>
                        <div class="ms-2 small text-start">
                            <span class="small">
                                <i class="fa fa-clock-o" aria-hidden="true"></i>
                                {{ getFormattedTime(m.messageInfo.dateTime) }}
                            </span>
                        </div>
                      </div>


                    </div>
                  </div>

                </div>
              </div>

            </div>
            <hr>
            <div>

              <div class="row">
                <div class="col-3">

                  <div v-if="uploadProgress>0 && uploadProgress<100">
                    <div class="progress" v-if="uploadProgress >0 && uploadProgress <100">
                      <div class="progress-bar progress-bar-striped progress-bar-animated"
                           role="progressbar" aria-valuenow="75" aria-valuemin="0"
                           aria-valuemax="100" :style="{width: `${uploadProgress}%`}"></div>
                    </div>
                  </div>
                  <div v-else>
                    <input type="file" style="display: none" id="fileInput" ref="file"
                           @change="setAttachment">
                    <button type="button" class="btn btn-primary rounded-pill w-100"
                            @click="selectAttachemnt">
                      <i class="fa fa-paperclip" aria-hidden="true"></i>
                      <span v-if="!isMobile">{{ $t('conversation.uploadFile') }}</span>
                    </button>

                  </div>
                </div>
                <div class="col-9">
                  <form @submit.prevent="sendMessage">

                    <div class="input-group rounded-pill">
                      <input type="text" class="form-control" v-model.trim="messageText" required
                             :placeholder="$t('conversation.typeYourMessage')">
                      <button class="btn btn-success"><i class="fa fa-send" aria-hidden="true"></i></button>
                    </div>

                  </form>
                </div>
              </div>


            </div>

          </div>
        </div>

      </div>
    </div>


  </div>

  <Modal v-if="newOrderPopupIsOpen" size="md" :title="$t('order.newOrder')" :z-index="1050"
         @modalClose="closeCreateOrderPopup">


    <ul class="nav nav-tabs">
      <li class="nav-item">
        <a class="nav-link" :class="{'active':orderType==='TRANSLATOR'}"
           @click.prevent="orderType='TRANSLATOR'">
          {{ $t('orderType.translation') }}
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#" :class="{'active':orderType==='INTERPRETER'}"
           @click.prevent="orderType='INTERPRETER'">{{ $t('orderType.interpreter') }}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#" :class="{'active':orderType==='TEACHER'}"
           @click.prevent="orderType='TEACHER'">{{ $t('orderType.teacher') }}</a>
      </li>
    </ul>
    <TranslatorOrderForm v-if="orderType==='TRANSLATOR'" :target-user-id="targetProfile.id"
                         @created="newOrderCreated($event)"/>
    <InterpreterOrderForm v-if="orderType==='INTERPRETER'" :target-user-id="targetProfile.id"
                          @created="newOrderCreated($event)"/>
    <TeacherOrderForm v-if="orderType==='TEACHER'" :target-user-id="targetProfile.id"
                      @created="newOrderCreated($event)"/>
  </Modal>


  <Modal v-if="ordersViewIsOpen" size="md" :title="$t('order.activeOrders')"
         @modalClose="ordersViewIsOpen=false" :z-index="1050">

    <div v-for="o in orderList" :key="o.firstOrder.id" class="card mb-3" style="cursor: pointer"
         @click.prevent="getOrderDetails(o.firstOrder.id)">
      <OrderListItem :o="o"/>
    </div>


  </Modal>

  <Modal v-if="activeOrder" size="md" :title="`#${lastOfferDetails.orderNo}`"
         @modalClose="activeOrder=false" :z-index="1060">


    <OrderDetails :active-order="activeOrder"></OrderDetails>

    <div class="row">


      <div class="col-md-12 d-flex align-items-center justify-content-center">

        <div class="alert alert-warning my-3" v-if="lastOfferDetails.orderStatus === 'EXPIRED'">
          {{ $t('order.thisOrderExpired') }}
        </div>
        <div class="text-end" v-if="lastOfferDetails.idExpert === this.user.id">
          <!-- expert -->


          <span class="badge bg-warning"
                v-if="lastOfferDetails.orderStatus === 'COMPLAINT'">{{ $t('order.statuses.complaint') }}</span>

          <div v-if="lastOfferDetails.orderStatus === 'WAITING'">
            <button class="btn btn-outline-danger rounded-pill  me-2" @click="editActiveOrder()">
              {{ $t('order.editOffer') }}
            </button>
            <button class="btn btn-outline-danger rounded-pill" @click="cancelOffer(activeOrder.firstOrder.id)">
              {{ $t('order.cancelOffer') }}
            </button>
          </div>

          <div v-if="lastOfferDetails.orderStatus === 'APPROVED'">
            <span class="badge bg-success">{{ $t('order.statuses.accepted') }}</span> <br>
            <button class="btn btn-outline-danger rounded-pill" @click="complainOffer(activeOrder.firstOrder.id)">
              {{ $t('order.complaintOrder') }}
            </button>
          </div>

          <span class="badge bg-warning"
                v-if="lastOfferDetails.orderStatus === 'CASH_PENDING'">{{ $t('order.statuses.cash_pending') }}</span>
          <span class="badge bg-danger"
                v-if="lastOfferDetails.orderStatus === 'REJECTED'">{{ $t('order.statuses.rejected') }}</span>
        </div>
        <div class="text-end" v-else>
          <!-- user -->

          <span class="badge bg-warning"
                v-if="lastOfferDetails.orderStatus === 'COMPLAINT'">{{ $t('order.statuses.complaint') }}</span>

          <span class="badge bg-warning"
                v-if="lastOfferDetails.orderStatus === 'CASH_PENDING'">{{ $t('order.statuses.cash_pending') }}</span>

          <span class="badge bg-danger"
                v-if="lastOfferDetails.orderStatus === 'REJECTED'">{{ $t('order.statuses.rejected') }}</span>

          <div v-if="lastOfferDetails.orderStatus === 'WAITING'">
            <button class="btn btn-success  rounded-pill me-2"
                    @click="confirmOrderPopup(activeOrder.firstOrder.id, activeOrder.firstOrder.kuryer>0)"
            >{{ $t('order.acceptOffer') }}
            </button>

            <button class="btn btn-danger  rounded-pill" @click="cancelOffer(activeOrder.firstOrder.id)">
              {{ $t('order.cancelOffer') }}
            </button>
          </div>
          <div v-if="lastOfferDetails.orderStatus === 'APPROVED'">

            <button class="btn btn-outline-danger rounded-pill " @click="complainOffer(activeOrder.firstOrder.id)">
              {{ $t('order.complaintOrder') }}
            </button>
          </div>
          <div v-if="lastOfferDetails.orderStatus === 'FINISHED'">

            <div v-if="activeOrder.orderRate == null">
              <button class="btn btn-success rounded-pill  me-2" @click="rateActiveOrder()"  v-if="canRate">
                {{ $t('order.rateThisOrder') }}
              </button>
            </div>
            <span class="h2" v-else>
              {{ activeOrder.orderRate.rate.toFixed(1) }} <i class="fa fa-star" aria-hidden="true"></i>
            </span>

          </div>

        </div>

      </div>

    </div>


  </Modal>


  <Modal v-if="editOrderPopupIsOpen" size="md" :title="$t('order.changeOffer')"
         @modalClose="closeEditOrderPopup" :z-index="1070">

    <form @submit.prevent="submitEditOrderForm">

      <div class="mb-3">
        <label>{{ $t('order.workVolume') }}</label>
        <div class="input-group">
          <input class="form-control" v-model.number="orderEditPageCount" min="1" required>
          <div class="input-group-text">
            <span v-if="orderEditType==='TRANSLATOR'">{{ $tc('order.units.page', orderEditPageCount) }}</span>
            <span v-if="orderEditType==='INTERPRETER'">{{ $tc('order.units.hour', orderEditPageCount) }}</span>
            <span v-if="orderEditType==='TEACHER'">{{ $tc('order.units.lesson', orderEditPageCount) }}</span>
          </div>
        </div>
      </div>

      <div class="mb-3">
        <label>{{ $t('order.price') }}</label>
        <div class="input-group">
          <input class="form-control" v-model.number="orderEditPrice" min="1" required>
          <div class="input-group-text">
            kc/<span v-if="orderEditType==='TRANSLATOR'">{{ $tc('order.units.page', orderEditPageCount) }}</span>
            <span v-if="orderEditType==='INTERPRETER'">{{ $tc('order.units.hour', orderEditPageCount) }}</span>
            <span v-if="orderEditType==='TEACHER'">{{ $tc('order.units.lesson', orderEditPageCount) }}</span>
          </div>
        </div>
      </div>

      <div class="mb-3">
        <label>{{ $t('order.deadline') }}</label>
        <DatePicker v-model="orderEditDeadline" mode="dateTime" is24hr
                    :minute-increment="15" :min-date="orderEditAcceptanceDeadline"
                    :model-config="{type: 'string', mask: 'YYYY-MM-DD HH:mm:ss'}"
                     is-required>
          <template v-slot="{ inputValue, inputEvents }">
            <input type="text" class="form-control " :value="inputValue"
                   v-on="inputEvents"
                   placeholder="" required>
          </template>
        </DatePicker>
      </div>

      <div class="mb-3">
        <label>{{ $t('order.deadlineForAcceptance') }}</label>
        <DatePicker v-model="orderEditAcceptanceDeadline" mode="dateTime" is24hr
                    :minute-increment="15" :min-date="new Date()" :max-date="orderEditDeadline"
                    :model-config="{type: 'string', mask: 'YYYY-MM-DD HH:mm:ss'}" is-required>
          <template v-slot="{ inputValue, inputEvents }">
            <input type="text" class="form-control " :value="inputValue"
                   v-on="inputEvents" placeholder="" required>
          </template>
        </DatePicker>
      </div>

      <div class="mb-3">
        <label>{{ $t('order.notes') }}</label>
        <input type="text" class="form-control" v-model="orderEditNote">
      </div>

      <div class="my-3">
        <button type="submit" class="btn btn-primary rounded-pill w-100">
          {{ $t('general.send') }}
        </button>
      </div>
    </form>
  </Modal>

  <Modal v-if="rateOrderPopupIsOpen" size="sm" :title="$t('order.rateOrder')"
         @modalClose="closeRateOrderPopup" :z-index="1080">

    <form @submit.prevent="submitRateOrderForm">

      <div class="mb-3">
        <label>{{ $t('order.rate') }}</label>

        <div class="text-warning">
          <i :class="['fa', 'fa-2x', orderRate>0?'fa-star':'fa-star-o']" @click="orderRate=1" aria-hidden="true"></i>
          <i :class="['fa', 'fa-2x', orderRate>1?'fa-star':'fa-star-o']" @click="orderRate=2" aria-hidden="true"></i>
          <i :class="['fa', 'fa-2x', orderRate>2?'fa-star':'fa-star-o']" @click="orderRate=3" aria-hidden="true"></i>
          <i :class="['fa', 'fa-2x', orderRate>3?'fa-star':'fa-star-o']" @click="orderRate=4" aria-hidden="true"></i>
          <i :class="['fa', 'fa-2x', orderRate>4?'fa-star':'fa-star-o']" @click="orderRate=5" aria-hidden="true"></i>
        </div>
      </div>

      <div class="mb-3">
        <label>{{ $t('order.rateComment') }}</label>
        <textarea class="form-control" v-model="orderRateNote"></textarea>
      </div>

      <div class="my-3">
        <button type="submit" class="btn btn-primary rounded-pill w-100">{{ $t('order.rateNow') }}</button>
      </div>
    </form>
  </Modal>
  <Modal v-if="complainPopupIsOpen" size="md" :title="$t('order.complaint.title')"
         @modalClose="complainPopupIsOpen = false" :z-index="1080">

    <form @submit.prevent="submitComplaintForm">


      <div class="mb-3">
        <label>{{ $t('order.complaint.subject') }}</label>
        <select type="text" class="form-control" v-model="complaintTitle" required>
          <option v-for="t in complaintTitleList" :key="t.id" :value="t.name">{{ t.name }}</option>
        </select>
      </div>
      <div class="mb-3">
        <label>{{ $t('order.complaint.description') }}</label>
        <textarea class="form-control" v-model="complaintDescription"></textarea>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="checkbox" value="1" id="cancellationComplainCheckbox" checked
               v-model="complaintIsCancellation">
        <label class="form-check-label" for="cancellationComplainCheckbox">{{
            $t('order.complaint.iWantToCancel')
          }}</label>
      </div>


      <div class="my-3 alert alert-danger">
        Dear customer, please note that you need to export and share conversation history in order to prove your
        complaint.
        Export is available on right top corner of the chat window, under the three dots menu. <br>
        Please make sure to export and send the conversation directly after the complain send. <br>
      </div>

      <div class="my-3">
        <button type="submit" class="btn btn-primary rounded-pill w-100">{{
            $t('order.complaint.submitComplaint')
          }}
        </button>
      </div>
    </form>
  </Modal>
  <Modal v-if="showPaymentMethodPopup" size="md" :title="$t('order.payment.choosePaymentMethod')"
         @modalClose="closePaymentMethodPopup" :z-index="1090">


    <div class=" mb-4" style="cursor: pointer;" @click.prevent="confirmOrder(currentPaymentOrderId, 'PAYMENT_CARD')">
      <img src="../assets/theme/card-payment.png" style="width: 60px" class="me-4" alt="">
      <span>{{ $t('order.payment.card') }}</span>
    </div>

    <div class=" mb-4" style="cursor: pointer;" @click.prevent="confirmOrder(currentPaymentOrderId,'BANK_ACCOUNT')">
      <img src="../assets/theme/transfer-payment.png" style="width: 60px" class="me-4" alt="">
      <span>{{ $t('order.payment.transfer') }}</span>
    </div>


    <div class="mb-4" style="cursor: pointer;" @click.prevent="payCash(currentPaymentOrderId)"
         v-if="currentPaymentCashEnabled">
      <img src="../assets/theme/cash-payment.png" style="width: 60px" class="me-4" alt="">
      <span>{{ $t('order.payment.cash') }}</span>
    </div>

    <div class=" mb-4" style="cursor: pointer;" @click.prevent="startTwisto(currentPaymentOrderId)">
      <img src="../assets/theme/twisto.png" style="width: 60px" class="me-4" alt="">
      <span>{{ $t('order.payment.twisto') }}</span>
    </div>


  </Modal>

  <Modal v-if="exportChatPopupIsOpen" size="sm" :title="$t('conversation.exportConversationHistory')"
         @modalClose="closeExportChatPopup">

    <form @submit.prevent="submitExportChatForm">

      <div class="mb-3">
        <label>{{ $t('conversation.exportFrom') }}</label>
        <DatePicker v-model="exportChatFrom" mode="date" :model-config="{type: 'string', mask: 'YYYY-MM-DD'}"
                    :max-date="exportChatTo">
          <template v-slot="{ inputValue, inputEvents }">
            <input type="text" class="form-control form-control-lg" :value="inputValue"
                   v-on="inputEvents">
          </template>
        </DatePicker>
      </div>

      <div class="mb-3">
        <label>{{ $t('conversation.exportTo') }}</label>
        <DatePicker v-model="exportChatTo" mode="date" :model-config="{type: 'string', mask: 'YYYY-MM-DD'}"
                    :min-date="exportChatFrom" :max-date="new Date()">
          <template v-slot="{ inputValue, inputEvents }">
            <input type="text" class="form-control form-control-lg" :value="inputValue"
                   v-on="inputEvents">
          </template>
        </DatePicker>
      </div>

      <div class="my-3">
        <button type="submit" class="btn btn-primary rounded-pill w-100">{{ $t('conversation.exportNow') }}</button>
      </div>
    </form>
  </Modal>

  <Modal v-if="sendAttachmentModalIsOpen" size="md" title="Sending attachment"
         @modalClose="closeSendAttachmentPopup">


    <div v-if="isPhoto(sendAttachmentPath)">
      <img :src="`${squareImageBase}/${sendAttachmentPath}`" class="img-fluid img-thumbnail"
           alt="" style="max-height: 400px; ">
    </div>
    <div class="text-center" v-else>
      <i class="fa fa-paperclip fa-3x"></i> <br>
      <span>*.{{ getFileExtension(sendAttachmentPath) }} file</span> <br>
      <a class="text-primary" target="_blank" :href="`${mediaBase}/${sendAttachmentPath}`">view file</a>
    </div>


    <div class="my-3 d-flex justify-content-between">
      <div>
        <button type="button" class="btn btn-success rounded-pill" @click.prevent="sendAttachment">Send attachment
        </button>
      </div>
      <div>
        <button type="button" class="btn btn-danger rounded-pill" @click.prevent="closeSendAttachmentPopup">Cancel
        </button>
      </div>
    </div>
  </Modal>

  <the-footer/>

</template>

<script>
// @ is an alias to /src

/*import SockJS from "sockjs-client";
import Stomp from "webstomp-client";*/
import moment from 'moment';

import TopNavigation from "../components/layout/TopNavigation";
import TheFooter from "../components/layout/TheFooter";
import Modal from "../components/Modal";
import {mapState} from "vuex";
import {mediaBase, squareImageBase} from "@/repositories/Repository";
import {
  cancelOrder, complaintOrder,
  confirmOrder, confirmTwistoInvoice, editOrder, exportChat,
  getChartList,
  getChartMessages, getChatOpponentDetails,
  getOrder, getOrdersByUserId, getPropertyList, getTwistoEncriptedData, payCash, rateOrder, readMessage, upload,
} from "@/repositories/GeneralDataRepository";
import customAlert from "../repositories/Utils";
import TranslatorOrderForm from "../components/order/TranslatorOrderForm";
import InterpreterOrderForm from "../components/order/InterpreterOrderForm";
import TeacherOrderForm from "../components/order/TeacherOrderForm";
import OrderListItem from "../components/order/OrderListItem";
import OrderDetails from "../components/order/OrderDetails";

export default {
  name: 'ChatPage',
  components: {
    OrderDetails,
    OrderListItem,
    TeacherOrderForm, InterpreterOrderForm, TranslatorOrderForm, TheFooter, TopNavigation, Modal
  },
  data() {
    return {
      mediaBase: mediaBase,
      squareImageBase: squareImageBase,
      stompClient: null,
      targetProfile: null,
      messageText: '',
      messages: [],
      chatList: [],
      uploadProgress: 0,


      ordersViewIsOpen: false,
      orderList: [],

      newOrderPopupIsOpen: false,
      orderType: 'TRANSLATOR',


      editOrderPopupIsOpen: false,
      orderEditType: '',
      orderEditPageCount: 0,
      orderEditPrice: 0,
      orderEditNote: '',
      orderEditDeadlineValue: '',
      orderEditAcceptanceDeadlineValue: '',

      rateOrderPopupIsOpen: false,
      orderRate: 0,
      orderRateNote: '',

      complainOrderId: 0,
      complainPopupIsOpen: false,
      complaintTitle: '',
      complaintDescription: '',
      complaintIsCancellation: false,
      complaintTitleList: [],

      activeOrder: null,
      isLoadingMessages: false,
      currentPaymentOrderId: 0,
      currentPaymentCashEnabled: false,
      showPaymentMethodPopup: false,
      windowWidth: window.innerWidth,

      exportChatPopupIsOpen: false,
      exportChatFromValue: '',
      exportChatToValue: '',

      sendAttachmentModalIsOpen: false,
      sendAttachmentPath: ''
    };
  },
  computed: {

    ...mapState(
        {
          token: state => state.auth.token,
          user: state => state.auth.user,
          receivedMessages: state => state.chat.messages
        }
    ),
    historyId() {
      return this.$route.params.id;
    },

    lastOfferDetails() {
      if (this.activeOrder == null) {
        return null;
      }
      if (this.activeOrder.secondOrder) {
        let secondOrder = this.activeOrder.secondOrder;
        secondOrder.expert = this.activeOrder.firstOrder.expert;
        secondOrder.user = this.activeOrder.firstOrder.user;
        secondOrder.orderNo = this.activeOrder.firstOrder.orderNo;
        return secondOrder;
      } else {
        return this.activeOrder.firstOrder;
      }
    },
    isMobile() {
      return this.windowWidth < 768;
    },
    canRate(){
      if (this.activeOrder == null) {
        return false;
      }
      if (this.activeOrder.finishedDate) {
        return moment(this.activeOrder.finishedDate).add(3, 'days').isAfter(moment());
      }
      return false;
    },
    orderEditDeadline: {
      get() {
        if (!this.orderEditDeadlineValue) {
          return null;
        }
        return moment(this.orderEditDeadlineValue).format('YYYY-MM-DD HH:mm:ss');
      },
      set(value) {
        this.orderEditDeadlineValue = value;
      }
    },
    orderEditAcceptanceDeadline: {
      get() {
        if (!this.orderEditAcceptanceDeadlineValue) {
          return null;
        }
        return moment(this.orderEditAcceptanceDeadlineValue).format('YYYY-MM-DD HH:mm:ss');
      },
      set(value) {
        this.orderEditAcceptanceDeadlineValue = value;
      }
    },
    exportChatFrom: {
      get() {
        if (!this.exportChatFromValue) {
          return null;
        }
        return moment(this.exportChatFromValue).format('YYYY-MM-DD');
      },
      set(value) {
        this.exportChatFromValue = value;
      }
    },
    exportChatTo: {
      get() {
        if (!this.exportChatToValue) {
          return null;
        }
        return moment(this.exportChatToValue).format('YYYY-MM-DD');
      },
      set(value) {
        this.exportChatToValue = value;
      }
    },

  },
  watch: {

    user(newData, oldData) {
      if (oldData === null && newData !== null) {
        //this.connectToSocket(newData.id);
      }
    },
    chatList() {
      if (this.targetProfile === null) {
        this.loadTargetProfile();
      }
    },
    historyId() {
      this.loadTargetProfile();
      this.loadChatMessages(false);
      this.activeOrder = null;
    },
    receivedMessages: {
      handler(newVal) {

        newVal.forEach(data => {

          if (this.historyId == null) {
            this.$store.dispatch('chat/messageUsed', data.messageInfo.id);
          } else {
            if (data.messageInfo && this.historyId === data.messageInfo.idMessageHistory) {
              data.messageInfo.body = JSON.parse(data.messageInfo.body);
              this.messages.push(data);
              if (data.messageInfo.body.type.slice(0, 5) === 'order') {
                this.getOrderDetails(data.messageInfo.body.orderId);
                this.loadOrderList();
              }
              readMessage(this.token, data.messageInfo.id);

            }

            this.$store.dispatch('chat/messageUsed', data.messageInfo.id);

            this.loadHistory();
          }
        });

        this.scrollToBottom();
      },
      deep: true
    },

    "$i18n.locale": function () {
      this.getComplainTitles();
    }
  },

  methods: {
    scrollToBottom() {
      setTimeout(() => {
        let container = document.querySelector("#messageList");
        if (container) {
          container.scrollTop = container.scrollHeight;
        }
      }, 100)

    },
    scrollToMessageId(messageId) {
      console.log(messageId);
      setTimeout(() => {
        let container = document.querySelector("#messageList");
        if (container) {
          let message = document.querySelector("#message" + messageId);
          console.log(message);
          console.log(message.offsetTop);
          container.scrollTop = message.offsetTop - 200;
        }
      }, 100);
    },
    getFormattedDate(date) {
      return moment(date).format("DD.MM.YYYY")
    },
    getFormattedTime(date) {
      return moment(date).format("HH:mm")
    },
    loadHistory() {
      getChartList(this.token).then(data => {
        this.chatList = data;
      });
    },
    loadChatMessages(nextPage) {
      if (this.historyId !== 'list') {
        this.isLoadingMessages = true;
        if (!nextPage) {
          this.$store.dispatch('chat/clearMessages');
        }
        getChartMessages(this.token, this.historyId, nextPage ? this.messages[0].messageInfo.id : "0").then(data => {
          data.forEach(m => {
            m.messageInfo.body = JSON.parse(m.messageInfo.body);
          })
          this.isLoadingMessages = false;
          this.loadHistory();
          if (nextPage) {
            const id = this.messages[0].messageInfo.id;
            this.messages = [...data, ...this.messages];
            if (data.length > 0) {
              this.scrollToMessageId(id);
            }
          } else {
            this.messages = data;
            this.scrollToBottom();
          }
        });
      }
    },
    goToChat(id) {
      console.log(id);
      this.$router.push(`/chat/${id}`);
    },
    sendMsg(message) {

      let messageBody = JSON.stringify(message);

      let json = {
        idMessageHistory: this.historyId,
        idUserFrom: this.user.id,
        body: messageBody,
        idUserTo: this.targetProfile.id,
        id: Math.random(),
      };


      this.messages.push({
        "userView": {
          "id": this.user.id,
          "name": this.user.name,
          "surname": this.user.surname,
          "profilePhoto": this.user.profilePhoto
        },
        "messageInfo": {
          "idUser": this.targetProfile.id,
          "body": message,
          "dateTime": new Date().getTime(),
          "deleted": false
        }
      });

      this.changeLastMessage(this.historyId, {
        "idUser": this.targetProfile.id,
        "body": messageBody,
        "dateTime": new Date().getTime(),
        "deleted": false,
        "idMessageHistory": this.historyId,
      });

      this.$store.dispatch('chat/sendMessage', json);

      this.scrollToBottom();

    },
    changeLastMessage(historyId, messageInfo) {

      this.chatList.forEach(ch => {
        if (ch.idHistory === historyId) {
          ch.lastMessage = messageInfo;
        }
      })

    },
    getMessageContent(lastMessage) {

      if (lastMessage === null) {
        return '';
      }

      let json = JSON.parse(lastMessage.body);

      switch (json.type) {
        case 'text':
          return json.message;
        case 'file':
          return 'attachment';

        default:

          if (json.type.slice(0, 5) === 'order') {

            return this.$t('conversation.orderAction')
          }

          return '';

      }


    },
    sendMessage() {
      if (this.messageText.trim() !== '') {
        this.sendMsg({type: 'text', message: this.messageText});
        this.messageText = '';
        this.loadHistory();
      }
    },

    getUsrIdFromHistoryId(historyId) {
      let history = this.chatList.filter(c => c.idHistory === historyId);
      if (history.length === 1) {
        return history[0].user.id;
      }
      return 0;
    },
    loadTargetProfile() {
      getChatOpponentDetails(this.token, this.historyId).then(data => {
        this.targetProfile = data;
        if (data != null) {
          this.loadOrderList();
        }
      })
    },
    loadOrderList() {
      getOrdersByUserId(this.token, this.targetProfile.id).then(orders => {
        this.orderList = orders;
      })
    },
    openOrdersView() {
      this.ordersViewIsOpen = true;
      this.activeOrder = null;
      this.loadOrderList();
    },
    selectAttachemnt() {
      window.document.getElementById('fileInput').click();
    },
    setAttachment() {
      let currentFile = this.$refs.file.files[0];

      if (currentFile && window.File && window.FileReader && window.FileList && window.Blob) {

        this.uploadMessage = '';
        upload(currentFile, event => {
          this.uploadProgress = Math.round((100 * event.loaded) / event.total);
        })
            .then(response => {
              this.uploadProgress = 100;
              if (response.data.code === 200) {
                this.sendAttachmentModalIsOpen = true;
                this.sendAttachmentPath = response.data.body;
              } else {
                alert(response.data.message);
              }
            })
            .catch((e) => {
              console.log(e);
              this.progress = 0;
              this.uploadMessage = this.$t('general.canNotUploadFile') + e;
            });

      } else {
        customAlert(this.$t('general.error'), this.$t('general.photoUploadNotSupportedOnYourBrowser'), 'danger');
      }
    },

    sendAttachment() {
      this.sendMsg({type: 'file', file: this.sendAttachmentPath})
      this.loadHistory();
      this.closeSendAttachmentPopup();
    },
    getFileExtension(path) {

      return path.split('.').pop().toLowerCase();
    },
    isPhoto(path) {
      return ['jpg', 'png', 'jpeg'].indexOf(this.getFileExtension(path)) > -1;
    },
    createNewOrder() {
      this.newOrderPopupIsOpen = true;
    },
    editActiveOrder() {
      this.editOrderPopupIsOpen = true;
      if (this.activeOrder.secondOrder) {
        this.orderEditType = this.activeOrder.secondOrder.orderType;
        this.orderEditPageCount = this.activeOrder.secondOrder.count;
        this.orderEditPrice = this.activeOrder.secondOrder.price;
        this.orderEditDeadline = this.activeOrder.secondOrder.endDate;
        this.orderEditAcceptanceDeadline = this.activeOrder.secondOrder.expireDateTime;
      } else {
        this.orderEditType = this.activeOrder.firstOrder.orderType;
        this.orderEditPageCount = this.activeOrder.firstOrder.count;
        this.orderEditPrice = this.activeOrder.firstOrder.price;
        this.orderEditDeadline = this.activeOrder.firstOrder.endDate;
        this.orderEditAcceptanceDeadline = this.activeOrder.firstOrder.expireDateTime;
      }
      this.orderEditNote = '';
    },
    closeCreateOrderPopup() {
      this.newOrderPopupIsOpen = false;
    },
    closeEditOrderPopup() {
      this.editOrderPopupIsOpen = false;
    }, closeRateOrderPopup() {
      this.rateOrderPopupIsOpen = false;
    }, closePaymentMethodPopup() {
      this.showPaymentMethodPopup = false;
      this.currentPaymentOrderId = 0;
    }, closeExportChatPopup() {
      this.exportChatPopupIsOpen = false;
      this.exportChatFrom = '';
      this.exportChatTo = '';
    },
    closeSendAttachmentPopup() {
      this.sendAttachmentModalIsOpen = false;
      this.sendAttachmentPath = '';
    },
    newOrderCreated(newId) {

      //this.sendMsg({type: 'orderCreated', orderId: newId});
      this.newOrderPopupIsOpen = false;
      this.getOrderDetails(newId);
      this.loadOrderList();

    },
    submitEditOrderForm() {

      let data = {
        "secondOrder": {
          "firstOrderId": this.activeOrder.firstOrder.id,
          "count": this.orderEditPageCount,
          "price": this.orderEditPrice,
          "endDate": this.orderEditDeadline,
          "note": this.orderEditNote,
          "expireDateTime": this.orderEditAcceptanceDeadline,
        }
      };

      editOrder(this.token, data).then(res => {

        if (res.code === 200) {
          customAlert(this.$t('order.offerUpdated'), '', 'success');
          this.sendMsg({type: 'orderUpdated', orderId: this.activeOrder.firstOrder.id});
          this.editOrderPopupIsOpen = false;
          this.getOrderDetails(this.activeOrder.firstOrder.id);
        } else {
          customAlert(this.$t('general.canNotUpdate'), res.message, 'danger');
        }

      })

    }, submitRateOrderForm() {

      if (this.orderRate > 0) {
        let data = {
          "commentFromUserId": this.user.id,
          "commentToUserId": this.activeOrder.firstOrder.idExpert,
          "commentToOrderId": this.activeOrder.firstOrder.id,
          "comment": this.orderRateNote,
          "rate": this.orderRate
        };

        rateOrder(this.token, data).then(res => {

          if (res.code === 201) {
            customAlert(this.$t('order.yourRateAccepted'), '', 'success');
            this.sendMsg({
              type: 'orderRated',
              orderId: this.activeOrder.firstOrder.id,
              rate: this.orderRate,
              review: this.orderRateNote
            });
            this.rateOrderPopupIsOpen = false;
            this.getOrderDetails(this.activeOrder.firstOrder.id);
          } else {
            customAlert(this.$t('general.canNotUpdate'), res.message, 'danger');
          }
        })
      } else {
        customAlert(this.$t('order.rateRequired'), this.$t('order.rateRequiredDescription'), 'danger');
      }

    }, submitExportChatForm() {


      exportChat(this.token, this.historyId, this.exportChatFrom, this.exportChatTo).then(res => {

        if (res.code === 200) {
          customAlert(this.$t('conversation.exportSuccessfull'), this.$t('conversation.exportSentTouYourEmail'), 'success');
          this.exportChatPopupIsOpen = false;
          this.exportChatFrom = '';
          this.exportChatTo = '';
        } else {
          customAlert(this.$t('conversation.canNotExport'), res.message, 'danger');
        }
      })


    },
    getOrderDetails(id) {


      getOrder(this.token, id).then(res => {

        if (res === null) {
          customAlert(this.$t('general.error'), this.$t('order.canNotGetOrderDetails'), 'danger');
        } else if (res.code === 200) {

          this.activeOrder = res.body;

          // this.ordersViewIsOpen = false;

        } else {
          customAlert(this.$t('general.error'), res.message, 'danger');
        }

      })

    },
    confirmOrderPopup(id, cashEnabled) {

      this.showPaymentMethodPopup = true;
      this.currentPaymentOrderId = id;
      this.currentPaymentCashEnabled = cashEnabled;

    },
    confirmOrder(id, paymentMethod) {

      confirmOrder(this.token, id, paymentMethod).then(res => {

        if (res === null) {
          customAlert(this.$t('general.error'), this.$t('general.canNotUpdate'), 'danger');
        } else if (res.code === 200) {

          window.location.href = res.body;


        } else {
          customAlert(this.$t('general.error'), res.message, 'danger');
        }

      })

    },

    payCash(id) {

      payCash(this.token, id).then(res => {

        if (res === null) {
          customAlert(this.$t('general.error'), this.$t('general.canNotUpdate'), 'danger');
        } else if (res.code === 200) {
          customAlert(this.$t('general.success'), this.$t('order.payment.cashPaymentReqeustAccepted'), 'success');
          this.getOrderDetails(id);

          this.showPaymentMethodPopup = false;
          this.currentPaymentOrderId = null;

        } else {
          customAlert(this.$t('general.error'), res.message, 'danger');
        }

      })

    },
    startTwisto(id) {

      getTwistoEncriptedData(this.token, id).then(res => {

        if (res === null) {
          customAlert(this.$t('general.error'), this.$t('general.canNotUpdate'), 'danger');
        } else if (res.code === 400) {
          customAlert(this.$t('general.error'), "Please fill all required profile details", 'danger');
        } else if (res.code === 200) {

          window.twistoCheck(res.body, (status, transactionId, rejectReason) => {

            console.log(status);
            console.log(transactionId);
            console.log(rejectReason);

            if (status === 'accepted') {

              confirmTwistoInvoice(this.token, id, transactionId).then(response => {

                if (response.code === 200) {
                  customAlert(this.$t('order.offerConfirmed'), '', 'success');
                  this.sendMsg({type: 'orderAccepted', orderId: id});
                  this.getOrderDetails(id);

                  this.showPaymentMethodPopup = false;
                  this.currentPaymentOrderId = null;
                } else {
                  customAlert(this.$t('general.canNotUpdate'), response.messages, 'danger');
                }

              })

            } else {
              customAlert(this.$t('order.payment.canNotPayWithTwisto'), rejectReason, 'danger');
            }


          })

        } else {
          customAlert(this.$t('general.error'), res.message, 'danger');
        }

      })

    },
    cancelOffer(id) {

      if (confirm(this.$t('order.cancel.question'))) {
        cancelOrder(this.token, id).then(res => {

          if (res === null) {
            customAlert(this.$t('general.error'), this.$t('general.canNotUpdate'), 'danger');
          } else if (res.code === 200) {
            customAlert(this.$t('order.cancel.calcelled'), res.message, 'success');
            this.sendMsg({type: 'orderCancelled', orderId: id});
            this.getOrderDetails(id);
          } else {
            customAlert(this.$t('general.error'), res.message, 'danger');
          }
        })
      }

    },
    complainOffer(id) {

      this.complainPopupIsOpen = true;
      this.complaintTitle = '';
      this.complaintDescription = '';
      this.complainOrderId = id;

    },
    submitComplaintForm() {

      let message = this.complaintTitle + '|' + this.complaintDescription;
      complaintOrder(this.token, this.complainOrderId, message, this.complaintIsCancellation).then(res => {

        if (res === null) {
          customAlert(this.$t('general.error'), this.$t('general.canNotUpdate'), 'danger');
        } else if (res.code === 200) {
          customAlert(this.$t('order.complaint.complaintAccepted'), res.message, 'success');
          this.getOrderDetails(this.complainOrderId);
          this.complainPopupIsOpen = false;
          this.complaintTitle = '';
          this.complaintDescription = '';
        } else if(res.code=== 410) {
          customAlert(this.$t('general.error'), "Order is not active. If you have issues you can contact administration for complains", 'danger');
        } else {
          customAlert(this.$t('general.error'), res.message, 'danger');
        }
      })

    },
    rateActiveOrder() {
      this.rateOrderPopupIsOpen = true;
    },
    handleScroll() {

      let container = document.getElementById("messageList");
      if (container && container.scrollTop < 1) {
        if (this.isLoadingMessages) {
          return;
        }
        this.loadChatMessages(true);
      }

    },
    getLanguageIcon(keyword) {
      let code = keyword.split('.')[1];
      return `${mediaBase}/lang/${code}.png`
    },

    getComplainTitles() {
      getPropertyList('order.complaint', this.$i18n.locale).then(d => {
        this.complaintTitleList = d;
      })
    }

  },
  mounted() {
    this.loadHistory();

    this.loadTargetProfile();
    this.loadChatMessages(false);

    this.getComplainTitles();

  }
}
</script>

<style lang="scss" scoped>

@import "../assets/colors.scss";


.avatar {
  display: inline-block;
  width: 50px;
  height: 50px;
  min-width: 50px;
  max-width: 50px;
  background-size: cover;
  border-radius: 100%;
  border: 1px solid #9EA2B2;

  &.message-avatar {
    width: 20px;
    height: 20px;
    min-width: 20px;
    max-width: 20px;
  }
}

.message-area {
  border-radius: 20px;

  img {
    border-radius: 20px;
  }

}

.input-group {
  input {
    border-radius: 30px;
  }

  .btn {
    border-radius: 30px;
  }
}

.my-message {
  background: #757e9a;
  color: #f0f0f0;
}

.opposite-message {
  background: #f0f0f0;
  color: #757e9a;
}

.status-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 100%;
}

.conversationTitle {
  text-decoration: none;
}

.ordersView {
  position: absolute;
  right: 0;
  left: 50%;
  bottom: 71px;
  top: 0;
  z-index: 1000;
  padding: 15px;
  overflow: auto;
}

</style>