const authStorage = {
    namespaced: true,
    state() {
        return {
            messages: [],
            sendMessages: []
        };
    },
    mutations: {
        addMessage(state, payload) {
            state.messages.push(payload);
        },
        sendMessage(state, payload) {
            state.sendMessages.push(payload);
        },
        messageUsed(state, payload) {
            state.messages = state.messages.filter(m => m.messageInfo && m.messageInfo.id !== payload);
        },
        messageSent(state, payload) {
            state.sendMessages = state.sendMessages.filter(m => m.id !== payload);
        },
        clearMessages(state) {
            state.messages = [];
        }
    },
    actions: {
        addMessage(context, payload) {
            context.commit('addMessage', payload)
        },
        sendMessage(context, payload) {
            context.commit('sendMessage', payload)
        },
        messageUsed(context, payload) {
            context.commit('messageUsed', payload)
        },
        messageSent(context, payload) {
            context.commit('messageSent', payload)
        },
        clearMessages(context) {
            context.commit('clearMessages')
        }
    },
    getters: {}
}

export default authStorage;