<template>
  <router-view/>
</template>


<script>


import {mapState} from "vuex";
import SockJS from "sockjs-client";
import {socketBase} from "./repositories/Repository";
import Stomp from "webstomp-client";

export default {


  data() {
    return {
      stompClient: null,
      unreadMessageTimer: null,
    }
  },
  computed: {
    ...mapState(
        {
          user: state => state.auth.user,
          sendMessages: state => state.chat.sendMessages
        }
    )
  },
  watch: {
    user(newValue, oldValue) {
      if (oldValue === null && newValue !== null) {
        if (newValue.title === null) {
          if (newValue.userType === 'EXPERT') {
            this.$router.push('/registration/expert')
          } else {
            this.$router.push('/profile/edit')
          }
        } else if (!newValue.translator && !newValue.interpreter && !newValue.teacher && newValue.userType === 'EXPERT') {
          this.$router.push('/registration/expert');
        } else {
          this.connectToSocket(newValue.id);
          if (newValue.lang) {
            this.$i18n.locale = newValue.lang.toLowerCase();
          }
        }
      }

      if(newValue === null){
        if(this.unreadMessageTimer){
          clearInterval(this.unreadMessageTimer);
        }
      }
    },
    sendMessages: {
      handler(newVal) {

        newVal.forEach(data => {

          this.stompClient.send("/app/chat/" + data.idUserTo, JSON.stringify(data, null, ''), {});

          this.$store.dispatch('chat/messageSent', data.id);

        });

      },
      deep: true
    },
  },
  methods: {

    connectToSocket(currentUserId) {
      console.log("connecting to chat...")
      let socket = new SockJS(socketBase + '/chat');
      this.stompClient = Stomp.over(socket);
      this.stompClient.connect({}, (frame) => {
        console.log("connected to: " + frame);
        this.stompClient.subscribe("/topic/messages/" + currentUserId, (response) => {
          let data = JSON.parse(response.body);
          this.$store.dispatch('chat/addMessage', data);
        });

        this.stompClient.send("/app/chat/subscribe/" + currentUserId, "{}", {});
      });
    }

  },
  created() {


    let token = localStorage.getItem('token');

    if (token) {
      this.$store.dispatch('auth/setToken', token);
      this.$store.dispatch('auth/loadUser', token);
      this.$store.dispatch('auth/loadUnreadMessageCount', token);

      this.unreadMessageTimer = setInterval(() => {
        this.$store.dispatch('auth/loadUnreadMessageCount', token);
      }, 10000);

    } else {


      let langFromStorage = localStorage.getItem("lang");
      if (langFromStorage) {
        this.$i18n.locale = langFromStorage;
      } else {
        localStorage.setItem("lang", 'en');
      }

    }

  }

}


</script>


<style lang="scss">

@import "assets/colors";

$primary: $color-violet;
$warning: $color-salmon;

@import "../node_modules/bootstrap/scss/bootstrap";
@import "assets/font-awesome/css/font-awesome.min.css";
@import url('https://unpkg.com/vue-next-select/dist/index.min.css');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

* {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
}

body {
  background: $color-light-bg;
}

h3 {
  font-size: 23px;
}


.btn-pill {
  border-radius: 20px;

  &.btn-lg {
    border-radius: 30px;
  }
}


.form-control {
  border-radius: 10px;
  background: $color-light;

  &.form-control-lg {
    border-radius: 20px;
    font-size: 14px;
    line-height: 30px;
  }

  &:focus {
    box-shadow: none;
    border-color: #ced4da;
    background: $color-light;
  }
  &:invalid {
    border-color: red;
  }
}

div.input-group:has(.form-control:invalid) .input-group-text { border-color: red; }

.input-group {

  &.input-group-lg {
    font-size: 14px;
    line-height: 30px;

    .input-group-text {
      border-radius: 20px;
    }
  }

  .input-group-text {
    background: none;
    border-radius: 10px;
    background: $color-light;

    &:first-child {
      border-right: none;
    }

    &:last-child {
      border-left: none;
    }
  }

  .form-control:first-child {
    border-right: none;
  }

  .form-control:last-child {
    border-left: none;
  }
}

.tabs-view {

  .tabs-header {
    display: flex;

    .tab-pill {
      display: inline-block;
      text-decoration: none;
      color: $color-grey;
      font-weight: bold;
      padding: 10px 25px;

      &.active {
        background: white;
        color: black;
        border-radius: 20px 20px 0 0;
      }
    }

  }

  .tab-body {
    padding: 30px;
    background: white;
    color: black;
    border-radius: 20px;

    &.firstIsActive {
      border-top-left-radius: 0;
    }
  }

}

.line-accordion {

  .title {
    position: relative;


    &:before {
      content: "";
      display: inline-block;
      width: 20px;
      height: 10px;
      background: url("assets/icon/chevron-down.png") no-repeat center;
      background-size: contain;
      z-index: 11;
    }

    &:after {
      content: '';
      position: absolute;
      right: 0;
      left: 30px;
      top: 50%;
      border-top: 1px solid rgba(0, 0, 0, 0.2);
      z-index: 1;
    }

    span {
      position: relative;
      display: inline-block;
      padding: 2px 15px;
      background: white;
      z-index: 9;
    }
  }

  .body {
    padding: 20px 0;
  }

}


.status-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 100%;
}

.unread-count {
  display: inline-block;
  min-width: 20px;
  height: 20px;
  border-radius: 100%;
  background: #317b21;
  color: #ffffff;
  font-size: 12px;
  line-height: 1;
  padding: 4px;
  text-align: center;
}


</style>
