<template>

  <div class="bg-white">

    <top-navigation/>

  </div>
  <div class="expert-profile-container" v-if="user=== null">
    <div class="container" style="padding: 100px 0;">
      <div class="alert alert-info">{{ $t('general.loading') }}</div>
    </div>
  </div>
  <div class="expert-profile-container" v-else>
    <div class="container" style="padding: 100px 0;">


      <div class="row">
        <div class="col-8">
          <h3 class="text-dark fw-bold">{{ $t('profile.edit.title') }}</h3>
        </div>
        <div class="col-4 text-end">
          <router-link to="/profile" class="btn btn-sm  btn-outline-primary btn-pill px-4 fw-bold ">
            {{ $t('general.cancel') }}
          </router-link>
        </div>
      </div>


      <form @submit.prevent="expertRegistrationFinish">
        <div class="my-5">
          <div class="card">

            <div class="card-body">


              <div class="row">

                <div class="col-lg-3">
                  <div class="mb-3">
                    <label>{{ $t('profile.profilePhoto') }}</label>
                    <input type="file" style="display: none" id="fileInput" ref="file" accept="image/*"
                           @change="setPhoto">
                    <div @click="selectPhoto">
                      <img v-if="photo === null || photo===''"
                           src="../assets/theme/placeholder.png"
                           class="w-100 img-thumbnail" alt="avatar">

                      <img v-else :src="`${squareImageBase}/${photo}`" class="w-100 img-thumbnail" alt="avatar">
                    </div>

                    <div class="alert alert-warning" v-if="uploadMessage">
                      {{ uploadMessage }}
                    </div>

                    <div class="progress" v-if="uploadProgress >0 && uploadProgress <100">
                      <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
                           aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"
                           :style="{width: `${uploadProgress}%`}"></div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-9">

                  <div class="row">

                    <div class="col-md-4">
                      <div class="mb-4">
                        <label>{{ $t('profile.name') }}<span class="text-primary fw-bold">*</span></label>
                        <input class="form-control form-control-lg" type="text"
                               pattern="[A-Za-z]+" maxlength="20" title="Only letters are allowed"
                               v-model="name" required>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="mb-4">
                        <label>{{ $t('profile.surname') }}<span class="text-primary fw-bold">*</span></label>
                        <input class="form-control form-control-lg" type="text"
                               pattern="[A-Za-z]+" maxlength="20" title="Only letters are allowed"
                               v-model="surname" required>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="mb-4">
                        <label>{{ $t('profile.title') }}</label>
                        <input class="form-control form-control-lg" type="text"
                               pattern="[A-Za-z]+" maxlength="15" title="Only letters are allowed"
                               v-model="title">
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="my-3">
                        <label>{{ $t('profile.invoiceAddress') }}<span
                            class="text-primary fw-bold">*</span></label>
                        <GMapAutocomplete class="form-control form-control-lg"
                                          :placeholder="$t('profile.location')"
                                          @place_changed="setPlace" :value="address">
                        </GMapAutocomplete>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="my-3">
                        <label>{{ $t('profile.country') }}<span class="text-primary fw-bold">*</span></label>
                        <select class="form-control form-control-lg"
                                v-model="country" required>
                          <option v-for="c in countries" :key="c.keyword" :value="c.keyword">
                            {{ c.name }}
                          </option>
                        </select>
                      </div>
                    </div>

                    <div class="col-lg-6">

                      <div class="my-4">
                        <label>{{ $t('profile.employment') }} <span class="text-primary fw-bold">*</span> </label>

                        <div class="d-flex my-3">


                          <div class="form-check me-5">
                            <input class="form-check-input" type="radio" name="legalStatus"
                                   id="legalStatusIndividual" value="INDIVIDUAL" v-model="jste">
                            <label class="form-check-label"
                                   for="legalStatusIndividual">{{ $t('profile.employmentStatus.individual') }}</label>
                          </div>

                          <div class="form-check">
                            <input class="form-check-input" type="radio" name="legalStatus"
                                   id="legalStatusLegal" value="COMPANY" v-model="jste">
                            <label class="form-check-label"
                                   for="legalStatusLegal">{{ $t('profile.employmentStatus.company') }}</label>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div class="col-lg-6">
                      <div class="my-4">
                        <label>{{ $t('profile.company') }}</label>
                        <input class="form-control form-control-lg" type="text"
                               v-model="company">
                      </div>
                    </div>


                    <div class="col-md-6">
                      <div class="my-3">
                        <label> {{ $t('profile.city') }} <span class="text-primary fw-bold">*</span></label>
                        <select class="form-control form-control-lg"
                                v-model="location" required>
                          <option v-for="c in locations" :key="c.id" :value="c.id">
                            {{ c.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="my-3">
                        <label>{{ $t('profile.phone') }}<span class="text-primary fw-bold">*</span></label>
                        <input class="form-control form-control-lg" type="tel"
                               pattern="[+0-9]{9,15}" title="Only numbers are allowed"
                               v-model="phone" required>
                      </div>
                    </div>


                  </div>

                </div>


              </div>

            </div>

            <div v-if="user.userType === 'EXPERT'">
              <hr>
              <div class="card-body">


                <div class="row">


                  <div class="col-md-6">
                    <div class="my-3">
                      additional attachments will be added here
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="my-3">
                      <label>{{ $t('profile.enterBioInfo') }}</label>

                      <textarea class="form-control form-control-lg" style="height: 150px;"
                                v-model="description"></textarea>
                    </div>
                  </div>


                </div>

              </div>

              <hr>

              <div class="card-body">

                <div class="row">

                  <div class="col-lg-6">
                    <div class="my-3">
                      <label>{{ $t('profile.accountNumber') }}<span class="text-primary fw-bold">*</span></label>
                      <input class="form-control form-control-lg" type="number" required min="1"
                             v-model="account">
                    </div>
                  </div>

                  <div class="col-lg-3">
                    <div class="my-3">
                      <label>{{ $t('profile.taxId') }}</label>
                      <input class="form-control form-control-lg" type="number" v-model="taxId">
                    </div>
                  </div>

                  <div class="col-lg-3">
                    <div class="my-3">
                      <label>{{ $t('profile.vatPayer') }}<span class="text-primary fw-bold">*</span></label>
                      <select class="form-control form-control-lg" v-model="edv" required>
                        <option value="0">{{ $t('general.no') }}</option>
                        <option value="1">{{ $t('general.yes') }}</option>
                      </select>
                    </div>
                  </div>

                  <div class="col-lg-3">
                    <div class="my-3">
                      <label>{{ $t('profile.swift') }}</label>
                      <input class="form-control form-control-lg" type="text"
                             v-model="swift">
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div class="my-3">
                      <label>{{ $t('profile.bic') }}</label>
                      <input class="form-control form-control-lg" type="text"
                             v-model="bic">
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="my-3">
                      <label>{{ $t('profile.iban') }}</label>
                      <input class="form-control form-control-lg" type="text"
                             v-model="iban">
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div class="card-body">

              <div class="row mt-0">
                <div class="col-md-6 mb-2">
                  <router-link to="/profile"
                               class="btn w-100 btn-outline-primary btn-pill btn-lg px-5 ">{{ $t('general.cancel') }}</router-link>
                </div>
                <div class="col-md-6 mb-2">
                  <button type="submit" class="btn w-100 btn-primary btn-pill btn-lg px-5 ">{{ $t('profile.saveChanges') }}</button>
                </div>
              </div>

            </div>


          </div>

        </div>


      </form>

    </div>
  </div>


  <the-footer/>

</template>

<script>
// @ is an alias to /src

import TopNavigation from "../components/layout/TopNavigation";
import TheFooter from "../components/layout/TheFooter";
import {
  getLocationList,
  getMe,
  getPropertyList,
  updateProfile,
  photoUpload
} from "@/repositories/GeneralDataRepository";
import {mapState} from "vuex";
import {mediaBase, squareImageBase} from "@/repositories/Repository";
import customAlert from "@/repositories/Utils";

export default {
  name: 'ProfileEdit',
  components: {TheFooter, TopNavigation},
  data() {
    return {
      mediaBase: mediaBase,
      squareImageBase: squareImageBase,
      uploadProgress: 0,
      uploadMessage: '',

      selectedProfiles: [],
      name: '',
      surname: '',
      title: '',
      legalStatus: '',
      company: '',

      country: '',
      location: '',
      address: '',
      latitude: 0,
      longitude: 0,

      phone: '',

      jste: '',
      taxId: '',
      account: '',
      edv: 0,
      swift: '',
      bic: '',
      iban: 0,

      description: '',

      photo: '',

      locations: [],
      countries: [],
    };
  },
  computed: {
    ...mapState({
      token: state => state.auth.token,
      user: state => state.auth.user
    }),
    submitData() {

      return {
        "id": this.user.id,
        "name": this.name,
        "surname": this.surname,
        "userType": this.user.userType,
        "title": this.title,
        "companyName": this.company,
        "profilePhoto": this.photo,
        "description": this.description,
        "accountAddress": this.address,
        "location": {
          "id": this.location
        },
        "latitude": this.latitude,
        "longitude": this.longitude,
        "country": {
          "keyword": this.country
        },
        "phone": this.phone,
        "account": this.account,
        "iban": this.iban,
        "swift": this.swift,
        "bic": this.bic,
        "edv": this.edv,
        "allPhotos": [],
        "translator": this.selectedProfiles.indexOf('translator') > -1,
        "interpreter": this.selectedProfiles.indexOf('interpreter') > -1,
        "teacher": this.selectedProfiles.indexOf('teacher') > -1,
        "taxId": this.taxId,
        "jste": this.jste
      }
    }
  },
  watch:{
    name: function (val) {
      if(val && val.length>1) {
        this.name = val[0].toUpperCase() + val.slice(1).toLowerCase();
      }
    },
    surname: function (val) {
      if(val && val.length>1) {
        this.surname = val[0].toUpperCase() + val.slice(1).toLowerCase();
      }
    },

    "$i18n.locale": function () {
      this.getProperties();
    }
  },
  methods: {
    profileSelection(type) {
      if (this.selectedProfiles.indexOf(type) > -1) {
        this.selectedProfiles.splice(this.selectedProfiles.indexOf(type), 1);
      } else {
        this.selectedProfiles.push(type);
      }
    },
    profileSelectionSubmit() {
      this.step = 2;
    },
    personalInfoFormSubmit() {
      this.step = 3;
    },
    invoiceFormSubmit() {
      this.step = 4;
    },
    expertRegistrationFinish() {

      updateProfile(this.token, this.submitData).then(res => {

        if (res === null) {
          customAlert(this.$t('general.error'),  this.$t('general.somethingWentWrong'), 'danger');
        } else {
          let {code, message} = res;

          if (code === 200) {

            this.$store.dispatch('auth/loadUser', this.token);

            customAlert(this.$t('profile.profileUpdated'));

            this.$router.push('/profile');

          } else {
            customAlert(`Error ${code}`,`${message}`, 'danger');
          }
        }

      });


    },
    setPlace(d) {
      console.log(d);
      this.address = d.formatted_address;
      this.latitude = d.geometry.location.lat();
      this.longitude = d.geometry.location.lng();
    },

    selectPhoto() {
      window.document.getElementById('fileInput').click();
    },
    setPhoto() {
      let currentFile = this.$refs.file.files[0];

      if (window.File && window.FileReader && window.FileList && window.Blob) {

        this.uploadMessage = '';
        photoUpload(currentFile, event => {
          this.uploadProgress = Math.round((100 * event.loaded) / event.total);
        })
            .then(response => {
              this.uploadProgress = 100;
              if (response.data.code === 200) {
                this.photo = response.data.body;
                this.uploadMessage = '';
              } else {
                this.uploadMessage = response.data.message;
              }
            })
            .catch((e) => {
              console.log(e);
              this.progress = 0;
              this.uploadMessage = this.$t('general.canNotUploadFile') + e;
            });

      } else {
        customAlert(this.$t('general.photoUploadNotSupportedOnYourBrowser'));
      }
    },

    getProperties(){

      getLocationList().then((data) => {
        this.locations = data;
      });

      getPropertyList('countries', this.$i18n.locale).then((data) => {
        this.countries = data;
      });
    }

  },
  mounted() {

    getMe(this.token).then(data => {

      if (data.code === 200) {

        if (!data.body.translator && !data.body.interpreter && !data.body.teacher && data.body.userType === 'EXPERT') {
          this.$router.push('/registration/expert');
        } else {

          this.name = data.body.name;
          this.surname = data.body.surname;
          this.title = data.body.title;
          this.phone = data.body.phone;
          this.legalStatus = data.body.account;
          this.company = data.body.companyName;
          this.edv = data.body.edv;
          this.swift = data.body.swift;
          this.bic = data.body.bic;
          this.iban = data.body.iban;
          this.account = data.body.account;
          this.taxId = data.body.taxId;
          this.jste = data.body.jste;

          this.country = data.body.country.keyword;
          this.location = data.body.location.id;
          this.latitude = data.body.latitude;
          this.longitude = data.body.longitude;
          this.address = data.body.accountAddress;

          this.photo = data.body.profilePhoto;

          this.description = data.body.description;

          if (data.body.translator) {
            this.selectedProfiles.push('translator');
          }
          if (data.body.interpreter) {
            this.selectedProfiles.push('interpreter');
          }
          if (data.body.teacher) {
            this.selectedProfiles.push('teacher');
          }

        }

      } else if (data.code === 401) {
        window.location.reload();
      }

    });

    this.getProperties();

  }
}
</script>

<style lang="scss" scoped>

.card {
  border-radius: 20px;

  .card-body {
    padding: 40px;

    @media screen and (max-width: 640px) {
      font-size: 20px;
    }
  }
}


</style>